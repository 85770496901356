
import classes from './PopupTeam.module.css';

import { POPUPS } from '@/js/AppState';
import Popup from '@cmp/shared/Popup/Popup';
import Tabs from '@cmp/shared/Tabs/Tabs';
import PopupsManager from 'react-sdk/PopupsManager';

import PageBadges from './PageBadges';
import PageObjectives from './PageObjectives';
import PageStats from './PageStats';

import PageInfos from './PageInfos';

import TeamHeader from '@/components/shared/TeamHeader/TeamHeader';
import tab1 from "./tabs_img/tab_team_01.png";
import tab2 from "./tabs_img/tab_team_02.png";
import tab3 from "./tabs_img/tab_team_03.png";
import tab4 from "./tabs_img/tab_team_04.png";
import SoundPlayer from 'react-sdk/SoundPlayer';
import { useEffect } from 'react';
import SequenceScenario from '@/js/SequenceScenario';
import FTUE, { FTUE_state } from '@/js/FTUE';
import clsx from 'clsx';


const PopupTeam = ({team}) => {
  useEffect(() => {
    SoundPlayer.playSound("music_batUI")


    if(!FTUE_state.viewed.includes("popup_team_ftue")) {
      console.log("FTUE start popup_team_ftue")
      FTUE.popup_team_ftue.start()
    }


    return () => {
      SoundPlayer.playSound("music_map")
    }
  }, []);

  function handleClose() {
    PopupsManager.close(POPUPS.TEAM)

  }

  return (
    <Popup className={classes.PopupTeam} onClose={handleClose}>

      <div className={clsx(classes.PopupTeam_header, "popup-team-header")}>
        <TeamHeader />
      </div>

      <Tabs
      popupId={POPUPS.TEAM}
      tabs={[
        <img src={tab1} />,
        <img src={tab2} />,
        <img src={tab3} />,
        <img src={tab4} />
      ]}
      panels={[
        <PageStats />,
        <PageObjectives />,
        <PageBadges />,
        <PageInfos team={team} />
      ]}
      />

    </Popup>
  );
};

// <img src={tab3} />,
// <PageBadges />,
export default PopupTeam;
