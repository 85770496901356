// l'idee est de créer des points de sauvegarde a la main, et de reprendre à cet endroit

import SequencesManager from "react-sdk/SequencesManager"
import AppState from "./AppState"
import Reliques from "./Reliques/Reliques"

import Minigames from "./Minigames/Minigames"
import MinigameFtues from "./Minigames/MinigameFtues"
import { FTUE_state, loadFTUEState } from "./FTUE"
import ObjectiveFile from "./Objectives/ObjectiveFile"

class SaveGame {
  saveTimer = null // TODO petit timer pour debounce et éviter de save plusieurs fois presque en meme temps

  // NOTE : stocker ici des états qu'on pourrait utiliser dans le code ou le SM pour reloader au bon endroit
  // que des booleens
  states = {

    sequenceIntroVue: false,
    seq1_bureauTrashVue: false

  }


  getState(id) {
    return this.states[id]
  }
  setState(id, value) {
    this.states[id] = value
  }




  hasSave() {
    return new Promise( (resolve, reject) => {
      AppState.vertxClient.UserApplicationSave_GetList((success, msg, data) => {

        if(!data.DataArray) {
          resolve(null)
          return
        }

        let id = null
        let idsToDelete = []
        // permet à la fois de récupérer la save actuelle et de delete les saves enregistrées sous une ancienne key
        data?.DataArray.forEach(s => {
          s = JSON.parse(s)
          if (s.Name === window.CONFIG.saveGameKey)
            id = s._id
          else
            idsToDelete.push(s._id)
        })

        this.deleteSaves(idsToDelete)
        .then(() => resolve(id))
      })
    })
  }

  getOrCreateSaveId() {
    return this.hasSave()
    .then((id) => {

      if(id) return id
      else {
        return new Promise( (resolve, reject) => {
          AppState.vertxClient.UserApplicationSave_Create(window.CONFIG.saveGameKey, (success, msg, data) => {
            console.log("UserApplicationSave_Create", success, msg, data)
            if(success) {
              try {
                let save_id = data?.DataArray[0]
                resolve(save_id)

              }
              catch(err) {
                console.log(err);
                reject("Impossible de créer une sauvegarde")
              }

            }
          })

        })
      }

    })
  }


  save() {

    // petit débounce pour éviter de save trop souvent
    // if(this.saveTimer) {
    //   window.clearTimeout(this.saveTimer)
    // }

    // return new Promise( (resolve, reject) => {
    //   this.saveTimer = window.setTimeout(() => {
        // la map register la func pour save son état
        // si elle n'est pas encore chargée, et qu'on save avant, on prend le dernier state loadé



    let mapState = AppState.getMapState ? AppState.getMapState() : AppState.loadedMapState
    let state = {
      sm_state: SequencesManager.stateToJson(),
      reliques: Reliques.stateToJson(),
      objectives: AppState.soloObjectives.stateToJson(),
      // objectives: ObjectiveFile.stateToJson(),

      minigames: Minigames.stateToJson(),
      minigames_ftue: MinigameFtues.stateToJson(),
      map: mapState,
      states: this.states,
      ftue: FTUE_state,
      uiState: AppState.UI_visibilities
    }

    return this.getOrCreateSaveId()
    .then(id => {
      AppState.vertxClient.UserApplicationSave_SetCustomData(id, JSON.stringify(state), (success, msg, data) => {
        if(success) {
          console.log("SAVE SUCCESS")
        } else {
          console.log("SAVE ERROR", msg)
        }
      })

    })

      // }, 100)

    // })
  }

  load() {
    return this.hasSave()
    .then(id => {
      console.log("s id", id)
      if(id) {
        return new Promise( (resolve, reject) => {
          AppState.vertxClient.UserApplicationSave_GetCustomData(id, (success, msg, data) => {
            if(success) {
              try {
                let d = data.DataArray[0]
                if(d) {
                  data = JSON.parse(d)

                  SequencesManager.stateFromJson(data.sm_state)
                  Reliques.stateFromJson(data.reliques)


                  AppState.soloObjectives.stateFromJson(data.objectives, false)

                  AppState.loadedMapState = data.map

                  Minigames.stateFromJson(data.minigames)
                  MinigameFtues.stateFromJson(data.minigames_ftue)
                  loadFTUEState(data.ftue)
                  this.states = data.states

                  AppState.UI_visibilities = data.uiState
                }
              }
              catch(err) {
                console.log("load error", err)
              }
            } else {
              console.log("error load", msg)
            }

            resolve()
          })

        })
      }
    })
    .then(() => AppState.userGetStars())
    .then((stars) => AppState.userStars = stars)
    .then(() => AppState.initTeamObjectivesState())
  }

  clearSave() {
    return this.hasSave()
    .then(id => this.deleteSaves([id]))
    .then(() => AppState.userSaveStars(0, false))
  }


  deleteSaves(ids) {
    return new Promise( (resolve, reject) => {
      if (ids.length === 0)
        resolve()
      else {
        let id = ids.pop()
        AppState.vertxClient.UserApplicationSave_Destroy(id, (success, msg, data) => {
          if(success) {
            console.log("SAVE " + id + " DESTROYED")
          } else {
            console.log("SAVE " + id + " DESTROY FAILED : ", msg)
          }
          this.deleteSaves(ids).then(() => resolve())
        })
      }
    })
  }

}


export default new SaveGame()