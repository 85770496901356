import AppState, { POPUPS } from '@/js/AppState';
import { observer } from 'mobx-react-lite';
import PopupsManager from 'react-sdk/PopupsManager';

import PopupReplayMinigame from '@/components/popups/PopupReplayMinigame/PopupReplayMinigame';
import Button from '@/components/shared/Button/Button';
import TeamHeader from '@/components/shared/TeamHeader/TeamHeader';
import Dial from '@cmp/Dial/Dial';
import ObjectiveBar from '@cmp/Hud/ObjectiveBar/ObjectiveBar';
import Map from '@cmp/Map/Map';
import PopupBoutique from '@pops/PopupBoutique/PopupBoutique';
import PopupClassement from '@pops/PopupClassement/PopupClassement';
import PopupCollecte from '@pops/PopupCollecte/PopupCollecte';
import PopupObjective from '@pops/PopupObjective/PopupObjective';
import PopupTeam from '@pops/PopupTeam/PopupTeam';
import PopupUser from '@pops/PopupUser/PopupUser';
import { FaBars, FaEnvelopeOpenText, FaRegBell } from 'react-icons/fa6';
import classes from './ScreenGame.module.css';

import ChapterBar from '@/components/Hud/ChapterBar/ChapterBar';
import PopupMinigameSequence from '@/components/popups/PopupMinigameSequence/PopupMinigameSequence';
import PopupMinigameStars from '@/components/popups/PopupMinigameStars/PopupMinigameStars';
import PopupNouveautes from '@/components/popups/PopupNouveautes/PopupNouveautes';
import GroupMessages from '@/js/GroupMessages';
import Minigames from '@/js/Minigames/Minigames';
import Reliques from '@/js/Reliques/Reliques';
import SaveGame from '@/js/SaveGame';
import SequenceScenario from '@/js/SequenceScenario';
import { useEffect } from 'react';
import Texts from 'react-sdk/Texts';
import { getUrlVar } from 'react-sdk/utils';
import clsx from 'clsx';

import image_cloche from './popup_notif_cloche.png'

const ScreenGame = () => {
  useEffect(() => {

    if(AppState.test_mg_mode) return

    if(!SaveGame.getState("sequenceIntroVue")) {
      SaveGame.setState("sequenceIntroVue", true)
      SaveGame.save()
    }

    GroupMessages.init()
    GroupMessages.fetchNew()
    .finally(() => {
      if(GroupMessages.unreadMessages.length > 0) {
        /** @type {import('@/components/popups/PopupNotif/PopupNotif').NotifPopupOptions} */
        const data = {
          title: Texts.get("popup-nouveautes-notif-title", {mustacheVars: {nb: GroupMessages.unreadMessages.length}}),
          text: Texts.get("popup-nouveautes-notif-text"),
          image_url: image_cloche,
          Buttons: () => (<Button onClick={() => {
            PopupsManager.close(POPUPS.NOTIF)
            PopupsManager.open(POPUPS.NOUVEAUTES)
          }}>{Texts.get("popup-nouveautes-notif-btn")}</Button>)

        }
        PopupsManager.open(POPUPS.NOTIF, data)
      }
    })

  }, []);

  function handleTeamPPClick() {

    console.log("LOCKHUD : test", AppState.lockHud);

    if(AppState.lockHud) return

    PopupsManager.open(POPUPS.TEAM)
  }



  return (
    <div className={classes.ScreenGame}>

      {getUrlVar("nomap") === null && <Map visible={AppState.mapVisible}/>}

      <div className={clsx(classes.top_hud, !AppState.UI_visibilities.game_header && classes.invisible, AppState.lockHud && classes.locked)} >
        <TeamHeader small onClick={handleTeamPPClick} />


        <div className={classes.top_hud_buttons}>
          {/* <div className={classes.hud_menu_btn}><Button icon={<FaBars />} /></div> */}
          {/* <div className={classes.hud_mail_btn}><Button icon={<FaEnvelopeOpenText />} /></div> */}

          <div
          className={classes.hud_bell_btn}
          onClick={() => PopupsManager.open(POPUPS.NOUVEAUTES)}
          >
            <Button icon={<FaRegBell />} />
            {GroupMessages.unreadMessages.length > 0 && <div className={classes.hud_notif}></div>}
          </div>
        </div>
      </div>

      {AppState.debugMode &&
      <div className={classes.debug}>
        <details>
          <summary>DEBUG</summary>
          <div>
            <button onClick={() => PopupsManager.open(POPUPS.TEAM)}>Show team page</button>
            <button onClick={() => PopupsManager.open(POPUPS.USER)}>Show user page</button>
            <button onClick={() => PopupsManager.open(POPUPS.LEADERBOARD)}>Show leaderboard page</button>
            <button onClick={() => PopupsManager.open(POPUPS.REPLAY_MG)}>Show Replay MG</button>
            <button onClick={() => PopupsManager.open(POPUPS.BOUTIQUE)}>Show Boutique</button>
            <button onClick={() => SequenceScenario.playSequence("dis_bureauTrash")}>OPEN BUREAU TRASH</button>
            <button onClick={() => SequenceScenario.playSequence("dis_magasinPeinture")}>OPEN MAGASIN PEINTURE</button>
            <button onClick={() => SequenceScenario.playSequence("dis_universite")}>OPEN UNIVERSITE</button>
            <button onClick={() => SequenceScenario.playSequence("dis_laboHope")}>OPEN LABO</button>
            <button onClick={() => Reliques.all[0].unlock()}>unlock relique 0</button>
            <button onClick={() => SequenceScenario.next()}>SequenceScenario.next</button>

            <button onClick={() => {
              AppState.vertxClient.GroupMessagePush(
                AppState.team.groupId,
                JSON.stringify({NewCollectInfo: "debug_1724860311354"}),
                (success, msg, data) => {
                  console.log("GroupMessagePush", success, msg, data)
                }
              )
            }}>Simuler une nouvelle collecte</button>

            <button onClick={() => {
              AppState.vertxClient.GroupMessagePush(
                AppState.team.groupId,
                JSON.stringify({CustomDataInfo: {Info: {Boutique: {Log: "La commande 123456 a bien été enregistrée"}}}}),
                (success, msg, data) => {
                  console.log("GroupMessagePush", success, msg, data)
                }
              )
            }}>Simuler un message de la boutique</button>


            <button onClick={() => {
              AppState.vertxClient.GroupMessagePush(
                AppState.team.groupId,
                JSON.stringify({BlasonUnlocked: "platinum"}),
                (success, msg, data) => {
                  console.log("GroupMessagePush", success, msg, data)
                }
              )
            }}>Simuler un gain de blason</button>

            <button onClick={() => {
              AppState.vertxClient.GroupMessagePush(
                AppState.team.groupId,
                JSON.stringify({PeriodChanged: {new: "ete_2024" }
                }),
                (success, msg, data) => {
                  console.log("GroupMessagePush", success, msg, data)
                }
              )
            }}>Simuler une notif de période (new seulement)</button>

            <button onClick={() => {
              AppState.vertxClient.GroupMessagePush(
                AppState.team.groupId,
                JSON.stringify({PeriodChanged: {old: "automne_2024", new: "hiver_2024" }
                }),
                (success, msg, data) => {
                  console.log("GroupMessagePush", success, msg, data)
                }
              )
            }}>Simuler une notif de période (old+new)</button>


            <button onClick={() => {
              AppState.vertxClient.GroupMessagePush(
                AppState.team.groupId,
                JSON.stringify({ObjectiveCompleted: "estim1"}),
                (success, msg, data) => {
                  console.log("GroupMessagePush", success, msg, data)
                }
              )
            }}>Simuler une complétion d'objectif d'équipe ROOT</button>

            <button onClick={() => {
              AppState.vertxClient.GroupMessagePush(
                AppState.team.groupId,
                JSON.stringify({ObjectiveAvailable: "estim1.1"}),
                (success, msg, data) => {
                  console.log("GroupMessagePush", success, msg, data)
                }
              )
            }}>Simuler une activation d'objectif d'équipe: 1er CHILD</button>

            <button onClick={() => {
              AppState.vertxClient.GroupMessagePush(
                AppState.team.groupId,
                JSON.stringify({ObjectiveAvailable: "estim1.2"}),
                (success, msg, data) => {
                  console.log("GroupMessagePush", success, msg, data)
                }
              )
            }}>Simuler une activation d'objectif d'équipe: 2eme CHILD</button>

            <button onClick={() => {
              AppState.currentMeeting.customActivityCommand("RefreshObjectives", AppState.teamObjectivesActivityId, false, { "Group" : AppState.team.groupId })
            }}>REFRESH TEAM OBJECTIFS</button>

            <button onClick={() => {
              AppState.vertxClient.GroupCustomDataIncrementNumericalValue(AppState.team.groupId, "Points", 100, true, (success, msg, data) => {
                console.log("GroupCustomDataIncrementNumericalValue ", success, msg, data)
              })
            }}>Add 100 Points</button>
            <button onClick={() => {
              AppState.vertxClient.GroupCustomDataIncrementNumericalValue(AppState.team.groupId, "Points", -100, true, (success, msg, data) => {
                console.log("GroupCustomDataIncrementNumericalValue ", success, msg, data)
              })
            }}>Remove 100 Points</button>


            <div>SequenceScenario : {SequenceScenario.currentElement?.id}</div>
            <div>STARS : {AppState.userStars}</div>

          </div>



        </details>
      </div>}

      <div className={classes.bottom_hud}>
        {!PopupsManager.isOpened(POPUPS.NEW_OBJECTIVE) &&
        <ObjectiveBar />}

        {AppState.soloObjectives.rootObjectives.length > 0 &&
        <ChapterBar />}

      </div>

      {PopupsManager.isOpened(POPUPS.TEAM) &&
      <PopupTeam team={AppState.team} />}

      {PopupsManager.isOpened(POPUPS.USER) &&
      <PopupUser />}

      {PopupsManager.isOpened(POPUPS.NOUVEAUTES) &&
      <PopupNouveautes />}

      {PopupsManager.isOpened(POPUPS.COLLECTE) &&
      <PopupCollecte />}



      {PopupsManager.isOpened(POPUPS.LEADERBOARD) &&
      <PopupClassement />}

      {PopupsManager.isOpened(POPUPS.BOUTIQUE) &&
      <PopupBoutique />}

      {PopupsManager.isOpened(POPUPS.NEW_OBJECTIVE) &&
      <PopupObjective />}


      {PopupsManager.isOpened(POPUPS.REPLAY_MG) &&
      <PopupReplayMinigame />}

      {Minigames.current &&
      <PopupMinigameSequence />}

      {PopupsManager.isOpened(POPUPS.MINIGAME_STARS) &&
      <PopupMinigameStars />}





      <Dial />
    </div>
  );
};

export default observer(ScreenGame);
