import { makeAutoObservable } from "mobx";
import PopupsManager from "react-sdk/PopupsManager";
import Texts from "react-sdk/Texts";
import { PopupNotifsReliqueBtns } from "@/components/popups/PopupNotif/PopupNotifBtns";
import { POPUPS } from "../AppState";

class Relique {
  unlocked = false;

  constructor(json) {
    Object.assign(this, json);
    makeAutoObservable(this);
  }

  unlock() {
    this.unlocked = true;
  }
}

class Reliques {
  all = [];

  init(json) {
    this.all = json.filter((elem) => elem.id).map((elem) => new Relique(elem));
  }

  get ids() {
    return this.all.map((c) => c.id);
  }

  /**
   *
   * @param {string} id relique ID
   * @returns {Relique}
   */
  get(id) {
    return this.all.find((c) => c.id === id);
  }

  stateToJson() {
    let state = this.all.map((r) => {
      return {
        id: r.id,
        unlocked: r.unlocked,
      };
    });

    return state;
  }

  stateFromJson(save) {
    save.forEach((data) => {
      let r = this.get(data.id);
      if (r) {
        r.unlocked = data.unlocked;
      }
    });
  }
}

export default new Reliques();
