import React, {useState, useEffect} from 'react'

import classes from './ProfilePic.module.css'

const ProfilePic = ({img, color, pseudo}) => {

  return (
    <div className={classes.ProfilePic}>
      {pseudo && pseudo.split(" ").map(w => w[0])}
    </div>
  );
};

export default ProfilePic;
