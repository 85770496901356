import popup_classes from '../Popups.module.css';
import classes from './PopupUser.module.css';

import Objective from "@cmp/Objective/Objective";
import clsx from "clsx";

import ObjectifDef from "@/js/Objectives/Objective";

import Texts from "react-sdk/Texts";
import AppState from '@/js/AppState';
import ObjectiveFile from '@/js/Objectives/ObjectiveFile';

/**
 *
 * @param {Object} props
 * @param {ObjectifDef} props.objective
 * @returns
 */



const ChapterObjectives = ({chapter, className}) => {
  const objs = chapter.children.filter(o => ObjectiveFile.isObjActive(o))
  const objs_f = chapter.children.filter(o => ObjectiveFile.isObjCompleted(o))
  // const objs_f = chapter.children.filter(obj => obj.state===states.FINISHED)

  return (
    <>
      <div className={clsx(classes.chapter, className)}>{chapter.name}</div>
      {objs
      .map(obj => <Objective objective={obj} key={obj.id} />)}
      {objs_f
      .map(obj => <Objective objective={obj} key={obj.id} />)}
    </>
  )
}


const PageUsersObjective = () => {

  return (
    <div className={clsx(popup_classes.page, classes.PageUsersObjective)}>
      <h4>{Texts.get("popup-user-tab1-title")}</h4>
      <div className={clsx(classes.Objectives, "ftue-user-objectives")}>

        {AppState.soloObjectives.activeRootObjectives
        .map(chapter =>
        <ChapterObjectives chapter={chapter} key={chapter.id} />)}


        {AppState.soloObjectives.completedRootObjectives
        .map(chapter =>
        <ChapterObjectives chapter={chapter} className={classes.completed} key={chapter.id} />)}

      </div>
    </div>
  )
}

export default PageUsersObjective