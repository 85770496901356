import classes from './Draggable.module.css'

import { useDraggable } from "@dnd-kit/core";
import clsx from "clsx";
import React from "react";
import Media from "../../Media/Media";

import { STATES } from "react-sdk/activities/ActivitiesConstants";


const Draggable = ({item, inTarget, dragged=false, showCorrection, disabled=false}) => {

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: item.ID,
    data: {type: item.target},
    disabled
  });

  const style = {
    opacity: transform ? 0.1 : 1
  }

  let correctionState
  if(showCorrection) correctionState = item.getCorrectionState(inTarget)

  return (
    <div className={classes.draggable_ctnr} ref={setNodeRef} style={style} {...listeners} {...attributes}>

      <div className={clsx(
      classes.draggable,
      item.media && classes.with_media,
      dragged && classes.dragged,
      (showCorrection && correctionState === STATES.CORRECT) && classes.correct,
      (showCorrection && correctionState === STATES.WRONG) && classes.wrong,
      (showCorrection && correctionState === STATES.MISSED) && classes.missed,
      )}>

        {item.media &&
        <div className={classes.media_ctnr}>
          <Media path={item.media} />
        </div>}

        {item.Text &&
        <div className={classes.draggable_text}>{item.Text}</div>}
      </div>
    </div>
  );

}

export default Draggable