export default class LP_api {
  onFullscreen = false
  args = null
  registeredFunctions = {}

  onCallfunction = null

  getVars() {
    if(this.args.vars) return this.args.vars
    return {}
  }

  openSettings(onClose) {
    this.args = {onClose}
  }

  endGame(vars){
    this.args = vars
  }

  getVertxApi(){
    return AppState.vertxApi
  }

  callFunction(name, param) {
    let ret = null

    if(this.onCallfunction) {
      this.onCallfunction(name, param)
    }

    return ret
  }

  toggleFullscreen() {
    if(this.onFullscreen){
      deactivateFullscreen()
      this.onFullscreen = false
    }
    else {
      activateFullscreen()
      this.onFullscreen = true
    }
  }

  openVideo(id, onClose) {
    this.args = {id, onClose}
  }

  registerVirtualKeyboardEvent(callback) {
    if ('virtualKeyboard' in navigator) navigator.virtualKeyboard.addEventListener('geometrychange', callback)
  }

  registerFunction(name, func) {
    this.registeredFunctions[name] = func
  }
}



function activateFullscreen() {
  var isFullscreenAvailable = document.fullscreenEnabled;
  if(!isFullscreenAvailable) {
    console.log("fullscreen not authorized")
    return
  }
  window.document.documentElement.requestFullscreen()
}

function deactivateFullscreen() {
  var isFullscreenAvailable = document.fullscreenEnabled;
  if(!isFullscreenAvailable) {
    console.log("fullscreen not authorized")
    return
  }
  window.document.exitFullscreen()
}