import React, {useState, useEffect} from 'react'

import classes from './ScreenUser.module.css'
import HomeScreens from '@scr/_layouts/HomeScreens/HomeScreens';
import AppState, { SCREENS } from '@/js/AppState';
import Button from '@cmp/shared/Button/Button';
import Texts from 'react-sdk/Texts';
import ProfilePic from '@/components/shared/ProfilePic/ProfilePic';
import Config from 'react-sdk/Config';

const ScreenUser = () => {
  const maxlength = Config.get("pseudoUserMaxLength", 15)

  const [loading, setLoading] = useState(false);


  let user = AppState.vertxClient.UserData
  const [pseudo, setPseudo] = useState(user.getPseudo() || "");

  const [error, setError] = useState(false);

  const remaining = maxlength - pseudo.length

  function updatePseudo(e) {
      setError(false)

      setPseudo(e.target.value)
  }


  function handleClick() {

    // 1/ updater le pseudo en ligne
    // 2/ passer à l'écran suivant
    //
    setLoading(true)

    if(pseudo !== user.getPseudo()) {
      AppState.vertxClient.UserUpdatePseudo(pseudo, (success, msg, data) => {
        if(success) {
          nextScreen()
        } else {
          setLoading(false)
          setError(true)
        }
      })
    }
    else {
      nextScreen()
    }

    function nextScreen() {
      if(AppState.userIsReferent) {
        AppState.setScreen(SCREENS.TEAM_PSEUDO)
      }
      else {
        AppState.setScreen(SCREENS.TEAM)
      }
    }

  }


  return (
    <HomeScreens
    title={Texts.get("user-screen-title")}
    >
      <div className={classes.content}>
        <div className={classes.pp}>
          <ProfilePic pseudo={pseudo} />
        </div>
        <div className={classes.pseudo}>
          <input required maxLength={15} type="text" value={pseudo} onChange={updatePseudo} placeholder={Texts.get("user-screen-placeholder")} />
          {/* <div className={classes.remaining}>{remaining}</div> */}
        </div>

        {error &&
        <div className={classes.error}> {Texts.get("user-screen-pseudo-error")}</div>}

        <div className={classes.button_ctnr}>
          <Button disabled={loading || !pseudo} onClick={handleClick}>{Texts.get("user-screen-button")}</Button>
        </div>

      </div>

    </HomeScreens>
  );
};

export default ScreenUser;
