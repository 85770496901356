import { states } from '@/js/Objectives/ObjectiveFile';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import classes from './ObjectiveBar.module.css';
import star from '../../../images/star.png'
import Texts from 'react-sdk/Texts';
import AppState from '@/js/AppState';


const ObjectiveBar = () => {

  if(AppState.soloObjectives.activeRootObjectives.length === 0 ) return null

  var childrenObjs = AppState.soloObjectives.rootObjectives.reduce((acc, chapter) => {
    return [...acc, ...chapter.children
    .filter(objective => objective.state === states.ACTIVE || objective.state === states.COMPLETED  )]
  }, [])


  return (
    <div className={classes.ObjectiveBar}>
      <div className={classes.title}>{Texts.get("objective-bar-title")}</div>

      {childrenObjs.length === 0 &&
      <div className={clsx(classes.Objective)} >
        Pas d'objectif en cours
      </div>}

      {childrenObjs.map(objective => {
        return (
        <div key={objective.id} className={clsx(classes.Objective, classes[objective.state])}>
          <div className={classes.obj_name}>{objective.name}</div>

          {objective.state === states.COMPLETED  &&
          <div className={classes.objective_reward}>
            +{objective.sourceObjective.line.get("reward")} <img src={star} />
          </div>
          }
        </div>)
      })}


      {/* {AppState.soloObjectives.rootObjectives.map(chapter => {

        let children = chapter.children
        .filter(objective => objective.state === states.ACTIVE || objective.state === states.COMPLETED  )


        return (
        <div className={clsx(classes.Objective, classes[chapter.state])} key={chapter.id}>

        </div>
        )

      }) } */}
    </div>
  );
};

export default observer(ObjectiveBar)
