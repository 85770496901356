import { makeAutoObservable, toJS } from "mobx";
import SequencesManager, { SequencesFile } from "react-sdk/SequencesManager";
import SoundPlayer from "react-sdk/SoundPlayer";
import AppState, { POPUPS } from "./AppState";
import Minigames from "./Minigames/Minigames";
import Reliques from "./Reliques/Reliques";

import FTUE from "./FTUE";
import ObjectiveFile from "./Objectives/ObjectiveFile";
import SaveGame from "./SaveGame";
import Texts from "react-sdk/Texts";
import { PopupNotifsReliqueBtns } from "@/components/popups/PopupNotif/PopupNotifBtns";
import PopupsManager from "react-sdk/PopupsManager";
import Analytics from "react-sdk/Vertx/Analytics";

class SequenceScenario {
  mainSeq = null;
  currentElement = null;
  onEnd = null;

  constructor() {
    makeAutoObservable(this);
  }

  init() {


    // NOTE ceci sont les actions/getters appelés depuis les fichiers .data de scénario

    SequencesManager.registerAction("openSequenceScenario", (filename, seqId) => {
      this.playSequence(filename, seqId, () => {
        console.log("Fin sequence", filename, seqId)
      })
    });


    SequencesManager.registerAction("showDialSequence", (filename, id) => {
      AppState.dials.playSequence(filename, id, () => {
        this.next();
      });
    });

    SequencesManager.registerAction("showMinigame", (id) => {
      Minigames.play(id, () => this.next());
    });

    SequencesManager.registerAction("unlockObjective", (id) => {
      let obj = AppState.soloObjectives.getObjective(id)
      if (obj) {
        obj.sourceObjective.available.force(null, true);
        AppState.soloObjectives.refresh()
      }
    });

    SequencesManager.registerAction("completeObjective", (id) => {
      let obj = AppState.soloObjectives.getObjective(id)
      if (obj) {
        obj.sourceObjective.completed.force(null, true);
        AppState.soloObjectives.refresh()

        // TODO ca serait bien de faire ca via un callback
        setTimeout(() => {
          this.next();
        }, 1000);
      }
    });

    SequencesManager.registerAction("unlockRelique", (id) => {
      let relique = Reliques.get(id);
      Analytics.scoreEvents(id, 1)

      if (relique) {
        relique.unlock();

        /** @type {import("@/components/popups/PopupNotif/PopupNotif").NotifPopupOptions} */
        let data = {
          sfx: "collec_relique",
          surtitle: Texts.get("popup-notif-reliques-surtitle"),
          title: relique.title,
          image_url: "data/images/" + relique.image,
          text: Texts.get("popup-notif-reliques-text"),
          color: relique.color,
          rays: true,
          Buttons: PopupNotifsReliqueBtns,
        };
        PopupsManager.queue(POPUPS.NOTIF, data, () => this.next());
      }
    });

    SequencesManager.registerAction("showFTUE", (id) => {
      let tour = FTUE[id];
      if (tour) {
        tour.on("complete", () => {
          this.next()
        });
        tour.start();
      }
    });

    SequencesManager.registerAction("setSaveState", (id, value) => {
      SaveGame.setState(id, value)
    });

    SequencesManager.registerAction("saveGame", () => {
      SaveGame.save()
      .then(() => {
        this.next()
      })
    });

    SequencesManager.registerAction("showUI", (id, visible) => {
      AppState.UI_visibilities = {...AppState.UI_visibilities, [id]: visible}
      this.next()
    })

    SequencesManager.registerAction("lockHUD", (val) => {
      AppState.lockHud = val
      this.next()
    })


    // permet d'unlock un POI depuis une ligne du SM
    SequencesManager.registerAction("UnlockPoi", (id, ftue_tourid) => {

      SoundPlayer.playSound("map_newPOI")
      let params = { id: id }
      if(ftue_tourid) {
        params.ftue_callback = (bb) => {
          AppState.mapFtueBatimentBoundingBox = bb
          setTimeout(() => {
            let tour = FTUE[ftue_tourid]
            if(tour) {
              tour.on("complete", () => {
                this.next()
              });
              tour.start()

            }
          }, 20);
        }
      }

      AppState.reaxe_api.toHaxe("map", "UnlockPoi", params, () => {
          if(!ftue_tourid) this.next() // sinon appelé par la fin de la ftue
        }
      )
    })

    // permet d'unlock un POI depuis une ligne du SM
    SequencesManager.registerAction("LockPoi", (id) => {
      AppState.reaxe_api.toHaxe(
        "map",
        "LockPoi",
        id,
        () => {
          this.next()
        }
      )
    })
    SequencesManager.registerAction("HidePoi", (id) => {
      AppState.reaxe_api.toHaxe(
        "map",
        "HidePoi",
        id,
        () => {
          this.next()
        }
      )
    })


    SequencesManager.registerAction("UnlockZone", (id) => {
      SoundPlayer.playSound("map_newPOI")
      AppState.reaxe_api.toHaxe(
        "map",
        "UnlockZone",
        id,
        () => {
          this.next()
        }
      )
    })


    SequencesManager.registerAction("HideAllPois", () => {

      AppState.reaxe_api.toHaxe(
        "map",
        "HideAllPois",
        null,
        () => {
          this.next()
        }
      )
    })

    // permet de changer un char d'un poi depuis une ligne du SM
    SequencesManager.registerAction("ChangePoiChar", (poi_id, char_id) => {
      AppState.reaxe_api.toHaxe(
        "map",
        "ChangePoiChar",
        {poi: poi_id, char: char_id, centerCamera: false},
        () => {
          this.next()
        }
      )


    });



    SequencesManager.registerGetter("getSaveState", (id) => {
      return SaveGame.getState(id)
    });

    SequencesManager.registerGetter("objectiveCompleted", (id) => {
      let obj = AppState.soloObjectives.getObjective(id)
      if (obj) {
        let completed = ObjectiveFile.isObjCompleted(obj)
        return completed
      }
      console.error("Fichier de sequence, erreur appel objectiveCompleted, auccun objectif avec cette id : " + id)
      return false
    });

    SequencesManager.registerGetter("isCurrentObjective", (id) => {
      // NOTE malgré son nom, on check les rootObjectives
      let obj = AppState.soloObjectives.activeRootObjectives.at(-1)
      if(obj) {
        return obj.id === id
      }
      return false;
    });

    SequencesManager.registerGetter("objectiveInactive", (id) => {
      let obj = AppState.soloObjectives.getObjective(id)
      if (obj) {
        return ObjectiveFile.isObjInactive(obj)
      }
      console.error("Fichier de sequence, erreur appel objectiveInactive, auccun objectif avec cette id : " + id)
      return true
    });

    // TODO
    SequencesManager.registerGetter("poiLocked", (id) => {
      return true;

      // let obj = ObjectivesLib.getObjective(id);
      // if (obj) {
      //   return obj.state === states.INACTIVE;
      // }
      // return false;
    });




    // NOTE les fonctions ci-dessous sont appelées depuis le fichier pois.data

    // window.LP_API = lp_api
    let DM = succubus.data.DataManager.instance

    DM.scriptManager.registerFunction("openSequenceScenario", (filename, seqId) => {
      AppState.reaxe_api.toHaxe("map", "freeze")

      this.playSequence(filename, seqId, () => {
        AppState.reaxe_api.toHaxe("map", "unfreeze")
      })
    });

    // on n'utilise plus les POIS pour ouvrir ces popups
    // DM.scriptManager.registerFunction("openPopupTeam", () => {
    //   AppState.reaxe_api.toHaxe("map", "freeze")

    //   PopupsManager.open(POPUPS.TEAM, null, () => {
    //     AppState.reaxe_api.toHaxe("map", "unfreeze")
    //   })
    // });

    // DM.scriptManager.registerFunction("openPopupUser", () => {
    //   AppState.reaxe_api.toHaxe("map", "freeze")

    //   PopupsManager.open(POPUPS.USER, null, () => {
    //     AppState.reaxe_api.toHaxe("map", "unfreeze")
    //   })
    // });

    DM.scriptManager.registerFunction("openLeaderboard", () => {
      AppState.reaxe_api.toHaxe("map", "freeze")

      PopupsManager.open(POPUPS.LEADERBOARD, null, () => {
        AppState.reaxe_api.toHaxe("map", "unfreeze")
      })
    });


    DM.scriptManager.registerFunction("openPopupReplayMinigame", () => {
      AppState.reaxe_api.toHaxe("map", "freeze")

      PopupsManager.open(POPUPS.REPLAY_MG, null, () => {
        AppState.reaxe_api.toHaxe("map", "unfreeze")
      })
    });

    DM.scriptManager.registerFunction("openBoutique", () => {
      AppState.reaxe_api.toHaxe("map", "freeze")
      PopupsManager.open(POPUPS.BOUTIQUE, null, () => {
        AppState.reaxe_api.toHaxe("map", "unfreeze")
      })
    });
  }

  addFile(filename, content) {
    // NOTE ceci ajoute automatiquement au SM
    SequencesFile.createFromDataFile(filename, content);
  }

  playSequence(filename, id, onEnd) {

    this.mainSeq = SequencesManager.playSequence(filename, id);
    this.onEnd = onEnd;

    if (this.mainSeq) {
      let el = this.mainSeq.getCurrentElement();
      if (el.Type === "auto") {
        this.next();
      } else {
        this.currentElement = el;
      }
    }
    else {
      if(onEnd) onEnd();
    }
  }

  next() {
    if (this.mainSeq?.hasNext()) {
      let el = this.mainSeq.next();
      if (el.Type === "auto") {
        this.next();
      } else {
        this.currentElement = el;
      }
    } else {
      this.quit();
    }
  }

  quit() {
    SoundPlayer.playSound("music_map");
    this.mainSeq = null;
    this.currentElement = null;
    AppState.dials.deleteAllCharacters();

    AppState.soloObjectives.refresh()

    // ObjectivesLib.refresh();
    if (this.onEnd) this.onEnd();

    this.onEnd = null;
  }
}

export default new SequenceScenario();
