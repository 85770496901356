import { makeAutoObservable, makeObservable, observable } from "mobx";
import Objective from "./Objective";


export const states = {
  INACTIVE: "INACTIVE",
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
  FINISHED: "FINISHED"
}



class ObjectiveFile {

  fileId
  objFile

  /** @type {Array<Objective>} */
  objectives = []

  constructor(fileId, isExternFile = false) {
    this.libHaxe.init(); // pas grave si appelé plusieurs fois


    this.fileId = fileId
    this.objFile = this.instance.createFileFromId(fileId, isExternFile)


    this.objectives = this.objFile.objectives.map(o => new Objective(o))

    this.refreshDone = this.refreshDone.bind(this)

    makeObservable(this, {
      objectives: observable
    })


    // makeAutoObservable(this)
  }

  refresh(done = this.refreshDone) {
    this.objFile.refresh((hasChanged, hasProgresschanged) => {
      if (hasChanged) {
        // on refait un refresh si y'a eu un changement d'état, dans le cas où un objectif devient actif suite à la complétion d'un autre objectif
        this.refresh(done)
      }
      else {
        done()
      }


    })
  }

  getObjective(id) {
    console.log("getObjective", id)
    return this.objectives.find(obj => obj.id === id)
  }

  get libHaxe() {
    return succubus.objective.ObjectivesLib
  }
  get instance() {
    return this.libHaxe.instance;
  }

  get inactiveObjectives() {
    return this.objectives.filter(o => ObjectiveFile.isObjInactive(o))
  }
  get activeObjectives() {
    return this.objectives.filter(o =>  ObjectiveFile.isObjActive(o))
  }
  get completedObjectives() {
    return this.objectives.filter(o => ObjectiveFile.isObjCompleted(o))
  }

  get rootObjectives() {
    return this.objectives.filter(o =>  !o.parent)
  }
  get activeRootObjectives() {
    return this.rootObjectives.filter(o =>  ObjectiveFile.isObjActive(o))

  }
  get completedRootObjectives() {
    return this.rootObjectives.filter(o =>  ObjectiveFile.isObjCompleted(o))
  }


  refreshDone(hasChanged) {
    console.log("Objective file", this.fileId, "done, ", (hasChanged ? " with changes" : "no changes"))
  }

  stateToJson() {
    // return this.instance.stateToJson()
    return  this.objFile.stateToJson()
  }

  stateFromJson(data, forExtern) {
    let context = null
    this.objFile.stateFromJson(context, data, forExtern)

    this.refresh(() => {
      // ne refresh que la partie "react", les states obj de haxe étant set par le stateFromJson
      this.objectives.forEach(obj => {
        obj.refreshState()
      })
    })




  }



  /**
   *
   * @param {Objective} o
   * @returns
   */
  static isObjInactive(o) {
    return o.state === states.INACTIVE
  }
  static isObjActive(o) {
    return o.state === states.ACTIVE
  }
  static isObjCompleted(o) {
    return o.state === states.COMPLETED || o.state === states.FINISHED
  }

}


export default ObjectiveFile