import { Howl } from "howler"

import { makeAutoObservable } from "mobx"
import { pickRandom } from "./utils"

class Sound {
  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)
  }
}

class SoundPlayer {

  all = []

  _mute = false

  previousId = null
  currentId = null


  constructor() {
    // 2 players : un pour la msique, un pour le son (comme ca les sons peuvent être joués en meme tps que la zik)

    /** @type <Howl> */
    this.musicPlayer = null
  }

  init(json) {
    this.all = json
    .filter(elem => elem.id).map(elem => new Sound(elem))
  }

  get ids() { return this.all.map(c => c.id)}

  mute(val) {
    console.log("SoundPlayer mute", val);
    if(!this.musicPlayer) return

    if(typeof val === "boolean") {
      this._mute = val
      this.musicPlayer.mute(val)
    }
    else {
      this.prevVolume = this.musicPlayer.volume()
      this.musicPlayer.volume(val)
    }
  }
  unmute() {

    if(this.prevVolume && this.musicPlayer) {
      console.log("unmute");
      this.musicPlayer.volume(this.prevVolume)

    }
  }

  get(id) { return this.all.find(c => c.id === id) }


  playSound(id, callback = null) {

    let s = this.get(id)
    if(!s) return

    if(s.type === "musicRandom") {
      let _id = pickRandom(s.path.split('|'))
      this.playSound(_id)
      return
    }



    if(s.type === "music") {
      console.log("playSound music", id, s.volume);

      if(this.currentId === id) return
      if(this.musicPlayer) {
        if(this.currentId) this.previousId = this.currentId
        this.musicPlayer.fade(this.musicPlayer.volume(), 0, 1000)
      }

      this.currentId = id
      // console.log("playing music", id)
      let src = [`${s.path}.mp3`, `${s.path}.ogg`]

      this.musicPlayer = new Howl({
        src: src,
        autoplay: true,
        loop: true,
        volume: s.volume === null ? 1 : s.volume,
        mute: this._mute
      })
    }

    if(s.type === "sfx") {
      console.log("playSound sfx", id);
      // console.log("playing sound", id)
      let src = [`${s.path}.mp3`, `${s.path}.ogg`]
      new Howl({
        src: src,
        autoplay: true,
        loop: false,
        volume: s.volume || 1,
        onend: callback,
        mute: this._mute
      })

    }

  }

  playPreviousMusic () {
    if(this.previousId) {
      this.playSound(this.previousId)
    }
  }

  stop() {
    if(this.musicPlayer) {
      this.musicPlayer.stop()
      this.musicPlayer.unload()
      this.musicPlayer = null
    }
  }

  pause() {
    if(this.musicPlayer) this.musicPlayer.pause()

  }
  unpause() {
    if(this.musicPlayer) this.musicPlayer.play()

  }
}


export default new SoundPlayer()
