import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import AppState, { SCREENS } from '@/js/AppState';


import classes from './ScreenLogin.module.css';


import Texts from 'react-sdk/Texts';
import Button from '@cmp/shared/Button/Button';
import HomeScreens from '@scr/_layouts/HomeScreens/HomeScreens';

export const STEP = {
  SENDMAIL: "SENDMAIL",
  MAILSENT:"MAILSENT"
}

const ScreenLogin = () => {

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [step, setStep] = useState(STEP.SENDMAIL);

  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState(null);

  function handleSubmit(e) {
    e.preventDefault()
    setSubmitting(true)
    const {universe, app} = window.CONFIG.vertx
    AppState.vertxClient.UserConnectPasswordless_GetConnectionTokenByMail(universe, app, email, window.location.href, (success, msg, data) => {
      setSubmitting(false)
      if(success) {
        setStep(STEP.MAILSENT)
      } else {
        setError("login-mail-not-found")
      }
    })

  }

  function handleSubmitCode(e) {
    e.preventDefault()
    setSubmitting(true)
    const {universe, app} = window.CONFIG.vertx
    AppState.loginWithCode(universe, app, email, code)
    .catch(err =>{
      setSubmitting(false)
      console.log("err", err)
      setError("login-code-not-found")

    })
    .then(() => {
      AppState.setScreen(SCREENS.HOME)
    })


  }

  function handleChangeMail(e) {
    setError(null)
    setEmail(e.target.value.toLowerCase())
  }


  return (
    <>
      {step === STEP.SENDMAIL &&
      <HomeScreens
      title={Texts.get("login-title")}
      >
      <form className={classes.form} onSubmit={handleSubmit}>
        <input name="email" type="email" placeholder={Texts.get("login-placeholder")} value={email} onChange={handleChangeMail}/>
        <div className={classes.form_button_ctnr} >
          <Button disabled={submitting} >{Texts.get("login-button")}</Button>
        </div>
      </form>
      {error &&
      <div className={classes.error}>{Texts.get(error)}</div>}
      </HomeScreens>}

      {step === STEP.MAILSENT &&
      <HomeScreens
      title={Texts.get("login-step2-title")}
      text={Texts.get("mailsent-confirmation", {mustacheVars: {email}})}
      >
        <form className={classes.form} onSubmit={handleSubmitCode}>
          <input type="text" value={code} placeholder={Texts.get("code-placeholder")}
          onChange={e => setCode(e.target.value.toUpperCase())}/>
          <div className={classes.form_button_ctnr} >
            <Button disabled={submitting} >{Texts.get("valider-code-button")}</Button>
          </div>
        </form>

        {error &&
        <div className={classes.error}>{Texts.get(error)}</div>}
      </HomeScreens>}
    </>
  );
};

export default observer(ScreenLogin)
