import LP_api from 'react-sdk/LP_api'

class ReaxeAPI {
  api = null

  #moduleCallbacks = new Map()

  constructor() {

    this.api = new LP_api()
    // window.LP_API = lp_api


    this.api.onCallfunction = (name, data) => {
      // console.info("LPAPI : function called from game : ", name, "(", data, ")")

      // la Map est prête
      if(name === "signalGameReady" ) {
        // on lance l'affichage de la map
        this.api.registeredFunctions.startGame(false)


      }

      if(name === "haxeToReact" ) {
        let {module, event, params} = data

        if (module) {
          let cb = this.#moduleCallbacks.get(module);
          if (cb)
            cb(event, params);
        }
      }
    }

  }

  /**
   *
   * @param {String} module
   * @param {(event : String, params)} cb
   */
  registerModule(module, cb) {
    this.#moduleCallbacks.set(module, cb)
  }

  toHaxe(module, event, params = null, cb = null) {
    this.api.registeredFunctions.reactToHaxe({
      "module" : module,
      "event" : event,
      "params" : params
    },
    () => {
      if (cb)
       cb()
    })
  }

}


export default ReaxeAPI
