import { makeAutoObservable } from "mobx"
import Debrief from "./Debrief"
import { pickRandom } from "../utils"
import { STATES } from "./ActivitiesConstants"

import Notifications from "../Notifications/Notifications"

class Proposition {

  placed = null
  placedTS = null

  locked = false
  constructor(json) {
    Object.assign(this, json)

    makeAutoObservable(this)
  }

  get correct() {
    return this.placed && this.placed === this.target
  }

  getCorrectionState(targetId) {

    if(!this.correct && targetId === this.target) return STATES.MISSED
    if(this.correct) return STATES.CORRECT

    return STATES.WRONG

  }
}


export class DragDrop {
  ID
  Text = ""
  propositions = []
  targets = []
  debriefs = []

  /** @type {import("./ActivitiesConstants").ActivityAnswer} */
  answer = null

  jokerUsed = false  // TODO voir si on peut se passer de ca avec le answer

  constructor(json) {
    Object.assign(this, json)
  }

  observe() {
    makeAutoObservable(this)
  }

  // si on l'utilise direct depuis le json bien formaté
  fromJson(json) {
    Object.assign(this, json)

    /** @type {Array<Proposition>} */
    this.propositions = this.propositions.map(p => new Proposition(p))

  }


  setText(text) {
    this.Text = text
  }

  addProposition(json) {
    this.propositions.push(new Proposition(json))
  }

  getProposition(id) {
    return this.propositions.find(p => p.ID === id)
  }

  addTarget(json) {
    this.targets.push(json)
  }
  getTarget(id) {
    return this.targets.find(t => t.ID === id)
  }
  addDebrief(json, isCorrect) {
    this.debriefs.push(new Debrief(json, isCorrect))
  }

  get remainingPropositions() {
    return this.propositions.filter(p => !p.placed)

  }

  get placed() {
    return this.propositions.filter(p => p.placed)
  }


  get allPlaced() {
    return this.propositions.every(p => p.placed)
  }

  localCorrect() {
    let answer = {
      is_correct: this.propositions.every(p => p.correct )
    }
    this.setAnswer(answer)
  }

  setAnswer(answer) {
    this.answer = answer
  }

  getDebrief() {
    // ici on renvoie le debrief qu'il faut
    if(this.debriefs.length === 0) return null

    if(this.debriefs.length === 1) return this.debriefs[0]

    if(this.debriefs.length === 2) {
      if(this.answer.is_correct) {
        return this.debriefs.find(d => d.isCorrect)
      }
      else {
        return this.debriefs.find(d => !d.isCorrect)
      }
    }

    return null

  }

  place(proposition_id, target_id) {

    let proposition = this.propositions.find(p => p.ID === proposition_id)

    if(proposition) {
      proposition.placed = target_id
      proposition.placedTS = new Date().getTime()

    }

  }


  getSortedPropositionsByTarget(target) {
    return [...this.propositions]
    .sort((p1, p2) => {
      if(p1.placedTS < p2.placedTS) return 1
      if(p1.placedTS > p2.placedTS) return -1
      return 0
    })
    .filter(p => p.target===target.ID)
  }
  getSortedPlacedPropositionsByTarget(target) {
    return [...this.propositions]
    .toSorted((p1, p2) => {
      if(p1.placedTS < p2.placedTS) return 1
      if(p1.placedTS > p2.placedTS) return -1
      return 0
    })
    .filter(p => p.placed===target.ID)

  }

  useJoker() {
    if(!this.jokerAvailable) return

    let elem = null;
    // ici, on chope un élément dans le pool
    if(this.remainingPropositions.length > 0) {
      elem = this.remainingPropositions[0]
    }
    else {
      // si y'en a plus, on chope un placé mais mal placé
      elem = this.placed.find(p => !p.correct)
    }

    if(!elem) {
      // si tout est placé ET bien placé, on en confirme un au pif
      elem = pickRandom(this.placed)
    }

    this.place(elem.ID, elem.target)
    elem.locked = true



    this.jokerUsed = true


  }

  get jokerAvailable() {
    if(this.jokerUsed) return false
    return true
  }

  handleJauges(jauges) {
    // on regarde les propales placées

    this.propositions
    .forEach(p => {
      // si elles ont une info de jauge

      if(p.jauge) {
        try {
          let jauge_obj = JSON.parse(p.jauge)

          Object.keys(jauge_obj).forEach(jauge_id => {

            let values = jauge_obj[jauge_id]
            let value = p.correct ? values[0] : values[1]
            value = value || 0

            let success = jauges.increment(jauge_id, value)
            if(!success) Notifications.error("Erreur colonne 'jauge' pour le DRAGDROP " + this.ID + " : cette id de jauge n'existe pas dans 'jauge.data' : " + jauge_id)

          })

        }
        catch(err) {
          Notifications.error("Erreur colonne 'jauge' pour le DRAGDROP " + this.ID)
          console.log("err", err)
        }
      }

    })

  }


  reset() {
    this.answer = null
    this.propositions.forEach(p =>{
      p.placed = null
      p.placedTS = null
    })
  }


}

export class DragDropFile {
  all = []

  constructor(json, file) {
    let current = null
    json.forEach(row => {
      try {
        if(row.Type === "title") {
          current = new DragDrop(row)
          this.all.push(current)
        }

        if(row.Type === "proposition") {
          current.addProposition(row)
        }

        if(row.Type === "target") {
          current.addTarget(row)
        }

        if(row.Type === "debrief") {
          current.addDebrief(row, null)
        }
        if(row.Type === "debrief_correct") {
          current.addDebrief(row, true)
        }
        if(row.Type === "debrief_incorrect") {
          current.addDebrief(row, false)
        }
      }
      catch(err) {
        throw "ERREUR CREATION DRAG DROP : " + row.ID + " dans " + file
      }
    })
  }

  observeAll() {
    this.all.forEach(dd => dd.observe())
  }
  get(id) {
    return this.all.find(gd => gd.ID === id)
  }
}
