import React, {useState, useEffect} from 'react'

import classes from './Rays.module.css'

const Rays = () => {

  return (
    <div className={classes.Rays}>
    </div>
  );
};

export default Rays;
