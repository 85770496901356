import React, {useState, useEffect, useRef} from 'react'

import classes from './CanvasCharacter.module.css'
import clsx from 'clsx';
import Button from '@cmp/shared/Button/Button';
import Texts from 'react-sdk/Texts';
import { FaForward, FaPause, FaPlay } from 'react-icons/fa6';
import { pickRandom } from 'react-sdk/utils';

// si char_id et pas sequence_element : on affiche un perso en neutre (éventuellement loop de qqs attitudes)
// si char_id + sequence_element :
//   * si c'est le perso principal, on l'affiche, on le fait parler, on affiche le dial
//   * si c'est un perso secondaire (dans sequence_element.otherCharacters), on l'affiche en neutre
const VISIBLE = "VISIBLE"
const INACTIVE = "INACTIVE"
const HIDDEN = "HIDDEN"

const CanvasCharacter = ({char_id, sequence_element, onEnd, className}) => {

  // console.log("char_id, sequence_element", char_id, sequence_element)
  const charRef = useRef(null)
  const canvasRef = useRef(null)
  const [subtitle, setSubtitle] = useState(null);
  const [visibility, setVisibility] = useState(VISIBLE);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    charRef.current = window.SpeechAPI.createCanvasCharacter(char_id, canvasRef.current)

    return () => {
      charRef.current.stop()
      charRef.current.delete()
      charRef.current = null
    }
  }, []);


  /* à chaque changement de sequence_element */
  useEffect(() => {
    if(!charRef.current) return

    if(char_id && !sequence_element) {
      charRef.current.enableBackToNeutral(false)
      charRef.current.playContent("", "", "neutre", "",() => {})
    }

    if(char_id && sequence_element) {
      const {Text, TextTimings, AttitudeTimings, Audio, Character, otherCharacters} = sequence_element.raw

      setVisibility(VISIBLE)



      if(Character === char_id) {
        charRef.current.unfreeze()
        if(sequence_element.Type === "choice_title"){
          charRef.current.setTextCallback(subtitle => setSubtitle(null))
        }
        else {
          charRef.current.setTextCallback(subtitle => setSubtitle(subtitle))
        }

        charRef.current.playContent(Text, TextTimings, AttitudeTimings, Audio || "", () => {
          if(Audio) {
            setSubtitle(null);

            if(onEnd) onEnd();
          }
        })
      }
      else if(otherCharacters && otherCharacters.includes(char_id)) {
        charRef.current.unfreeze()
        setVisibility(INACTIVE)
        charRef.current.playContent("", "", "", "",() => {})
      }
      else {
        // freeze/unfreeze
        // charRef.current.freeze()
        setVisibility(HIDDEN)
      }
    }

  }, [char_id, sequence_element]);

  function handleSkip() {
    const {Audio} = sequence_element.raw

    if(!Audio) {
      onEnd && onEnd();
    }
    else {
      charRef.current.skipSegment()
    }
  }

  function togglePause(e){
    e.stopPropagation()
    if(paused) {
      charRef.current.resume()
    }
    else {
      charRef.current.pause()
    }

    setPaused(p => !p)

  }


  return (
    <div className={clsx(classes.CanvasCharacter, classes[char_id], classes[visibility], className)}>

      <div className={classes.canvas_ctnr_ctnr}>
        <div className={classes.canvas_ctnr}>
          <canvas ref={canvasRef} className={classes.canvas}></canvas>
        </div>
      </div>

      {subtitle &&
      <div className={classes.subtitle} onClick={handleSkip}>
        <div className={classes.char_name}>{Texts.get(sequence_element.raw.Character)}</div>
        <div className={classes.subtitle_text} dangerouslySetInnerHTML={{__html: subtitle}} />

        <FaForward className={classes.forward_icon} />

        {sequence_element.raw.Audio &&
        <Button
        className={classes.pause_btn}
        onClick={togglePause}
        icon={paused ? <FaPlay /> : <FaPause />}
        />}
      </div>}
    </div>
  );
};

export default CanvasCharacter;
