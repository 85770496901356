import React, {useState, useEffect} from 'react'

import classes from './ActivityFeedback.module.css'
import clsx from 'clsx'
import Texts from 'react-sdk/Texts';

const ActivityFeedback = ({children, correct, points}) => {

  return (
    <div className={clsx(classes.ActivityFeedback, correct ? classes.correct : classes.wrong)}>
      {!children &&
      <span>
      {correct ?
          Texts.get("feedback-correct") :
          Texts.get("feedback-wrong")}
      </span>}

      <span>{children}</span>

      {points > 0 && <div className={classes.points}>+{points}</div>}
    </div>
  );
};

export default ActivityFeedback;
