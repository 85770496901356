import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { QCM } from "react-sdk/activities/QCM";
import classes from './Qcm.module.css'

/**
 *
 * @param {object} props
 * @param {QCM} props.qcm
 * @returns
 */

const QrlInput = ({qcm, qrlAnswer, setQrlAnswer}) => {


  let isCorrect = qcm.answer?.is_correct === true
  let isWrong = qcm.answer?.is_correct === false


  return (
    <div className={clsx(classes.QrlInput_ctnr, isCorrect && classes.correct, isWrong && classes.wrong)}>
      <input
      autoFocus
      className={classes.QrlInput}
      type="text"
      placeholder="Entrez votre réponse ici"
      disabled={qcm.answer}
      value={qcm.answer ? qcm.choices[0].Text : qrlAnswer}
      onChange={e => setQrlAnswer(e.target.value)} />
    </div>
  )
}

export default observer(QrlInput)
