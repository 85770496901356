import React, { useEffect, useRef, useState } from 'react'


import classes from './Tabs.module.css'
import clsx from 'clsx'
import SoundPlayer from 'react-sdk/SoundPlayer'
import Analytics from 'react-sdk/Vertx/Analytics'

const Tab = ({index, selected, children, onClick}) => {
  return (
    <div onClick={onClick} className={clsx(classes.Tab, selected && classes.selected, "Tab-"+index)}>
      {children}
    </div>


  )
}


const Tabs = ({tabs = [], panels = [], popupId}) => {

  const [tabindex, setTabindex] = useState(0);
  const prevTabindex = useRef(null)

  function handleClick(i) {

    setTabindex(i)
    SoundPlayer.playSound("UI_tab")
  }

  useEffect(() => {

    if(popupId) {
      let prevStr = prevTabindex.current !== null ? `popup.${popupId}.tab.${prevTabindex.current}` : null
      Analytics.changeScreenEvents(prevStr, `popup.${popupId}.tab.${tabindex}`)
    }
    prevTabindex.current = tabindex

  }, [tabindex]);

  return (

    <div className={classes.Tabs}>
      <div className={classes.Tabs_list}>
        {tabs.map((t, i) => {
          return <Tab key={i} index={i} selected={tabindex===i} onClick={() => handleClick(i)}>{t}</Tab>
        })}
      </div>

      <div className={classes.Tabs_panels} key={tabindex}>
        {panels && panels[tabindex]}
      </div>

    </div>



  );
};

export default Tabs;
