class ConfigItem {
  constructor(data) {

    this.id = data.id

    this.value = this.parseVal(data.value)
  }

  parseVal(value) {
    // ici on essaie de parser les arrays
    if(value === null) return null

    if(value.includes(";")) {
      return value.split(";")
      .map(val => val.trim())
      .filter(val => val.length > 0)
      .map(val => this.parseVal(val))
    }

    // ici on essaie de voir si c'est un nombre ou pas
    if(!isNaN(value)) return parseFloat(value)

    return value
  }

}


export class Config {

  all = []

  init(json) {
    this.all = json
    .filter(elem => elem.id)
    .map(elem => new ConfigItem(elem))
  }

  get ids() { return this.all.map(c => c.id) }
  get(id, def = null) {
    let item = this.all.find(c => c.id === id)

    if(item) return item.value
    return def
  }



}


export default new Config()