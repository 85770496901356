
import AppState from "@/js/AppState";
import clsx from "clsx";
import Texts from "react-sdk/Texts";
import popup_classes from '../Popups.module.css';
import CollectesGrid from "./CollectesGrid/CollectesGrid";
import classes from './PopupTeam.module.css';


export const Value = ({val, name}) => {
  return (
    <div className={popup_classes.Value}>
      <strong>{val}</strong>
      {name}
    </div>
  )
}


const PageStats = () => {

  function handleForceRefresh() {
    AppState.currentMeeting.customActivityCommand("ForceCollect", AppState.collecteActivityId, false)
  }


  return (
    <div className={clsx(popup_classes.page, classes.PageStats)}>
      <h4>{Texts.get("popup-team-tab1-title")}</h4>

      <div className="block collectes-block">
        <h5 className="block-title">Demandes de collecte en cours</h5>
        <CollectesGrid />
      </div>

    </div>
  )
}

export default PageStats