import React, {useState, useEffect, useRef} from 'react'

import classes from './TimeBar.module.css'
import clsx from 'clsx';

const TimeBar = ({duration, pause, onFinished, remainingTimeRef}) => {
  const [remainingTime, setRemainingTime] = useState(duration);

  const [percent, setPercent] = useState(100);
  const intervalRef = useRef(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setRemainingTime(t => t-1)
    }, 1000)

    return () => {
      window.clearInterval(intervalRef.current)
    }


  }, []);

  useEffect(() => {

    if(remainingTimeRef) remainingTimeRef.current = remainingTime

    setPercent((remainingTime - 1) / duration * 100)
    if(remainingTime === 0) {
      window.clearInterval(intervalRef.current)
      onFinished?.()
    }
  }, [remainingTime]);


  useEffect(() => {
    if(pause) {
      window.clearInterval(intervalRef.current)
    }
  }, [pause]);



  return (
    <div className={classes.TimeBar_ctnr}  >
      <div className={clsx(classes.TimeBar, pause && classes.paused)} style={{width: percent + "%"}}>
        <span>{remainingTime}</span>
      </div>
    </div>
  );
};

export default TimeBar;
