import { useEffect, useState } from 'react';

import AppState, { POPUPS } from '@/js/AppState';
import clsx from 'clsx';
import Texts from 'react-sdk/Texts';
import Loader from "react-sdk/components/Loader/Loader";
import classes from './TeamGrid.module.css';
import MeetingsApi from 'react-sdk/Vertx/MeetingsApi/MeetingsApi';
import ProfilePic from '../ProfilePic/ProfilePic';
import { FaCrown, FaPlus } from 'react-icons/fa6';
import { observer  } from 'mobx-react-lite';
import Button from '../Button/Button';
import PopupsManager from 'react-sdk/PopupsManager';
import { toJS } from 'mobx';

const User = observer(({user, current, showReferentActions}) => {


  function  deleteUser(user) {
    AppState.team.removeUser(user)
    .finally(() => {
      PopupsManager.close(POPUPS.NOTIF)
    })
  }

  function changeReferent(user) {
    AppState.team.changeReferent(user)
    .finally(() => {
      PopupsManager.close(POPUPS.NOTIF)
    })
  }



  function handleDeleteUserClick() {

    /** @type {import('@/components/popups/PopupNotif/PopupNotif').NotifPopupOptions} */
    let data = {
      surtitle: Texts.get("popup-delete-user-surtitle"),
      title: user.Pseudo,
      text: Texts.get("popup-delete-user-text"),
      // image_url: "", // TODO utiliser l'image du joueur
      onClose: () => {PopupsManager.close(POPUPS.NOTIF)},
      Buttons: () => <>
        {/* <Button onClick={() => {PopupsManager.close(POPUPS.NOTIF)}} variant='secondary' >NON</Button> */}
        <Button onClick={() => { deleteUser(user) }}>{Texts.get("popup-delete-user-button")}</Button>
      </>
    }

    PopupsManager.open(POPUPS.NOTIF, data)
  }
  function handleChangeReferentClick() {

    /** @type {import('@/components/popups/PopupNotif/PopupNotif').NotifPopupOptions} */
    let data = {
      surtitle: Texts.get("popup-change-referent-surtitle"),
      title: user.Pseudo,
      text: Texts.get("popup-change-referent-text", {mustacheVars: {pseudo: user.Pseudo}}),
      // image_url: "", // TODO utiliser l'image du joueur
      onClose: () => {PopupsManager.close(POPUPS.NOTIF)},
      Buttons: () => <>
        {/* <Button onClick={() => {PopupsManager.close(POPUPS.NOTIF)}} variant='secondary' >NON</Button> */}
        <Button onClick={() => { changeReferent(user) }}>{Texts.get("popup-change-referent-button")}</Button>
      </>
    }

    PopupsManager.open(POPUPS.NOTIF, data)
  }

  const showCrown = AppState.team.referentsIds.includes(user.UserID)

  return (
    <div className={clsx(classes.user, current && classes.current, showReferentActions && classes.showReferentActions)} >
      <div className={classes.pseudo}>
        {user.Pseudo}
        {(showReferentActions && !current) &&
        <div className={classes.referentActions}>
          <span className={classes.change_referent} onClick={handleChangeReferentClick}><FaCrown /></span>
          <span className={classes.delete_user} onClick={handleDeleteUserClick}>&times;</span>
        </div>
        }

      </div>

      <div className={classes.pp}>
        <ProfilePic pseudo={user.Pseudo} />
        {showCrown && <div className={classes.referent}><FaCrown /></div>}

      </div>
    </div>
  )
})

const TeamGrid = ({showReferentActions = false}) => {
  const [loading, setLoading] = useState(true);

  const myid = MeetingsApi.user_id

  useEffect(() => {
    if(AppState.team.users.length === 0) {
      AppState.team.fetchUsersAndReferents()
      .then(() => {
        setLoading(false)
      })
    }
    else {
      setLoading(false)
    }
  }, []);


  // let team = AppState.team
  return (
    <div className='block'>

      <h5 className='block-title'>
        {Texts.get("team-screen-teamblock-title", {mustacheVars: {team_size: loading ? "--" : AppState.team.users.length }})}
        {showReferentActions && <Button className={classes.add_user_btn} icon={<FaPlus />} onClick={() => PopupsManager.open(POPUPS.GROUPADDUSER)} />}
      </h5>

      {loading &&
      <div className={classes.loading}>
        <Loader />
      </div>}

      {!loading &&
      <div className={classes.team_members}>
        {AppState.team.users.slice().sort((a, b) => {
          if(a.UserID === myid) return -1
          if(b.UserID === myid) return 1
          return 0
        })
        .map((u, i) => <User user={u} key={u.UserID} current={u.UserID === myid} showReferentActions={showReferentActions}/>)}
      </div>}



    </div>
  );
};

export default observer(TeamGrid)
