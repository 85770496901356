import ProfilePic from '@/components/shared/ProfilePic/ProfilePic';
import AppState, { POPUPS } from '@/js/AppState';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FaUser } from "react-icons/fa6";
import classes from './ChapterBar.module.css';
import finish from "./finish.png";

import star from '../../../images/star.png';
import { useEffect } from 'react';
import { Tooltip } from 'react-tooltip'
import PopupsManager from 'react-sdk/PopupsManager';
import Shepherd from 'shepherd.js';
import { toJS } from 'mobx';



export const FinishedPlayersText = ({num}) => {
  return (
    <div className={classes.FinishedPlayersText}>
      <div>{num} <FaUser /></div>
    </div>
  )
}


const PlayerMarkerGroup = ({progress_value, playersInfo, index}) => {
  const keys = Object.keys(playersInfo)

  if(keys.length === 1) {
    const pseudo = playersInfo[keys[0]].pseudo
    return <PlayerMarker isMe={false} progress={progress_value} index={index} pseudo={pseudo} tooltip_content={pseudo} />
  }

  const playerspseudolist = (
  <div>
    {keys.map(k => <div key={k}>{playersInfo[k].pseudo}</div> )}
  </div>)

  return (


  <PlayerMarker
  isMe={false}
  progress={progress_value}
  index={index}
  tooltip_content={playerspseudolist} >
    <FaUser /> {keys.length}
  </PlayerMarker>)

}



const PlayerMarker = observer (({isMe, index, progress, children, pseudo, tooltip_content }) => {

  function handleClick() {
    if(!isMe) return
    if(Shepherd.activeTour) return
    if (AppState.lockHud) return
    PopupsManager.open(POPUPS.USER)
  }

  // NOTE : petit fix car le pseudo venant du message de progress, il est mis à jour en cas de changement au prochain progress seulement, avec ça c'est immédiat
  if(isMe) pseudo = AppState.vertxClient.UserData.getPseudo()

  return (
  <div
  className={clsx(classes.PlayerMarker, isMe && classes.PlayerMarker_me, isMe && "ftue-player-popup")}
  style={{left: progress * 100 + "%"}}

  data-tooltip-id={"marker-tooltip-" + index}
  // data-tooltip-html={tooltip_content}

  onClick={handleClick}

  >

    <div className={classes.PlayerMarker_pp}>
      {!children && <ProfilePic pseudo={pseudo} />}
      {children && <div className={classes.PlayerMarker_content}>{children}</div>}
    </div>

    {isMe &&
    <div className={classes.PlayerMarker_text} >
      <div className={classes.PlayerMarker_pseudo}>{pseudo}</div>
      <div className={classes.PlayerMarker_stars}>
        <img src={star} />
        {AppState.userStars}
      </div>
    </div>}

      {index !== undefined &&
    <Tooltip id={"marker-tooltip-" + index} place='top-end'>
      {tooltip_content}
    </Tooltip>}
  </div>
  )
})

const ChapterBar = () => {
  let chapter = AppState.soloObjectives.activeRootObjectives[0]

  if(!chapter) chapter = AppState.soloObjectives.completedRootObjectives[AppState.soloObjectives.completedRootObjectives.length - 1]


  let progress = 0
  if(chapter) {
    progress = chapter.progress
  }

  useEffect(() => {
    // NOTE je place ca la pour le moment, faut que ça régisse au changement de progress
    if(!chapter) return

    console.log("UPDATE DU PROGRESS");

    AppState.team.sendProgress(chapter.id, chapter.progress)
    .then(() =>  {
      console.log("PROGRESS SENT !!!");

    })



  }, [chapter?.progress]);

  const myId = AppState.vertxClient.UserData.getUserID()
  const percent = progress * 100 + "%"

  const progressMarkers = AppState.team.progress[chapter?.id]

  const myInfo = progressMarkers?.[myId]


  const groupedByProgress = {};
  if(progressMarkers) {

    for (const key in progressMarkers) {
      if(key === myId) continue
      const progressValue = progressMarkers[key].progress;


      if (!groupedByProgress[progressValue]) {
          groupedByProgress[progressValue] = {};
      }

      // Ajouter l'entrée à la liste correspondante
      groupedByProgress[progressValue][key]= progressMarkers[key]
    }

  }

  return (
    <div
    className={clsx(
      classes.ChapterBar,
      "ftue-chapter-bar",
      !AppState.UI_visibilities.chapter_bar && classes.invisible,
      AppState.lockHud && classes.locked
    )} >


      <div className={classes.bar_ctnr} >
        <div className={classes.bar}>
          <div className={classes.bar_slider} style={{width: percent}}></div>

          {myInfo && <PlayerMarker isMe={true} {...myInfo} />}

          {Object.keys(groupedByProgress)
          .map((progress_value, index) =>
            <PlayerMarkerGroup
              key={index}
              index={index}
              progress_value={progress_value}
              playersInfo={groupedByProgress[progress_value]} />)
          }
        </div>
        <img className={classes.finish_flag} src={finish} />
      </div>

      <div className={classes.chapter_name}>
        {AppState.soloObjectives.activeRootObjectives.map(o => o.name)}

      </div>
    </div>
  );
};

export default observer(ChapterBar);
