import { makeAutoObservable } from "mobx";

class MinigameFtue {
  minigame_type;
  surtitle;
  text;
  image;
  title;

  constructor(json) {
    Object.assign(this, json);

    makeAutoObservable(this);
  }
  get content_image_url() {
    if (this.image)
      return `${window.CONFIG.root}images/minigames_ftue/${this.image}`;

    return null;
  }
}

class MinigameFtues {
  /** @type {Array<MinigameFtue>} */
  all = [];

  viewed_types = [];

  init(json) {
    this.all = json
      .filter((elem) => elem.id)
      .map((elem) => new MinigameFtue(elem));
  }

  /** @returns {Array<string>} */
  get ids() {
    return this.all.map((c) => c.id);
  }

  /** @returns {MinigameFtue} */
  get(id) {
    return this.all.find((c) => c.id === id);
  }

  getForMG(mg_type) {
    if (!this.hasBeenViewed(mg_type)) {
      this.viewed_types.push(mg_type);
    }
    return this.all.filter((mgf) => mgf.minigame_type === mg_type);
  }

  hasBeenViewed(mg_type) {
    return this.viewed_types.includes(mg_type);
  }

  stateToJson() {
    return this.viewed_types;
  }
  stateFromJson(data) {
    if (data) {
      this.viewed_types = data;
    }
  }
}

export default new MinigameFtues();
