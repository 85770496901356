import React, {useState, useEffect} from 'react'

import classes from './Objective.module.css'
import clsx from 'clsx';
import { PiCoinsFill } from 'react-icons/pi';
import { FaCheck } from 'react-icons/fa6';

import ObjectiveFile from '@/js/Objectives/ObjectiveFile';

const Objective = ({objective}) => {
  const completed = ObjectiveFile.isObjCompleted(objective)

  const reward = objective.sourceObjective.line.get("reward")
  return (
    <div className={clsx(
      classes.Objective,
      completed && classes.completed,
      objective.date && classes.event,
      "objective"
      )}>

      <div className={classes.Objective_name}>


        {objective.progress===0 &&
        <div className={classes.Objective_notif}>Nouveau</div>}

        {objective.name}
      </div>

      {!completed &&
      <div className={classes.Objective_reward}>
        {reward &&
        <><PiCoinsFill /> + {reward}</>}
      </div>}
      {completed && <div className={classes.check}><FaCheck /></div>}
    </div>
  )
}
export default Objective;
