import { useDroppable } from "@dnd-kit/core";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import Button from "@cmp/shared/Button/Button";
import { FaInfo } from "react-icons/fa6";
import Draggable from "../Draggable/Draggable";
import classes from './Target.module.css';
import { toJS } from "mobx";

const Target = ({target, items, disabled, setInfo_popup, showCorrection}) => {
  let isDisabled = false

  let target_items_style = {}
  const target_header_style = {}

  if(target.config) {
    try{
      let config = JSON.parse(target.config)

      if(config.max !== undefined) {
        if(config.max === items.length) isDisabled = true
      }

      if(config.header_height !== undefined) {
        target_header_style.height = config.header_height
      }


    }
    catch(err) {
      console.log("err", err)

    }
  }


  const {isOver, setNodeRef} = useDroppable({id: target.ID, disabled: isDisabled});

  if(target.media) {
    target_header_style.backgroundImage = `url(${window.CONFIG.root}/images/medias_pedago/${target.media})`
  }

  let classesArray = [classes.Target, isOver && classes.draggedOver]


  // HACK permet de figer la hauteur des Target une fois que le jeu est chargé, sinon elles s'agrandissent en hauteur quand on y met une image et ca complique tout avec le module de DD
  const [height, setHeight] = useState(null);
  const itemsRef = useRef(null)
  useEffect(() => {
    setHeight(itemsRef.current.offsetHeight)
  }, []);

  if(height) target_items_style.height = height

  return (
    <div
    ref={setNodeRef}
    className={clsx(classesArray)}
    >

      <div  className={clsx(classes.target_name, target.media && classes.with_media)} style={target_header_style}  >
        {/* {target.media &&
        <img src={`${window.CONFIG.root}/popup_pedago/medias/${target.media}`} className={classes.target_bgimage} />} */}
        {target.Text && <span>{target.Text}</span>}

        {target.infos &&
        <Button onClick={() => setInfo_popup(target.infos)} icon={<FaInfo />} className={classes.target_info_button} />}
      </div>


      <div ref={itemsRef} className={classes.target_items} style={target_items_style}>
        {items.map(i =>
        <Draggable key={i.ID} item={i} inTarget={target.ID} showCorrection={showCorrection} disabled={showCorrection || disabled}/>)}
      </div>

    </div>
  )
}

export default Target