import React, { useState, useEffect, useRef } from 'react'

import classes from './ColourPuzzle.module.css'
import clsx from 'clsx';
import Loader from 'react-sdk/components/Loader/Loader';
import AppState from '@/js/AppState';
import Button from '../shared/Button/Button';
import { FaQuestion } from 'react-icons/fa6';
import { MdOutlineReplay } from 'react-icons/md';

const ColourPuzzle = ({ level, onFinished }) => {
  const elementRef = useRef(null)
  const [loaded, setLoaded] = useState(false);

  // ColourPuzzle USE EFFECT INITIAL
  useEffect(() => {

    AppState.reaxe_api.registerModule("colourPuzzle", (event, params) => {
      if (event === "colourPuzzlelocked") {
        toHaxe("colourPuzzle", "start")
      } else if (event === "colourPuzzlewin" || event === "colourPuzzlefail") {
        console.log("colourPuzzlewin results", event, params)

        let { score } = params // pas sur que ca serve
        if (onFinished) onFinished(score)

      }
    })

    AppState.reaxe_api.toHaxe("colourPuzzle", "launch", { configId: level }, () => setLoaded(true))
    AppState.mapVisible = false;
    if (AppState.haxeCanvas.parentElement)
      AppState.haxeCanvas.parentElement.removeChild(AppState.haxeCanvas)
    elementRef.current.append(AppState.haxeCanvas)
    // appelé à la fermeture du composant
    return () => {
      AppState.reaxe_api.toHaxe("colourPuzzle", "delete", null,
        () => {
          AppState.mapVisible = true;
          setLoaded(false)
          AppState.reaxe_api.registerModule("colourPuzzle", null)
        }
      )
    }

  }, []);

  function resetMgLevel() {
    AppState.reaxe_api.toHaxe("colourPuzzle", "reset", null,
      () => {
      }
    )
  }

  return (
    <>
      <Button icon={<MdOutlineReplay />} className={classes.reset_btn} onClick={resetMgLevel} />

      <div className={clsx(classes.ColourPuzzle, loaded && classes.loaded)} ref={elementRef} >
        {!loaded &&


          <div className={classes.loader}>
            <Loader />
          </div>
        }
      </div>
    </>
  );
};

export default ColourPuzzle;
