import { useEffect, useRef, useState } from 'react';

import { FaCheck, FaPencil } from 'react-icons/fa6';
import Button from '../Button/Button';
import classes from './EditableInput.module.css';

import Loader from "react-sdk/components/Loader/Loader"

const EditableInput = ({onSubmit, value, maxLength = null}) => {
  const [editing, setEditing] = useState(0);

  const inputRef = useRef(null)

  function handleSubmit(e) {
    e.preventDefault()

    const data = new FormData(e.target)
    const newValue = data.get("value")

    setEditing(2)

    onSubmit(newValue, () => {
      setEditing(0)
    })
  }

  useEffect(() => {
    if(editing) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [editing]);

  return (
    <div className={classes.EditableInput}>
      <form onSubmit={handleSubmit} >
        <input ref={inputRef} type="text" name="value" defaultValue={value} disabled={!editing} maxLength={maxLength}/>
        {editing === 1 &&
        <Button className={classes.edit_pseudo_btn} icon={<FaCheck />} />}
        {editing === 2 &&
        <Button disabled className={classes.edit_pseudo_btn} icon={<Loader />} />}
      </form>
      {!editing &&
      <Button onClick={() => setEditing(1)} className={classes.edit_pseudo_btn} icon={<FaPencil />} />}

    </div>
  );
};

export default EditableInput;
