import React from 'react'

import classes from './Button.module.css'
// import SoundPlayer from 'react-sdk/SoundPlayer';
import clsx from 'clsx';
import Analytics from 'react-sdk/Vertx/Analytics';

/**
 *
 * @param {Object} props
 * @param {"small"|"secondary"|"contour"} props.variant
 * @returns
 */

const Button = (props) => {

  let {onClick=null, icon=null, icon_left=null, icon_right=null, disabled=false, className="", variant="", name, children} = props

  if(icon && !icon_left) icon_left = icon

  const hasIcon = icon_left||icon_right


  function handleClick() {
    if(onClick) {
      if(name) {
        Analytics.actionEvent(name, "click")
      }
      onClick()
    }
  }


  return (
    <button

    {...props}

    className={clsx(
      classes.Button,
      variant.split(" ").map(c => classes[c]),
      className,
      (hasIcon && !children) && classes.icon_button,
      !hasIcon && classes.text_button)}
    onClick={handleClick}
    disabled={disabled}
    // onMouseEnter={e => SoundPlayer.playSound("rollover")}
    >
      <div className={classes.content}>
        {icon_left && <span className={clsx(classes.icon, classes.icon_left)}>{icon_left}</span>}
        {children && <span className={classes.text}>{children}</span>}
        {icon_right && <span className={clsx(classes.icon, classes.icon_right)}>{icon_right}</span>}
      </div>

    </button>
  );
};

export default Button;
