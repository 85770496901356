import clsx from "clsx";
import Texts from "react-sdk/Texts";
import popup_classes from '../Popups.module.css';
import classes from './PopupTeam.module.css';

import { states } from "@/js/Objectives/ObjectiveFile";

import AppState from "@/js/AppState";
import { useEffect } from "react";
import FTUE, { FTUE_state } from "@/js/FTUE";

import Objective from "@/js/Objectives/Objective";

/**
 *
 * @param {Object} props
 * @param {Objective} props.chapter
 *
 * @returns
 */
const TeamObjective = ({chapter, className}) => {
  const chapterCompleted = (chapter.state === states.COMPLETED || chapter.state === states.FINISHED);
  let currentObj = null

  if (!chapterCompleted) {
    const objs = chapter.children.filter(obj => obj.state===states.ACTIVE)
    currentObj = objs[0];
  } else {
    currentObj = chapter.children[chapter.children.length - 1]
  }


  const image = chapter.sourceObjective.line.get("image")
  const name = chapter.sourceObjective.line.get("name")
  const description = currentObj?.sourceObjective.line.get("descriptionText")
  const progressRatio = currentObj?.sourceObjective.getProgress()
  const lvl = chapter.children.indexOf(currentObj) + 1;
  return (
    <div className={clsx(classes.TeamObjective, className)}>
      <div className={classes.image}>
        <img src={image} alt="" />
      </div>
      <div className={classes.content}>
        <div className={classes.title}>{name}</div>
        <div className={classes.description}>{description}</div>

        {!chapterCompleted &&
          <div className={classes.progress}>
            {/* <div className={classes.progress_values}>{progressRatio}</div> */}
            <div className={classes.progressbar}>
              <div className={classes.progressbar_slider}  style={{width: progressRatio * 100 + "%"}} />
            </div>

          </div>
        }
      </div>
      <div className={classes.level}>Lvl. {lvl}</div>
    </div>
  )
}

const PageObjectives = () => {


  useEffect(() => {
    if(FTUE.popup_team_ftue.isActive() && !FTUE_state.viewed.includes("popup_team_page02")) {
      FTUE.popup_team_ftue.show("popup_team_page02")
    }

  }, []);
  console.log("AppState.teamObjectives.rootObjectives", AppState.teamObjectives.rootObjectives)


  return (
    <div className={clsx(popup_classes.page, classes.PageObjectives)}>
      <h4>{Texts.get("popup-team-tab2-title")}</h4>

      <div className={classes.Objectives}>

        {AppState.teamObjectives.activeRootObjectives
        .map(chapter => <TeamObjective chapter={chapter} key={chapter.id} />)}

        {AppState.teamObjectives.completedRootObjectives
        .map(chapter => <TeamObjective chapter={chapter} className={classes.completed} key={chapter.id} />)}

      </div>


    </div>
  )
}

export default PageObjectives