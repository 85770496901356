import React, { useEffect } from 'react'


import classes from './PopupObjective.module.css'
import PopupsManager from 'react-sdk/PopupsManager';
import { POPUPS } from '@/js/AppState';
import Texts from 'react-sdk/Texts';
import clsx from 'clsx';
import SoundPlayer from 'react-sdk/SoundPlayer';
import Rays from '@/components/shared/Rays/Rays';
import Objective from '@/js/Objectives/Objective';
import Analytics from 'react-sdk/Vertx/Analytics';


const PopupObjective = () => {
  /** @type {Objective} */
  let obj = PopupsManager.getData(POPUPS.NEW_OBJECTIVE)

  let isChapter = !obj.parent


  useEffect(() => {
    SoundPlayer.playSound("quest_start")

    const evt = "OBJECTIVE : " + obj.id

    Analytics.contentEventShow(evt)

    return () => {
      Analytics.contentEventHide(evt)
    }



  }, []);

  function handleClose() {
    PopupsManager.close(POPUPS.NEW_OBJECTIVE)
  }

  return (

    <div className={clsx(classes.PopupObjective, isChapter && classes.chapter)} onClick={handleClose}>

      {isChapter &&
      <div className={classes.rays}>
        <Rays />
      </div>}

      <div className={classes.content}>
        {isChapter && <div className={classes.title}>{Texts.get("popup-chapter-title")}</div>}
        {!isChapter && <div className={classes.title}>{Texts.get("popup-objectifs-title")}</div>}
        <h1 className={classes.name}>{obj.name}</h1>
      </div>


    </div>



  );
};

export default PopupObjective;
