import './App.css';
import { observer } from "mobx-react-lite";
import React from "react";
import ScaleManager from 'react-sdk/ScaleManager';
import AppState, { POPUPS, SCREENS } from "@/js/AppState";

import ScreenLoading from "@scr/ScreenLoading/ScreenLoading";
import ScreenHome from "@scr/ScreenHome/ScreenHome";
// import ScreenFinal from "@scr/ScreenFinal/ScreenFinal";
import ScreenLogin from "@scr/ScreenLogin/ScreenLogin";
import Notifs from "@cmp/shared/Notifs/Notifs";
import ScreenWelcome from '@scr/ScreenWelcome/ScreenWelcome';
import ScreenUser from '@scr/ScreenUser/ScreenUser';
import ScreenTeam from '@scr/ScreenTeam/ScreenTeam';
import ScreenVideo from '@scr/ScreenVideo/ScreenVideo';
import ScreenGame from "@scr/ScreenGame/ScreenGame";
import ScreenDial from '@scr/ScreenDial/ScreenDial';
import PopupsManager from 'react-sdk/PopupsManager';
import PopupNotif from '@pops/PopupNotif/PopupNotif';
import PopupGroupAddUser from '../popups/PopupGroupAddUser/PopupGroupAddUser';
import ScreenTeamPseudo from '../screens/ScreenTeam/ScreenTeamPseudo';
import ScreenTestMg from '../screens/ScreenTestMg/ScreenTestMg';

const App = () => {
  return (
    <>
      <div className="App"  style={ScaleManager.style}>
        {AppState.screen === SCREENS.LOADING && <ScreenLoading />}

        {AppState.screen === SCREENS.HOME && <ScreenHome />}
        {AppState.screen === SCREENS.LOGIN && <ScreenLogin />}
        {/* on est loggés a partir de la */}
        {AppState.screen === SCREENS.WELCOME && <ScreenWelcome />}
        {AppState.screen === SCREENS.USER && <ScreenUser />}
        {AppState.screen === SCREENS.TEAM_PSEUDO && <ScreenTeamPseudo />}
        {AppState.screen === SCREENS.TEAM && <ScreenTeam />}

        {AppState.screen === SCREENS.VIDEO && <ScreenVideo />}
        {AppState.screen === SCREENS.FIRST_DIAL && <ScreenDial />}
        {AppState.screen === SCREENS.GAME && <ScreenGame />}

        {AppState.screen === SCREENS.TEST_MG && <ScreenTestMg />}


        {PopupsManager.isOpened(POPUPS.GROUPADDUSER) &&
        <PopupGroupAddUser />}


        {PopupsManager.isOpened(POPUPS.NOTIF) &&
        <PopupNotif />}

        <Notifs />
      </div>
    </>
  )
}

export default observer(App);
