import React, {useState, useEffect} from 'react'
import Loader from "react-sdk/components/Loader/Loader";
import Texts from 'react-sdk/Texts';
import AppState from '@/js/AppState';
import { FaMapMarkerAlt } from "react-icons/fa";

import classes from './MagasinInfos.module.css'

const MagasinInfos = () => {

  const magasin = AppState.team


  return (
    <div className="block">
      <h5 className='block-title'>{Texts.get("team-screen-magasinblock-title")}</h5>

      {!magasin &&
      <div className={classes.loading}>
        <Loader />
      </div>}


      {magasin &&
      <div className={classes.infos}>
        <div className={classes.infos_row1}>
          <div className={classes.region}>
            <span>Région</span>
            <div>{magasin.region}</div>
          </div>
          <div className={classes.picture}></div>
          <div className={classes.departement}>
            <span>Département</span>
            <div>{magasin.departement}</div>
          </div>
        </div>
        <div className={classes.name}>{magasin.enseigne}</div>
        <div className={classes.address}><FaMapMarkerAlt /> {magasin.adresse}</div>
      </div>}
    </div>

  );
};

export default MagasinInfos;
