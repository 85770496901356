import FTUE, { FTUE_state } from '@/js/FTUE';
import popup_classes from '../Popups.module.css';
import classes from './PopupTeam.module.css';

import Rays from '@/components/shared/Rays/Rays';
import AppState, { POPUPS } from '@/js/AppState';
import clsx from "clsx";
import { useEffect, useState } from 'react';
import PopupsManager from 'react-sdk/PopupsManager';
import Texts from "react-sdk/Texts";
import Loader from "react-sdk/components/Loader/Loader";

const PageBadges = ({}) => {
  const [loading, setLoading] = useState(true);
  const [unlockedIds, setUnlockedIds] = useState([]);


  useEffect(() => {
    if(FTUE.popup_team_ftue.isActive() && !FTUE_state.viewed.includes("popup_team_page03")) {
      FTUE.popup_team_ftue.show("popup_team_page03")
    }
  }, []);


  useEffect(() => {
    // ici recuperer les CD de badges
    AppState.team.fetchBlasons()
    .then(blasons => {
      if(blasons) {
        const ids = Object.keys(blasons)
        .filter(k => blasons[k].Unlocked)
        setUnlockedIds(ids)
      }

      setLoading(false)
    })

  }, []);

  let DM = succubus.data.DataManager.instance
  let blasonsFile = DM.getFile("data/blasons.data")

  function open(blasonLine) {
    let desc = blasonLine.get("description").evaluate() //.getContent().get()
    console.log("desc", desc)

    /** @type {import('../PopupNotif/PopupNotif').NotifPopupOptions} */
    const data = {
      onClose: () => PopupsManager.close(POPUPS.NOTIF),
      title: blasonLine.get("title"),
      text: desc,
      rays: true,
      image_url: `./${window.CONFIG.root}/images/${blasonLine.get("image")}`
    }

    PopupsManager.open(POPUPS.NOTIF, data)
  }


  return (
    <div className={clsx(popup_classes.page, classes.PageBadges)}>
      <h4>{Texts.get("popup-team-tab3-title")}</h4>

        {loading &&
        <div className={classes.blasons_loading}>
          <Loader />
        </div>}

        {!loading &&
        <div className={classes.blasons_grid}>
          {blasonsFile.lines.map(blasonLine => {


            const unlocked = unlockedIds.includes(blasonLine.id)

            return (
              <div
              key={blasonLine.id}
              onClick={() => open(blasonLine)}
              className={clsx(classes.blason, !unlocked && classes.inactive)}>
                {unlocked && <div className={classes.rays}><Rays  /></div> }

                <img src={`./${window.CONFIG.root}/images/${blasonLine.get("image")}`} alt="" />
              </div>
            )
          })}
        </div>}


    </div>
  )
}

export default PageBadges