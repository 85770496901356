import { makeAutoObservable, makeObservable, observable } from "mobx"
import classifyPoint from "robust-point-in-polygon"
import { makeId } from "../utils"

export class Marker {
  id
  x
  y
  imgScale
  correct = false

  constructor (x, y, imgScale) {
    this.x = x
    this.y = y
    this.imgScale = imgScale
    this.id = makeId()
    // makeAutoObservable(this)
  }

  get coordsArray() {
    return [this.x / this.imgScale, this.y / this.imgScale]
  }
}


export class Feature {
  coords =  []
  correct = false

  constructor(coords) {
    this.coords = coords
  }

  svgPath() {

    let str = ""
    this.coords.forEach(([x, y], index) => {
      if(index === 0 ) {
        str += "M"
      }else {
        str += "L "
      }

      str += `${x} ${y}`
    })

    str += " Z"
    return str
  }

  get centroidCoords() {
    return this.coords.reduce((center, coord, i) => {
      center[0] += coord[0]
      center[1] += coord[1]

      if(i === this.coords.length - 1) {
          center[0] /= this.coords.length
          center[1] /= this.coords.length
      }

      return center
    }, [0, 0])
  }
}

export class PointerValider {
  ID
  Text = null
  media = null

  duration = null

  /** @type {Array<Feature>} */
  features = []

  /** @type {import("./ActivitiesConstants").ActivityAnswer} */
  answer = null

  /** @type {Array<Marker>} */
  markers = []

  constructor(json) {
    Object.assign(this, json)

    this.features = this.parseFeatures(json.features)
  }

  observe() {
    makeObservable(this, {
      markers: observable,
      answer: observable
    })

  }

  parseFeatures(geojson) {
    try {
      geojson = JSON.parse(geojson)

      const polys = geojson.features
      .map(feature => {
        if(feature.geometry.type === "Polygon") {
          return feature.geometry.coordinates[0].map(([x, y]) => ([Math.abs(x), Math.abs(y)]))
        }
        return null
      })
      .filter(coords => !!coords)
      .map(coords => new Feature(coords))

      return polys

    }
    catch(err) {
      console.error("Erreur dans le parsing des features pour " + this.ID);
      console.log(err);
    }
  }

  addMarker(x, y, imgScale) {
    const m = new Marker(x, y, imgScale)
    this.markers.push(m)
  }

  deleteMarker(m_id) {
    this.markers = this.markers.filter(m => m.id !== m_id)
  }


  reset() {
    this.markers = []
    this.answer = null
    this.features.forEach(f => f.correct = false)
  }

  localCorrect(normalizedRemainingTime) {

    this.markers.forEach((marker, index) => {
      for(let feature of this.features) {
        var result = classifyPoint(feature.coords, marker.coordsArray )
        if(result <= 0) {
          marker.correct = true
          feature.correct = true
          // on s'arrête la pour ce point
          continue
        }
      }
    })

    const nbFeaturesCorrect = this.features.filter(f => f.correct).length

    const points = Math.floor(nbFeaturesCorrect * 5)

    this.answer = {
      is_correct:  nbFeaturesCorrect === this.features.length,
      points
    }

  }
}


export class PointerValiderFile {
  /** @type {Array<PointerValider>} */
  all = []

  constructor(json) {
    json.forEach(row => {
      this.all.push(new PointerValider(row))
    })
  }
  get(id) {
    return this.all.find(cv => cv.ID === id)
  }

  observeAll() {
    this.all.forEach(cv => cv.observe())
  }
}