import { makeAutoObservable } from "mobx"
import Analytics from "./Vertx/Analytics"

/**
 * permet de gérer une stack de popups
 * soit utiliser en singleton, soit instancier
 *
 *
 */

export class PopupsManager {
  datas = new Map()
  closeCb = new Map()

  #queue = []
  vertxApi = null

  constructor() {
    this.closeAll = this.closeAll.bind(this)

    makeAutoObservable(this)
  }

  // NOTE: c'est optionnel d'appeler cette méthode
  init(vertxApi) {
    this.vertxApi = vertxApi
  }

  open(popup_id, data = null, onClose = null) {
    if (!this.isOpened(popup_id)) {
      Analytics.changeScreenEvents(null, "popup." + popup_id)
      if (this.vertxApi)
        this.vertxApi.analytics.navigationEnter("popup." + popup_id)
    }

    // pas dans le if car on peut vouloir mettre à jour les datas....
    this.datas.set(popup_id, data)
    if (onClose) {
      this.closeCb.set(popup_id, onClose)
    }
  }

  queue(popup_id, data = null, onClose = null) {
    this.#queue.push([popup_id, data, onClose])
    this.#updateQueue()
  }

  close(popup_id) {
    if (this.isOpened(popup_id)) {
      Analytics.changeScreenEvents("popup." + popup_id, null)
      if (this.vertxApi)
        this.vertxApi.analytics.navigationLeave("popup." + this.popup)

      if (this.closeCb.has(popup_id)) {
        let cb = this.closeCb.get(popup_id)
        if (cb) cb()
      }

      this.datas.delete(popup_id)
      this.closeCb.delete(popup_id)

      setTimeout(() => {
        this.#updateQueue()
      }, 1)
    }
  }

  #updateQueue() {
    if (!this.someOpened && this.#queue.length > 0) {
      let [popup_id, popup_data, close_cb] = this.#queue.shift() // on prend le premier element inséré FIFO
      this.open(popup_id, popup_data, close_cb)
    }
  }

  emptyQueue() {
    this.#queue = []
  }

  getData(popup_id) {
    return this.datas.get(popup_id)
  }

  isOpened(popup_id) {
    return this.datas.has(popup_id)
  }

  get someOpened() {
    return this.datas.size > 0
  }

  closeAll() {
    this.datas.clear()
    this.closeCb.clear()
  }
}

export default new PopupsManager()
