import { useEffect } from "react";
import popup_classes from '../Popups.module.css';
import classes from './PopupUser.module.css';

import FTUE, { FTUE_state } from "@/js/FTUE";
import Reliques from "@/js/Reliques/Reliques";
import clsx from "clsx";
import Texts from "react-sdk/Texts";


const Relique = ({relique}) => {
  var img_src = `./${window.CONFIG.root}/images/${relique.image}`

  return (
    <div className={classes.Relique}>
      {!relique.unlocked && <span className={classes.Relique_locked} >?</span>}
      {relique.unlocked && <img className={classes.Relique_image} src={img_src} />}
    </div>
  )
}

const PageReliques = () => {

  useEffect(() => {
    if(FTUE.popup_user_ftue.isActive() && !FTUE_state.viewed.includes("popup_user_ftue_step2")) {
      FTUE.popup_user_ftue.show("step2")
    }
  }, []);

  return (
    <div className={clsx(popup_classes.page, classes.PageReliques)}>
      <h4>{Texts.get("popup-user-tab2-title")}</h4>
      <div className={clsx(classes.reliques_list)}>
        {Reliques.all.map(relique => {
          return <Relique relique={relique} key={relique.id} />
        })}
      </div>
    </div>
  )
}

export default PageReliques