import React, {useState, useEffect} from 'react'

import classes from './ScreenTestMg.module.css'
import PopupsManager from 'react-sdk/PopupsManager';
import { POPUPS } from '@/js/AppState';
import PopupReplayMinigame from '@/components/popups/PopupReplayMinigame/PopupReplayMinigame';
import Minigames from '@/js/Minigames/Minigames';
import PopupMinigameSequence from '@/components/popups/PopupMinigameSequence/PopupMinigameSequence';
import PopupMinigameStars from '@/components/popups/PopupMinigameStars/PopupMinigameStars';
import { observer } from 'mobx-react-lite';

const ScreenTestMg = () => {

  return (
    <div className={classes.ScreenTestMg}>
      {PopupsManager.isOpened(POPUPS.REPLAY_MG) &&
      <PopupReplayMinigame />}

      {Minigames.current &&
      <PopupMinigameSequence />}

      {PopupsManager.isOpened(POPUPS.MINIGAME_STARS) &&
      <PopupMinigameStars />}

    </div>
  );
};

export default observer(ScreenTestMg);
