import AppState, { POPUPS } from '@/js/AppState';
import FTUE from '@/js/FTUE';
import SequenceScenario from '@/js/SequenceScenario';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import Config from 'react-sdk/Config';
import PopupsManager from 'react-sdk/PopupsManager';
import ScaleManager from 'react-sdk/ScaleManager';
import SequencesManager from 'react-sdk/SequencesManager';
import SoundPlayer from 'react-sdk/SoundPlayer';
import Loader from 'react-sdk/components/Loader/Loader';
import classes from './Map.module.css';
import { observer } from 'mobx-react-lite';



const Map = ({visible}) => {

  const [loaded, setLoaded] = useState(false);

  const elementRef = useRef(null)
  // const [bb, setBB] = useState(null);

  useEffect(() => {

    if(loaded) return


    let DM = succubus.data.DataManager.instance

    AppState.reaxe_api.registerModule("map", (event, params) => {
      if(event === "Click") {
        // NOTE Appelé seulement si on clique sur un POI, et une fois le déplacement vers celui-ci terminé

        // ici on recoit l'id du poi
        let poiId = params
        if(poiId) {
          // on va récupérer le fichier pois dans le DM
          let line =  DM.getFile("data/pois.data").getLine(poiId)
          if(line) {
            // on récupère la colonne action
            let actionCell = line.get("OnClickActions")
            if(actionCell) {
              actionCell.execute(() => {})
            }
          }

        }

      } else if(event === "GameLoaded") {
        // La map est affichée
        setLoaded(true)

        AppState.onMapReady()

      } else if(event === "DeplacementStart") {
        // SoundPlayer.playSound("map_pion")

        const clickOnPoi = params

        if(clickOnPoi) {
          // tut tut
          SoundPlayer.playSound("map_poi")
        }

      } else if(event === "DeplacementEnd") {

      }
    })

    AppState.reaxe_api.toHaxe("map",
      "launch",
      {
        avatarSpeed: Config.get("avatarSpeed"),
        avatarScale: Config.get("avatarScale"),
        state : AppState.loadedMapState,
        gameWidth: ScaleManager.w,
        gameHeight: ScaleManager.h
      }
    )

  }, []);

  useEffect(() => {
    if (visible) {
      if (AppState.haxeCanvas.parentElement)
        AppState.haxeCanvas.parentElement.removeChild(AppState.haxeCanvas)
      elementRef.current.append(AppState.haxeCanvas)
    }
  }, [visible]);


  const bb = AppState.mapFtueBatimentBoundingBox


  return (
    <>
      <div className={clsx(classes.Map, loaded && classes.loaded)} ref={elementRef} >
        {!loaded &&
        <div className={classes.loader}>
          <Loader />
        </div>
        }
      </div>

      {bb &&
      <div className="ftue-poiBB"
      style={{top: bb.y, left: bb.x, width: bb.width, height: bb.height}} />}

    </>
  );
};

export default observer(Map)
