import Button from "@/components/shared/Button/Button";
import ProfilePic from "@/components/shared/ProfilePic/ProfilePic";
import AppState from "@/js/AppState";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import Config from "react-sdk/Config";
import Loader from "react-sdk/components/Loader/Loader";
import classes from './PopupClassement.module.css';
import Texts from "react-sdk/Texts";


const PlayerRow = ({player, rank, isMyRow=false}) => {
  let pseudo = player.Pseudo || player.Mail
  return (
    <>
      <div className={clsx(classes.rank, isMyRow && classes.my_row)}>{rank}</div>
        <div className={clsx(classes.rank, isMyRow && classes.my_row)}><ProfilePic pseudo={pseudo} /></div>
        <div className={clsx(classes.teamPseudo, isMyRow && classes.my_row)}>{pseudo}</div>
        <div className={clsx(classes.stars, isMyRow && classes.my_row)}>
          {player.CustomData?.rekupr?.stars}
        </div>
    </>
  )
}

const PagePlayerLeaderboard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [myRow, setMyRow] = useState(null);
  const [endReached, setEndReached] = useState(false)
  const [nbLoads, setNbLoads] = useState(0);

  const pageSize = Config.get("leaderboard_page_size", 100)


  function loadPage() {

    setLoading(true)

    let universe = AppState.vertxClient.UserData.getUniverse()
    let collection = universe + "_Users"
    AppState.vertxClient.AdminQuery(collection, {
      // Filter: "",
      SortFields : { "CustomData.rekupr.stars" : -1 },
      Projection: ["_id", "Pseudo", "Mail", "CustomData.rekupr.stars"] ,
      PageSize: pageSize,
      PageOffset: pageSize * nbLoads,
      FindIndexes: {
        _id: [AppState.vertxClient.UserData.getUserID()]
      }
    }, (success, msg, data) => {
      if(success) {
        setData(list => [...list, ...data.List])
        setMyRow(data.Indexes[0])
        setNbLoads(nb => nb+1)
        if(data.List.length < pageSize) {
          setEndReached(true)
        }
        setLoading(false)

      }
      else {

      }

    })

  }


  useEffect(() => {
    loadPage()

  }, []);

  const myTeamIsInDatas = useMemo(() => {
    if(data && myRow) {
      return data.find(user => user._id === myRow.doc._id)
    }
    return false
  }, [data, myRow ])


  return (
    <div className={classes.PageTeamLeaderboard}>

      <div className={classes.grid}>
        {data.map((player, i) =>
        <PlayerRow key={player._id} player={player} rank={i+1} isMyRow={player._id === myRow.doc._id} />)}
        {myRow && !myTeamIsInDatas && <PlayerRow team={myRow.doc} rank={myRow.index + 1} isMyRow={true} />}
        {!loading && !endReached &&
        <div className={classes.load_more_ctnr}>
          <Button onClick={loadPage}>{Texts.get("popup-leaderboard-load-more-btn")}</Button>
        </div>}
      </div>

      {loading &&
      <div className={classes.loading}>
        <Loader />
      </div>}
    </div>
  )
}

export default PagePlayerLeaderboard