import React, {useState, useEffect} from 'react'

import classes from './PopupNouveautes.module.css'
import Popup from '@/components/shared/Popup/Popup'
import AppState, { POPUPS } from '@/js/AppState';
import PopupsManager from 'react-sdk/PopupsManager';
import Loader from 'react-sdk/components/Loader/Loader';
import GroupMessages from '@/js/GroupMessages';
import Collectes, { COLLECTE_STATUS } from '@/js/Collectes';
import Button from '@/components/shared/Button/Button';
import { FaChevronRight } from 'react-icons/fa6';

import image_notif_collecte from "./icon_notif_collecte.png"
import Texts from 'react-sdk/Texts';


const Nouveaute = ({notif}) => {
  const [loading, setLoading] = useState(true);
  const [icon, setIcon] = useState(null);
  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  const [action, setAction] = useState(null);

  useEffect(() => {
    if(notif?.NewCollectInfo) {
      // ici on récupère la collecte en question, et on affiche les infos selon le figma
      Collectes.getSingleCollecte(notif.NewCollectInfo)
      .then(collecte => {
        setLoading(false)
        setIcon(image_notif_collecte)
        setAction(() => () => PopupsManager.open(POPUPS.COLLECTE, collecte))

        const date = collecte.date_confirmation_DE_toLocale
        setTitle(Texts.get("collecte-surtitle", {mustacheVars: date}))

        if(collecte.statut_DE === COLLECTE_STATUS.REFUSEE) {
          setText(Texts.get("collecte-title-refused"))
        }
        else if(collecte.statut_DE === COLLECTE_STATUS.SUPPRIMEE) {
          setText(Texts.get("collecte-title-supprimee"))
        }

        else {
          if(!collecte.total_weight) {
            setText(Texts.get("collecte-title-nouvelle"))
          }
          else {
            setText(Texts.get("collecte-title-finalisee"))
          }
        }

      })
    }

    else if (notif?.ObjectiveAvailable) {

      // il s'agit d'un obj d'équipe de type children

      let obj = AppState.teamObjectives.getObjective(notif.ObjectiveAvailable)
      if(obj) {

        const obj_image = obj.parent.sourceObjective.line.get("image")

        let objIndex = obj.parent.children.indexOf(obj)
        let firstChild = objIndex === 0
        if (!firstChild)
          setTitle(Texts.get("popup-nouveautes-teamobj-step-start-title"))
        else
          setTitle(Texts.get("popup-nouveautes-teamobj-start-title"))

        setText(obj.parent.name + (firstChild ? "" : (" - Lvl. " + (objIndex + 1))))
        setIcon(obj_image)
        setLoading(false)

        setAction(() => () => {
          let text = undefined
          if (!firstChild) {
            let prevObj = obj.parent.children[objIndex - 1]
            text = prevObj.sourceObjective.line.get("completedText") + "<br/><br/>Nouvelle étape :<br/>" + obj.sourceObjective.line.get("descriptionText")
          } else {
            text = obj.sourceObjective.line.get("descriptionText")
          }


          let datas = {
            title: !firstChild ? Texts.get("popup-notif-teamobj-step-start-title") : Texts.get("popup-notif-teamobj-start-title"),
            surtitle: obj.parent.name + (firstChild ? "" : (" - Lvl. " + (objIndex + 1))),
            text: text,
            image_url: obj_image,

            onClose: () => PopupsManager.close(POPUPS.NOTIF),
            //Buttons: PopupNotifNewCollecteBtns.bind(null, {isReferent: AppState.userIsReferent, collecte}), // <-instance d'un component react avec des props
          }
          PopupsManager.open(POPUPS.NOTIF, datas)
        })
      }
    }

    else if (notif?.ObjectiveCompleted) {

      let obj = AppState.teamObjectives.getObjective(notif.ObjectiveCompleted)
      if(obj) {
        const obj_image = obj.sourceObjective.line.get("image")

        setTitle(Texts.get("popup-nouveautes-teamobj-end-title"))
        setText(obj.name)
        setIcon(obj_image)
        setLoading(false)

        setAction(() => () => {

          /** @type {import('../PopupNotif/PopupNotif').NotifPopupOptions} */
          let datas = {
            title: Texts.get("popup-notif-teamobj-end-title"),
            surtitle: obj.name,
            text: obj.children[obj.children.length - 1].sourceObjective.line.get("completedText"),

            image_url: obj_image,

            onClose: () => PopupsManager.close(POPUPS.NOTIF),
            //Buttons: PopupNotifNewCollecteBtns.bind(null, {isReferent: AppState.userIsReferent, collecte}), // <-instance d'un component react avec des props
          }
          PopupsManager.open(POPUPS.NOTIF, datas)
        })
      }
    }

    else if (notif?.BlasonUnlocked) {

      let DM = succubus.data.DataManager.instance
      let blasonsFile = DM.getFile("data/blasons.data")
      let blasonId = notif.BlasonUnlocked
      let blasonLine = blasonsFile.getLine(blasonId)
      if(blasonLine) {

        let datas = {
          title: blasonLine.get("title"),
          surtitle: Texts.get("popup-notif-blason-unlocked-title"),
          text: Texts.get("popup-notif-blason-unlocked-description", {
            mustacheVars: {
              blason_description: blasonLine.get("description").evaluate()
            }
          }),
          image_url: `./${window.CONFIG.root}/images/${blasonLine.get("image")}`,

          onClose: () => PopupsManager.close(POPUPS.NOTIF),
        }

        setTitle(Texts.get("popup-nouveautes-blason-unlocked-title", {mustacheVars: {blason_title: datas.title}}))
        setText(datas.text)
        setIcon(datas.image_url)
        setAction(() => () => PopupsManager.open(POPUPS.NOTIF, datas))

        setLoading(false)

      }
    }


    else if (notif?.PeriodChanged) {


      let DM = succubus.data.DataManager.instance
      let periodsFile = DM.getFile("data/periods.data")


      setTitle(Texts.get("popup-nouveaute-nouvelle-periode-classement-title"))
      setIcon("data/game-level/asset/poi-display/UIpoi3_classement.png")
      let oldPeriodID = notif.PeriodChanged.old
      let newPeriodID = notif.PeriodChanged.new

      let text = ""

      const handleNewPeriod = () => {
        if(newPeriodID) {
          let periodLine = periodsFile.getLine(newPeriodID)
          text += Texts.get("popup-nouveaute-nouvelle-periode-commence", {mustacheVars: {name: periodLine?.get("name")}})
        }
      }

      if(oldPeriodID) {
        let periodLine = periodsFile.getLine(oldPeriodID)
        text += Texts.get("popup-nouveaute-periode-termine", {mustacheVars: {name: periodLine?.get("name")}})

        AppState.team.fetchClassement(1, 0, { ["Periods." + oldPeriodID + ".Points"]: -1 } )
        .then(data => {
          if(data.Indexes && data.Indexes.length > 0) {
            text += Texts.get("popup-nouveaute-periode-classement", {mustacheVars: {classement: data.Indexes[0].index + 1}})
          }

          handleNewPeriod()
          setText(text)
          setLoading(false)
          setAction(() => () => {
            PopupsManager.open(POPUPS.LEADERBOARD)
          })

        })
        return
      }



      handleNewPeriod()
      setText(text)
      setLoading(false)
      setAction(() => () => {
        PopupsManager.open(POPUPS.LEADERBOARD)
      })

    }


    else if(notif?.CustomDataInfo?.Info?.Boutique?.Log) {
      setTitle(Texts.get("popup-nouveaute-message-boutique"))
      setText(notif.CustomDataInfo.Info.Boutique.Log)
      setIcon("data/game-level/asset/poi-display/UIpoi4_boutique.png")
      // const openBoutiqueFunc = () => {
      //   const userID = AppState.vertxClient.UserData.getUserID()
      //   window.open(window.CONFIG.boutique_url + userID)
      // }

      // setAction(() => openBoutiqueFunc)

      setLoading(false)
    }



    else {
      // ici rien, on ignore les notifs qui tombent pas dans les cas non spécifiés au dessus
      // mais ca devrait pas arriver
    }
  }, []);

  if(loading) return null // car certaines notifs on veut les masquer en fait

  return (
  <div className={classes.notif}>
    {/* {loading && <Loader />} */}
    <div className={classes.notif_icon}>
      <img src={icon} />
    </div>
    <div className={classes.notif_content}>
      <div className={classes.notif_title} dangerouslySetInnerHTML={{__html: title}} />
      <div className={classes.notif_text} dangerouslySetInnerHTML={{__html: text}} />
    </div>
    {action &&
    <div className={classes.notif_action}>
      <Button variant='contour' icon={<FaChevronRight />} onClick={action} />
    </div>}
  </div>)
}



const PopupNouveautes = () => {
  const [loading, setLoading] = useState(true);
  const [notifs, setNotifs] = useState([]);

  function handleClose() {
    PopupsManager.close(POPUPS.NOUVEAUTES)
  }

  useEffect(() => {
    if (GroupMessages.unreadMessages.length === 0) {
      setLoading(false)
      return
    }

    let notifs = GroupMessages.unreadMessages.map(msg => msg.content)

    setLoading(false)
    // console.log("messages (notifs) ", notifs)
    setNotifs(notifs)


    return () => {
      // à la fermeture de la popup, passer toutes les notifs en lues

      GroupMessages.markAsRead(GroupMessages.unreadMessages.map(msg => msg.id))
      .then(() => {
        GroupMessages.unreadMessages = []
      })
    }

  }, []);

  return (
    <Popup className={classes.PopupNouveautes} onClose={handleClose}>
      <div className="popup-title">
        <h3>{Texts.get("popup-nouveautes-title")}</h3>
      </div>
      <div className={classes.notifs}>
        {loading &&
        <div className={classes.loading}><Loader /></div>}

        {notifs.map((notif, index) => <Nouveaute notif={notif} key={index} />)}

        {!loading && notifs.length === 0 &&
        <div className={classes.no_notif}>{Texts.get("popup-nouveautes-nothing")}</div>}

      </div>

    </Popup>
  );
};

export default PopupNouveautes;
