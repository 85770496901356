import React, {useState, useEffect} from 'react'

import classes from './ActivityTitle.module.css'
import Media from '../Media/Media';

const ActivityTitle = ({activity, showMedia=true}) => {

  return (
    <div className={classes.ActivityTitle}>
      {showMedia && activity.media &&
      <div className={classes.media}>
        <Media path={activity.media } key={activity.ID}/>
      </div>}
      <h1 className={classes.title} dangerouslySetInnerHTML={{__html: activity.Text}} />
    </div>
  );
};

export default ActivityTitle;
