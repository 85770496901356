import React, {ReactNode , useState, useEffect} from 'react'
import { observer } from 'mobx-react-lite';
import classes from './TeamHeader.module.css'
import AppState from '@/js/AppState';
import clsx from 'clsx';
import ProfilePic from '../ProfilePic/ProfilePic';



/**
 *
 * @returns {ReactNode} LE header d'équipe, utilisé dans lers écrans de démarrage, et la popup d'équipe
 */
const TeamHeader = ({small, onClick}) => {
  const team = AppState.team

  function handleClick() {
    if(onClick) onClick()
  }

  return (
    <div className={clsx(classes.TeamHeader, small && classes.small)}>

      <div
      className={clsx(classes.team_pp_blason, "ftue-team-button", onClick && classes.clickable)}
      onClick={handleClick} >
        <ProfilePic pseudo={team.teamPseudo} />
        <div className={classes.team_blason}></div>
      </div>
      <div className={classes.content}>
        {!small && <div className={classes.type}>Equipe</div>}
        {!small && <div className={classes.team_pseudo}>{team.teamPseudo}</div>}
        <div className={clsx(classes.team_coins, "team-coins")}>{parseInt(team.coins)}</div>
      </div>
    </div>
  );
};

export default observer (TeamHeader)
