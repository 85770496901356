const EVENT_TYPES = {
  NAVIGATION: "NAVIGATION",
  SCORE: "SCORE",
  CHOICE: "CHOICE",
  ACTION: "ACTION",
  CONTENT: "CONTENT",
  INPUT: "INPUT",
}

class Analytics {
  client = null

  init(vertxClient) {
    this.client = vertxClient
  }

  createEvent(type, id, value) {
    return JSON.stringify({ type, id, value })
  }

  sendEvents(events) {
    if (this.client) {
      this.client.UserEventsAdd(events)
    }
  }

  /**
   * @param {string} prevScreenId
   * @param {string} nextScreenId
   */
  changeScreenEvents(prevScreenId, nextScreenId) {
    const events = []

    if (prevScreenId)
      events.push(
        this.createEvent(EVENT_TYPES.NAVIGATION, prevScreenId, "LEAVE")
      )
    if (nextScreenId)
      events.push(
        this.createEvent(EVENT_TYPES.NAVIGATION, nextScreenId, "ENTER")
      )

    this.sendEvents(events)
  }

  // a appeler quand on répond à une question
  qcmEvents(questionId, answerId, score) {
    const events = [this.createEvent(EVENT_TYPES.CHOICE, questionId, answerId)]

    this.sendEvents(events)
  }

  scoreEvents(id, value) {
    const events = [this.createEvent(EVENT_TYPES.SCORE, id, value)]

    this.sendEvents(events)
  }

  userInputEvents(inputs) {
    // inputs = {field_id: field_value}

    const events = []
    for (let id in inputs) {
      events.push(this.createEvent(EVENT_TYPES.INPUT, id, inputs[id]))
    }

    this.sendEvents(events)
    return
  }

  contentEventShow(value) {
    const events = [this.createEvent(EVENT_TYPES.CONTENT, value, "SHOW")]
    this.sendEvents(events)
  }

  contentEventHide(value) {
    const events = [this.createEvent(EVENT_TYPES.CONTENT, value, "HIDE")]
    this.sendEvents(events)
  }

  actionEvent(id, action) {
    const events = [this.createEvent(EVENT_TYPES.ACTION, id, action)]
    this.sendEvents(events)
  }
}

export default new Analytics()
