import React, { useEffect } from "react";
import popup_classes from '../Popups.module.css'
import classes from './PopupTeam.module.css'

import clsx from "clsx";
import {observer} from "mobx-react-lite"

import TeamGrid from "@cmp/shared/TeamGrid/TeamGrid";
import MagasinInfos from "@cmp/shared/MagasinInfos/MagasinInfos";
import Texts from "react-sdk/Texts";
import AppState from "@/js/AppState";
import ProfilePic from "@/components/shared/ProfilePic/ProfilePic";
import EditableInput from "@/components/shared/EditableInput/EditableInput";
import { Team } from "@/js/Team";
import FTUE, { FTUE_state } from "@/js/FTUE";

/**
 *
 * @param {object} props
 * @param {Team} props.team
 *
 * @returns
 */
const PageInfos = ({team}) => {

  useEffect(() => {

    if(FTUE.popup_team_ftue.isActive() && !FTUE_state.viewed.includes("popup_team_page04")) {
      FTUE.popup_team_ftue.show("popup_team_page04")
    }
  }, []);

  function updateTeamPseudo(newValue, cb) {
    team.updatePseudo(newValue)
    .then(cb)
  }

  return (
    <div className={clsx(popup_classes.page, classes.PageInfos)}>
      <h4>{Texts.get("popup-team-tab4-title")}</h4>

      {AppState.userIsReferent &&
      <div  className={classes.page_infos_referent_block}>
        <div className={classes.referent_info}>
          {Texts.get("popup-team-referent-info")}
        </div>
        {/* <ProfilePic pseudo={team.teamPseudo} /> */}
        <EditableInput value={team.teamPseudo} onSubmit={updateTeamPseudo} />
      </div>}

      <TeamGrid showReferentActions={AppState.userIsReferent} />

      <MagasinInfos />
    </div>
  )
}

export default observer(PageInfos)
