// le but de cette classe est de formater un fichier .data en json spécifique QCM pour le mettre dans une activity
// il permet aussi de relire ce json et de le manipuler côté react

import { makeAutoObservable, makeObservable, observable, computed, action, toJS } from "mobx"
import Debrief from './Debrief'
import { STATES } from "./ActivitiesConstants"
import { pickRandom } from "../utils"

import Notifications from "../Notifications/Notifications"


class Choice {

  selected = false
  locked = false

  constructor(json, observe) {
    Object.assign(this, json)
    if(observe) this.observe()
  }

  observe() {
    makeObservable(this, {
      selected: observable,
      locked: observable,
      state: computed,
      setSelected: action
    })
  }

  setSelected(val) {
    this.selected = val
  }

  get state() {
    if(this.isCorrect && this.selected) return STATES.CORRECT
    if(!this.isCorrect && this.selected) return STATES.WRONG
    if(this.isCorrect && !this.selected) return STATES.MISSED
    return null

  }

}


// représente une seule question, avec ses choices et debriefs
export class QCM {
  ID
  Text = null
  media = null
  /** @type {Array<Choice>} */
  choices = []

  debriefs = []


  /** @type {import("./ActivitiesConstants").ActivityAnswer} */
  answer = null

  jokerUsed = false // TODO voir si on peut se passer de ca avec le answer


  constructor(json) {
    Object.assign(this, json)
    // NOTE : parfois on ne le veut pas observable, quand on l'utilise depuis la classe QCMFile
  }

  observe() {
    makeObservable(this, {
      jokerUsed: observable,
      answer: observable,
      MultipleChoiceMode: computed,
      selectedChoicesIds: computed,
      jokerAvailable: computed
    })
  }

  fromJson(json) {
    Object.assign(this, json)
    this.choices = this.choices.map(c => new Choice(c, true))
    this.observe()
    return this
  }

  addChoice(json) {
    this.choices.push(new Choice(json))
  }

  addDebrief(json, isCorrect) {
    this.debriefs.push(new Debrief(json, isCorrect))
  }

  setAnswer(answer) {
    this.answer = answer
  }

  handleJauges(jauges) {
    // on regarde les réponses qu'on a cochées
    this.choices.filter(c => c.selected).forEach(c => {
      // si elles ont une info de jauge

      if(c.jauge) {
        try {
          let jauge_obj = JSON.parse(c.jauge)
          Object.keys(jauge_obj).forEach(jauge_id => {
            let success = jauges.increment(jauge_id, jauge_obj[jauge_id])
            if(!success) Notifications.error("Erreur colonne 'jauge' pour le QCM " + this.ID + " : cette id de jauge n'existe pas dans 'jauge.data' : " + jauge_id)
          })
        }
        catch(err) {
          Notifications.error("Erreur colonne 'jauge' pour le QCM " + this.ID)
        }
      }

    })

  }


  localCorrect(qrlAnswer) {
    // ici on va créer nous même le "answer" au lieu de l'attendre du serveur
    /** @type {import('react-sdk/activities/ActivitiesConstants').ActivityAnswer} */
    // c'est correct si pas de wrong no de missed

    if(this.Type === "question") {
      let nb_wrong = this.choices.filter(c => c.state === STATES.WRONG).length
      let nb_missed = this.choices.filter(c => c.state === STATES.MISSED).length
      let answer = {
        is_correct: nb_missed === 0 && nb_wrong === 0
      }
      this.setAnswer(answer)
    }

    if(this.Type === "qrl") {
      let is_correct = this.choices.find(c => {
        return c.Text.localeCompare(qrlAnswer.trim(), undefined, { sensitivity: 'base' }) === 0
      }) !== undefined

      let answer = { is_correct }
      this.setAnswer(answer)

    }
  }

  get MultipleChoiceMode() {
    return this.choices.filter(c => c.isCorrect).length > 1
  }

  toggleChoice(choice) {

    if (choice.locked) return

    if(this.MultipleChoiceMode) {
      choice.setSelected(!choice.selected)
    }
    else {
      this.choices.forEach(c => c.setSelected(false))
      choice.setSelected(true)
    }
  }


  get selectedChoices() {
    return this.choices.filter(c => c.selected)
  }

  get unselectedChoices() {
    return this.choices.filter(c => !c.selected)

  }

  get selectedChoicesIds() {
    return this.selectedChoices.map(c => c.ID)
  }

  useJoker() {
    if(!this.jokerAvailable) return

    if(this.MultipleChoiceMode) {
      // on préselectionne un choice correct


      let c = pickRandom(this.choices.filter(c => c.isCorrect))
      c.selected = true
      c.locked = true
    }
    else {
      // on supprime un choice incorrect
      let c = pickRandom(this.choices.filter(c => !c.isCorrect))
      c.selected = false
      c.locked = true
    }

    this.jokerUsed = true
  }

  get jokerAvailable() {
    if(this.choices.length <= 2) return false
    if(this.jokerUsed) return false

    return true
  }

  getDebrief() {
    if(this.debriefs.length === 0) return null
    if(this.debriefs.length === 1) return this.debriefs[0]
    if(this.debriefs.length === 2) {
      if(this.answer.is_correct) {
        return this.debriefs.find(d => d.isCorrect)
      }
      else {
        return this.debriefs.find(d => !d.isCorrect)
      }
    }

  }

  reset() {
    this.answer = null
    this.choices.forEach(c => {
      c.selected = false
    })
  }

}


// représente tous les QCM
export class QCMFile {
  /** @type {Array<QCM>} */
  all = []

  constructor(json, file) {
    let current = null

    json.forEach(row => {
      try {
        if(row.Type === "question" ||row.Type === "qrl") {
          current = new QCM(row)
          this.all.push(current)
        }

        if(row.Type === "choice") {
          current.addChoice(row)
        }

        if(row.Type === "debrief") {
          current.addDebrief(row, row.isCorrect)
        }
      }
      catch(err) {
        throw "ERREUR CREATION QCM : " + row.ID + " dans " + file
      }
    })

  }
  get(id) {
    return this.all.find(qcm => qcm.ID === id)
  }

  observeAll() {
    this.all.forEach(qcm=> {
      qcm.observe()
      qcm.choices.forEach(c=>c.observe())
    })
  }
}