import Button from "@/components/shared/Button/Button";
import star from "@/images/star.png";
import AppState, { POPUPS } from "@/js/AppState";
import Minigame from "@/js/Minigames/Minigame";
import Minigames from "@/js/Minigames/Minigames";
import SequenceScenario from "@/js/SequenceScenario";
import { FaLock } from "react-icons/fa6";
import PopupsManager from "react-sdk/PopupsManager";
import Texts from "react-sdk/Texts";

// NOTE : on stocke des components de boutons de popup notif comme ils sont dynamiques, on les passe en param, mais parfois c'est depuis un .js qui connait pas le jsx, donc on les stocke ici


export const PopupNotifsReliqueBtns = () => {
  return (
    <Button onClick={() => PopupsManager.close(POPUPS.NOTIF)} >{Texts.get("popup-notif-reliques-btn")}</Button>
  )
}

/**
 *
 * @param {{mg: Minigame}} param0
 * @returns
 */
export const PopupNotifStartMinigameBtns = ({mg}) => {
  return (
  <div className={"PopupNotifStartMinigameBtns_list"}>
    {mg.levels.map((level, index )=> {
      const textoptions =  {mustacheVars: {level_num: index+1 }}
      const isUnlocked = AppState.test_mg_mode ? true : level.unlocked


      return (
      <div className={"PopupNotifStartMinigameBtns"} key={index}>
        <Button
          className={"play_btn"}
          disabled={!isUnlocked}
          icon_right={isUnlocked ? null : <FaLock />}
          onClick={() => {
            PopupsManager.close(POPUPS.NOTIF)
            Minigames.launch(mg, index)
          }} >
            {!isUnlocked && Texts.get("popup-notif-minigame-btn-locked", textoptions)}

            {isUnlocked &&
            <>
              {level.playCount === 0 ?
              Texts.get("popup-notif-minigame-btn-play", textoptions) :
              Texts.get("popup-notif-minigame-btn-replay", textoptions)}
            </>}
        </Button>

        <div className={"PopupNotifStartMinigameBtns_stars"} key={level.configRef}>
          {Array.from(Array(3), (e, i) => <img key={i} src={star} className={i >= level.stars && "star_disabled"} />)}
        </div>

      </div>)
    })}
  </div>
  )
}

export const PopupMGReglesBtn = () => {

  const onclick= () => {
    PopupsManager.close(POPUPS.NOTIF)
  }

  return (
    <Button onClick={onclick} >{Texts.get("popup-notif-minigame-ftue-button")}</Button>
  )
}


export const PopupNotifChapterEndBtns = () => {
  return (
    <Button onClick={() => {

      // ici on relance la sequence principale
      SequenceScenario.playSequence("MainSequence", "root")
      PopupsManager.close(POPUPS.NOTIF)

    }} >{Texts.get("popup-chapter-ended-btn")}</Button>
  )
}


export const PopupNotifNewCollecteBtnsEstim = ({collecte}) => {
  const handleClick = () => {
    console.log("collecte", collecte)
    PopupsManager.open(POPUPS.COLLECTE, collecte)
    PopupsManager.close(POPUPS.NOTIF)
  }

  return (
    <div>
      <Button onClick={handleClick} >{Texts.get("popup-notif-newcollecte-referent-btn")}</Button>
    </div>
  )
}

export const PopupNotifNewCollecteBtnsOK = () => {

  const handleClick = () => {
    PopupsManager.close(POPUPS.NOTIF)
  }

  return (
    <div>
      <Button onClick={handleClick} >OK</Button>
    </div>
  )
}


export const PopupNotifNewCollecteBtns = ({isReferent, collecte}) => {

  const handleClick = () => {
    PopupsManager.open(POPUPS.COLLECTE, collecte)
    PopupsManager.close(POPUPS.NOTIF)
  }

  return (
    <div>
      {isReferent && <Button onClick={handleClick} >{Texts.get("popup-notif-newcollecte-referent-btn")}</Button>}
      {!isReferent && <Button onClick={handleClick} >{Texts.get("popup-notif-newcollecte-player-btn")}</Button>}
    </div>

  )
}


export const PopupNotifImReferentBtn = () => {
  return (
    <Button onClick={() => PopupsManager.close(POPUPS.NOTIF)} >{Texts.get("popup-notif-im-referent-btn")}</Button>
  )
}

export const PopupNotifOtherSessionsBtn = ({handleClick}) => {

  return (
    <Button onClick={handleClick}>{Texts.get("popup-notif-session-other-button")}</Button>
  )
}

export const PopupNotifSessionClosedBtn = () => {

  function handleClick() {
    console.log("close()");

    window.close()
  }
  return (
    <Button onClick={handleClick}>Fermer la fenêtre</Button>
  )
}