import Button from "@/components/shared/Button/Button";
import ProfilePic from "@/components/shared/ProfilePic/ProfilePic";
import AppState from "@/js/AppState";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import Config from "react-sdk/Config";
import Loader from "react-sdk/components/Loader/Loader";
import classes from './PopupClassement.module.css';
import Texts from "react-sdk/Texts";
import { objectGetDeepValue } from "react-sdk/utils";


const TeamRow = ({pointsPath, team, rank, isMyRow=false}) => {
  let pseudo = team.CustomData?.Infos?.teamPseudo || team.CustomData?.Infos?.nom_pdc

  let points = objectGetDeepValue(team.CustomData, pointsPath)
  console.log("points", points)

  return (
    <>
      <div className={clsx(classes.rank, isMyRow && classes.my_row)}>{rank}</div>
        <div className={clsx(classes.rank, isMyRow && classes.my_row)}><ProfilePic pseudo={pseudo} /></div>
        <div className={clsx(classes.teamPseudo, isMyRow && classes.my_row)}>{pseudo}</div>
        <div className={clsx(classes.jetons, isMyRow && classes.my_row)}>
          {points ? parseInt(points) : 0}
        </div>
    </>
  )
}

const PageTeamLeaderboard = () => {
  const [loading, setLoading] = useState(true);

  const [periodId, setPeriodId] = useState(null);

  const [data, setData] = useState([]);
  const [myRow, setMyRow] = useState(null);
  const [endReached, setEndReached] = useState(false)
  const [nbLoads, setNbLoads] = useState(0);
  const [error, setError] = useState(null);

  const pageSize = Config.get("leaderboard_page_size", 100)


  const CDPath = periodId ? "Periods." + periodId + ".Points" : null

  function getPeriod() {
    let classementActivity = AppState.currentMeeting.findActivity(a => a.Type === "Custom.RekupoClassement")
    if (classementActivity) {

      AppState.currentMeeting.customActivityCommand("GetCurrentPeriod", classementActivity.NetID, false, null)
      .then(data => {
        setPeriodId(data.DataArray[0])
      })
      .catch(err => {
        console.log("GetCurrentPeriod error", err)
        setLoading(false)
        setError("Une erreur est survenue, merci de réessayer plus tard...")
      })
    }
    else {
      console.log("GetCurrentPeriod no activity")
      setLoading(false)
      setError("Une erreur est survenue, merci de réessayer plus tard... ")
      // setPeriodId("automne_2024")

    }

  }


  function getData() {

    setLoading(true)

    if(!CDPath) return



    AppState.team.fetchClassement(
      pageSize,
      nbLoads * pageSize,
      { [CDPath]: -1 },
      ["Points", "Infos.teamPseudo", "Infos.nom_pdc", CDPath]
    )
    // AppState.team.fetchClassement(pageSize, nbLoads * pageSize)
    .then(data => {
      console.log("data", data)

      setData(list => [...list, ...data.List])
      setMyRow(data.Indexes[0])
      setNbLoads(nb => nb+1)
      if(data.List.length < pageSize) {
        setEndReached(true)
      }
    })
    .finally(() => {
      setLoading(false)
    })
  }


  useEffect(() => {
    getPeriod()
  }, []);

  useEffect(() => {
    if(periodId) getData()
  }, [periodId]);


  const myTeamIsInDatas = useMemo(() => {
    if(data && myRow) {
      return data.find(group => group.FullName === myRow.doc.FullName)
    }
    return false
  }, [data, myRow ])

  let periodTitle = useMemo(() => {
    if(periodId) {
      try {
        let DM = succubus.data.DataManager.instance
        let periodsFile = DM.getFile("data/periods.data")
        let line =  periodsFile.getLine(periodId)
        if(line) return line.get("name")
        return periodId
      }
      catch(err) {
        console.error(err)
        return null
      }
    }
    return null

  }, [periodId])


  return (
    <div className={classes.PageTeamLeaderboard}>

      {data.length > 0 &&
      <>
        <div className={classes.period_title}>{periodTitle}</div>
        <div className={classes.grid}>
          {data.map((group, i) => <TeamRow pointsPath={CDPath} team={group} rank={i+1} isMyRow={group.FullName === myRow.doc.FullName} />)}
          {myRow && !myTeamIsInDatas && <TeamRow pointsPath={CDPath} team={myRow.doc} rank={myRow.index + 1} isMyRow={true} />}
          {!loading && !endReached &&
          <div className={classes.load_more_ctnr}>
            <Button onClick={getData}>{Texts.get("popup-leaderboard-load-more-btn")}</Button>
          </div>}
        </div>

      </>}

      {loading &&
      <div className={classes.loading}>
        <Loader />
      </div>}

      {error && <div className={classes.error}>{error}</div>}
    </div>
  )
}

export default PageTeamLeaderboard