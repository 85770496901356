import React, { useEffect } from 'react';
import popup_classes from '../Popups.module.css';
import classes from './PopupUser.module.css';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { FaBookBookmark } from 'react-icons/fa6';
import { GiRoundStar } from 'react-icons/gi';
import { LiaAwardSolid } from 'react-icons/lia';
import PopupsManager from 'react-sdk/PopupsManager';
import AppState, { POPUPS } from '@/js/AppState';
import Popup from '@cmp/shared/Popup/Popup';
import Tabs from '@cmp/shared/Tabs/Tabs';
import PageReliques from './PageReliques';
import PageUsersObjective from './PageUsersObjective';
import PageInfos from './PageInfos';



import tab1 from "./tabs_img/user_tab_01.png"
import tab2 from "./tabs_img/user_tab_02.png"
import tab3 from "./tabs_img/user_tab_03.png"
import PlayerHeader from '@/components/shared/PlayerHeader/PlayerHeader';
import SoundPlayer from 'react-sdk/SoundPlayer';
import FTUE, { FTUE_state } from '@/js/FTUE';
import SequenceScenario from '@/js/SequenceScenario';


const PopupUser = () => {

  useEffect(() => {
    SoundPlayer.playSound("music_batUI")

    if(!FTUE_state.viewed.includes("popup_user_ftue")) {
      FTUE.popup_user_ftue.start()
    }

    return () => {
      SoundPlayer.playSound("music_map")
    }
  }, []);


  function handleClose() {
    PopupsManager.close(POPUPS.USER)
  }

  return (
    <Popup className={clsx(classes.PopupUser)} onClose={handleClose}>

      <div className={clsx(classes.PopupUser_header )}>
        <PlayerHeader />
      </div>

      <Tabs
      popupId={POPUPS.USER}
      tabs={[
        <img src={tab1} />,
        <img src={tab2} />,
        <img src={tab3} />
      ]}
      panels={[
        //<PageStats />,
        <PageUsersObjective />,
        <PageReliques />,
        <PageInfos />,
      ]}
      />
    </Popup>
  );
};

export default observer(PopupUser)
