import React, {useState, useEffect, useRef} from 'react'

import classes from './PopupGroupAddUser.module.css'
import Popup from '@/components/shared/Popup/Popup';
import ProfilePic from '@/components/shared/ProfilePic/ProfilePic';
import AppState, { POPUPS } from '@/js/AppState';
import PopupsManager from 'react-sdk/PopupsManager';
import Button from '@/components/shared/Button/Button';
import Texts from 'react-sdk/Texts';

const PopupGroupAddUser = () => {

  const inputRef = useRef(null)

  const [mail, setMail] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);

  function handleSubmit(e) {
    e.preventDefault()

    setError(null)
    setShowSuccess(false)


    let userdata = AppState.vertxClient.UserData
    let groupName = AppState.team.groupId

    const userInfo = { Mail: mail, Pseudo: mail.split("@").shift() }
    AppState.vertxClient.UserAdd(userInfo, (success, msg, data) => {

      if(success) {
        let userid = data

        AppState.vertxClient.GroupUserAdd(userdata.getUniverse(), groupName, userid, (success, msg, data) => {
          if(success) {
            AppState.team.fetchUsers()
            setShowSuccess(mail)
            setMail("")
            inputRef.current.focus()
          } else {
            setError(msg)
          }
        })
      } else {
        setError(msg)
      }
    })



  }

  return (
    <Popup className={classes.PopupGroupAddUser} onClose={() => PopupsManager.close(POPUPS.GROUPADDUSER)}>

      <div className={classes.pp}><ProfilePic pseudo={AppState.team.teamPseudo} /></div>

      <div className={classes.invite}>{Texts.get("popup-group-add-user-title")}</div>

      <form onSubmit={handleSubmit}>
        <input ref={inputRef} type="email" placeholder={Texts.get("popup-group-add-user-placeholder")} value={mail} onChange={e => setMail(e.target.value)} />

        <Button>{Texts.get("popup-group-add-user-button")}</Button>

        {error && <div className={classes.error}>{Texts.get("popup-group-add-user-error", {mustacheVars: {error}})}</div>}

        {showSuccess && <div className={classes.success}>{Texts.get("popup-group-add-user-success", {mustacheVars: {email: showSuccess}})}</div>}
      </form>
    </Popup>
  );
};

export default PopupGroupAddUser;
