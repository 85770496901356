import React, {useState, useEffect} from 'react'

import classes from './ScreenWelcome.module.css'
import HomeScreens from '@scr/_layouts/HomeScreens/HomeScreens';
import Texts from 'react-sdk/Texts';
import AppState, { SCREENS } from '@/js/AppState';
import Button from '@cmp/shared/Button/Button';
import Config from 'react-sdk/Config';

const ScreenWelcome = () => {

  function handleClick() {
    AppState.setScreen(SCREENS.USER)
  }


  return (
    <HomeScreens
    title={Texts.get("welcomescreen-title")}
    text={Texts.get("welcomescreen-text")}
    >
      <div className={classes.elements}>
        {/* <div className={classes.check}>
          <label>
            <input type="checkbox" />
            J'accepte d'être contacté par REKUP'R
          </label>
        </div> */}
        <div className={classes.button_ctnr}>
          <Button className={classes.button} onClick={handleClick}>
            {Texts.get("welcome-screen-button")}
          </Button>
        </div>
      </div>


    </HomeScreens>
  );
};

export default ScreenWelcome;
