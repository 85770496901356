import { computed, makeObservable, observable } from "mobx"
import { states } from "./ObjectiveFile"

class Objective {

  /**
   * Objet source de l'objectif, venant de l'ObjectivesLib haxe
   * @type {succubus.objective.Objective}
   */
  sourceObjective

  /**
   * Etat de l'objectif
   * @type {states}
   */
  state=states.INACTIVE

  /**
   * la progression (float entre 0 et 1), plus d'info dans doc succubus.objective.Objective.getProgress()
   */
  // progress=0

  get progress() {
    return this.sourceObjective.progress.noCtxValue
  }


  get available() {
    return this.sourceObjective.available.validCached.noCtxValue
  }
  get completed() {
    return this.sourceObjective.available.validCached.noCtxValue
  }


  constructor(sourceObjective) {
    this.sourceObjective = sourceObjective

    this.sourceObjective.reactObjective = this // TEST :

    // console.log("this.sourceObjective.reactObjective", this.sourceObjective.reactObjective)

    this.sourceObjective.available.addPostAction((doneFunc) => {
      this.state = states.ACTIVE
      doneFunc()
    })
    this.sourceObjective.completed.addPostAction((doneFunc) => {
      this.state = states.COMPLETED

      setTimeout(() => {
        this.state = states.FINISHED
      }, 3000);
      doneFunc()
    })


    // observation du progress
    makeObservable(this.sourceObjective.progress, { noCtxValue : observable })

    // NOTE j'ai essayé ça mais ca renvoie true tout le temps...
    // observation des states
    // makeObservable(this.sourceObjective.available.validCached, { noCtxValue : observable })
    // makeObservable(this.sourceObjective.completed.validCached, { noCtxValue : observable })


    makeObservable(this, {
      state : observable,
      progress : computed,
      available : computed,
      completed : computed
     })
  }

  get id() {
    return this.sourceObjective.line.id
  }

  /**
   * parent de cet objectif
   * @type {Objective}
   */
  get parent() {
    if(this.sourceObjective.parent) {
      return this.sourceObjective.parent.reactObjective
    }
    return null

  }

  /**
   * Renvoie les objectifs enfants
   * @type {Array<Objective>}
   */
  get children() {
    return this.sourceObjective.getChildren().map(o => o.reactObjective)
  }

  // appelé lorsqu'on load un state depuis la sauvrgarde
  refreshState() {
    if (this.sourceObjective.completed.isValid()) {
      this.state = states.FINISHED
      return
    }

    if (this.sourceObjective.available.isValid()) {
      this.state = states.ACTIVE
      return
    }
    this.state = states.INACTIVE
  }


  /**
   * Renvoie le nom (colonne name dans le .data)
   */
  get name() {
    return this.sourceObjective.line.get("name")
  }

}

export default Objective
