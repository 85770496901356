import Match3 from "@/components/Match3/Match3";
import Minigames, { MG_TYPES } from "@/js/Minigames/Minigames";
import { useEffect, useMemo, useState } from 'react';
import DragDrop from "./DragDrop/DragDrop";
import Qcm from "./Qcm/Qcm";


import Popup from '@/components/shared/Popup/Popup';
import classes from './PopupMinigameSequence.module.css';
import { observer } from "mobx-react-lite";
import PopupsManager from "react-sdk/PopupsManager";
import { POPUPS } from "@/js/AppState";
import Texts from "react-sdk/Texts";
import SoundPlayer from "react-sdk/SoundPlayer";
import WireLaby from "@/components/WireLaby/WireLaby";
import { FaQuestion } from "react-icons/fa6";
import Button from "@/components/shared/Button/Button";
import MinigameFtues from "@/js/Minigames/MinigameFtues";
import ConnectionLink from "@/components/ConnectionLink/ConnectionLink";
import BrickBreaker from "@/components/BrickBreaker/BrickBreaker";
import PointerValider from "./PointerValider/PointerValider";
import WordFind from "@/components/WordFind/WordFind";
import ColourPuzzle from "@/components/ColourPuzzle/ColourPuzzle";
import Parking from "@/components/Parking/Parking";

const haxeGame = [MG_TYPES.MATCH3, MG_TYPES.WIRELABY, MG_TYPES.CONNECTIONLINK, MG_TYPES.BRICKBREAKER, MG_TYPES.WORDFIND, MG_TYPES.COLOURPUZZLE, MG_TYPES.PARKING]

const PopupMinigameSequence = () => {

  let mg = Minigames.current
  let levelIndex= Minigames.currentLevelIndex

  // todo stocker le score ici pour le minigame en cours, attention il faut le mettre à jour à chaque fois
  const [score, setScore] = useState(0);

  // NOTE c'est pour les sequences de QCM/DragDrop, on stocke ici l'index de l'element actuel
  const [currentElementIndex, setCurrentElementIndex] = useState(null);

  function onFinished() {
      // ICI comment je réupère le score actuel du M3 ?
    Minigames.stop(score)
  }

  useEffect(() => {
    SoundPlayer.playSound("music_MG")
  }, []);


  useEffect(() => {
    if(!mg.elements) return

    if(mg.elements.length > 0) {
      setCurrentElementIndex(0)
    }
  }, [mg]);

  function next() {
    if(mg.elements.length > currentElementIndex + 1) {
      setCurrentElementIndex(i => i+1)
    }
    else {
      onFinished()
    }
  }

  const ftue_avail = useMemo(() => {
    return MinigameFtues.getForMG(mg.type).length > 0
  }, [mg])

  function showFtue() {
    const pages = MinigameFtues.getForMG(mg.type)
    /** @type {import("../PopupNotif/PopupNotif").NotifPopupOptions} */
    const data = {
      Pages: pages,
      onClose: () => PopupsManager.close(POPUPS.NOTIF)
    }

    PopupsManager.open(POPUPS.NOTIF, data)
  }

  return (
    <Popup className={classes.PopupMinigameSequence} onClose={() => Minigames.stop(0)} >

      {ftue_avail &&
      <Button icon={<FaQuestion />} className={classes.ftue_btn} onClick={showFtue} />}

      {currentElementIndex !== null && mg.type === MG_TYPES.QUIZ &&
      <Qcm
      qcm={mg.elements[currentElementIndex]}
      onFinished={next}
      onPoints={points => setScore(s => s + points )}  />}

      {currentElementIndex !== null && mg.type === MG_TYPES.DRAGDROP &&
      <DragDrop dd={mg.elements[currentElementIndex]} onFinished={next} onPoints={points => setScore(s => s + points )}  />}



      {currentElementIndex !== null && mg.type === MG_TYPES.POINTERVALIDER &&
      <PointerValider
      pv={mg.elements[currentElementIndex]}
      onFinished={next}
      onPoints={points => setScore(s => s + points )}
      />}

      {mg.type === MG_TYPES.MATCH3 &&
      <Match3 level={mg.levels[levelIndex].gameConfigRef} onFinished={(score) => {
        // NOTE pas super élégant, permet de ne pas compter ce score si jamais on a cliqué sur la croix
        //  juste avant que le jeu se finisse
        //  il faudrait mettre le jeu en pause plutôt
        if(!PopupsManager.isOpened(POPUPS.MINIGAME_STARS)) {
            Minigames.stop(score)
          }
        }
      } />}

      {mg.type === MG_TYPES.WIRELABY &&
      <WireLaby level={mg.levels[levelIndex].gameConfigRef} onFinished={(score) => {
        // NOTE pas super élégant, permet de ne pas compter ce score si jamais on a cliqué sur la croix
        //  juste avant que le jeu se finisse
        // TODO : il faudrait mettre le jeu en pause plutôt
        if(!PopupsManager.isOpened(POPUPS.MINIGAME_STARS)) {
            Minigames.stop(score)
          }
        }
      } />}

      {mg.type === MG_TYPES.CONNECTIONLINK &&
      <ConnectionLink level={mg.levels[levelIndex].gameConfigRef} onFinished={(score) => {
        // NOTE pas super élégant, permet de ne pas compter ce score si jamais on a cliqué sur la croix
        //  juste avant que le jeu se finisse
        // TODO : il faudrait mettre le jeu en pause plutôt
        if(!PopupsManager.isOpened(POPUPS.MINIGAME_STARS)) {
            Minigames.stop(score)
          }
        }
      } />}

      {mg.type === MG_TYPES.BRICKBREAKER &&
      <BrickBreaker level={mg.levels[levelIndex].gameConfigRef} onFinished={(score) => {
        // NOTE pas super élégant, permet de ne pas compter ce score si jamais on a cliqué sur la croix
        //  juste avant que le jeu se finisse
        //  il faudrait mettre le jeu en pause plutôt
        if(!PopupsManager.isOpened(POPUPS.MINIGAME_STARS)) {
            Minigames.stop(score)
          }
        }
      } />}

    {mg.type === MG_TYPES.WORDFIND &&
      <WordFind level={mg.levels[levelIndex].gameConfigRef} onFinished={(score) => {
        // NOTE pas super élégant, permet de ne pas compter ce score si jamais on a cliqué sur la croix
        //  juste avant que le jeu se finisse
        //  il faudrait mettre le jeu en pause plutôt
        if(!PopupsManager.isOpened(POPUPS.MINIGAME_STARS)) {
            Minigames.stop(score)
          }
        }
      } />}

      {mg.type === MG_TYPES.COLOURPUZZLE &&
      <ColourPuzzle level={mg.levels[levelIndex].gameConfigRef} onFinished={(score) => {
        // NOTE pas super élégant, permet de ne pas compter ce score si jamais on a cliqué sur la croix
        //  juste avant que le jeu se finisse
        //  il faudrait mettre le jeu en pause plutôt
        if(!PopupsManager.isOpened(POPUPS.MINIGAME_STARS)) {
            Minigames.stop(score)
          }
        }
      } />}

      {mg.type === MG_TYPES.PARKING &&
      <Parking level={mg.levels[levelIndex].gameConfigRef} onFinished={(score) => {
        // NOTE pas super élégant, permet de ne pas compter ce score si jamais on a cliqué sur la croix
        //  juste avant que le jeu se finisse
        //  il faudrait mettre le jeu en pause plutôt
        if(!PopupsManager.isOpened(POPUPS.MINIGAME_STARS)) {
            Minigames.stop(score)
          }
        }
      } />}

      { !haxeGame.includes(mg.type) &&
        // les jeux haxe gère l'affiche de leur score eux même
        <div className={classes.score}>
          <div className={classes.score_title}>{Texts.get("score-block-title")}</div>
          <span>{score}</span>
        </div>
      }
    </Popup>
  )
}

export default observer(PopupMinigameSequence)
