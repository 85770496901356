import { makeAutoObservable } from "mobx";
import Minigames, { MG_TYPES } from "./Minigames";

export class MinigameLevel {
  id;
  threshold_1 = 5;
  threshold_2 = 10;
  threshold_3 = 15;
  gameConfigRef = null;

  stars = 0;
  unlocked = false;
  playCount = 0;

  constructor(json) {
    Object.assign(this, json);

    makeAutoObservable(this);
  }

  calculateStars(score) {
    const formula = (score) => {
      if (score >= this.threshold_3) return 3;
      if (score >= this.threshold_2) return 2;
      if (score >= this.threshold_1) return 1;
      return 0;
    };
    let nb = formula(score);
    if (nb > this.stars) {
      let diff = nb - this.stars;
      this.stars = nb;
      return diff; // on n'ajoute à notre score global que la différence !
    }

    return null;
  }
}

class Minigame {
  id;
  title;
  type;
  refs;

  unlocked = false;

  /** @type {Array<MinigameLevel>} */
  levels;

  constructor(json) {
    Object.assign(this, json);

    if (this.levels) {
      this.levels = this.levels
        .trim()
        .split(";")
        .map((id) => Minigames.getLevel(id));
    } else {
      console.error("Missing level config for minigame " + this.id + ", using default config");
      this.levels = [new MinigameLevel(null)];
    }

    this.levels[0].unlocked = true;
    makeAutoObservable(this);
  }

  unlock() {
    // on enregistre le chapitre ou cela a eu lieu
    this.unlocked = true


  }

  getLevel(id) {
    return this.levels.find((l) => l.id === id);
  }

  unlockNextLevel(currentIndex) {
    let next = this.levels[currentIndex + 1];

    if (next) {
      next.unlocked = true;
    }
  }

  calculateStars(score, level_index) {
    let level = this.levels[level_index];
    let nb_stars_won = level.calculateStars(score);

    if (nb_stars_won > 0) {
      //
      this.unlockNextLevel(level_index);
    }
    return nb_stars_won;
  }

  get currentStars() {
    return this.levels.reduce((acc, l) => acc + l.stars, 0);
  }
  get totalStars() {
    return this.levels.length * 3;
  }



  get img_url() {
    return "data/images/minigames_icons/" + this.type + ".png";
  }

  get elements() {
    if (!this.refs) return null;

    let refs = this.refs.split(";");
    if (this.type === MG_TYPES.QUIZ) {
      return refs.map((id) => Minigames.qcms.get(id));
    }

    if (this.type === MG_TYPES.DRAGDROP) {
      return refs.map((id) => Minigames.dragdrops.get(id));
    }
    if (this.type === MG_TYPES.POINTERVALIDER) {
      return refs.map((id) => Minigames.pointerValider.get(id));
    }
  }
}

export default Minigame;
