import React, {useState, useEffect, useRef} from 'react'

import classes from './Match3.module.css'
import clsx from 'clsx';
import Loader from 'react-sdk/components/Loader/Loader';
import AppState from '@/js/AppState';

const Match3 = ({level, onFinished }) => {
  const elementRef = useRef(null)
  const [loaded, setLoaded] = useState(false);
  
  // MATCH3 USE EFFECT INITIAL
  useEffect(() => {

    AppState.reaxe_api.registerModule("match3", (event, params) => {
      if(event === "match3locked") {
        toHaxe("match3", "start")
      } else if(event === "match3win") {
        console.log("match3win results", params)

        let {moves, score} = params // pas sur que ca serve
        if(onFinished) onFinished(score)

      }
    })
    
    AppState.reaxe_api.toHaxe("match3", "launch", {configId : level}, () => setLoaded(true) )
    AppState.mapVisible = false;
    if (AppState.haxeCanvas.parentElement)
      AppState.haxeCanvas.parentElement.removeChild(AppState.haxeCanvas)
    elementRef.current.append(AppState.haxeCanvas)
    // appelé à la fermeture du composant
    return () => {
      AppState.reaxe_api.toHaxe("match3", "delete", null, 
        () => {
          AppState.mapVisible = true;
          setLoaded(false)
          AppState.reaxe_api.registerModule("match3", null)
        }
      )
    }

  }, []);

  return (
    <div className={clsx(classes.Match3, loaded && classes.loaded)} ref={elementRef} >
    {!loaded &&
    <div className={classes.loader}>
      <Loader />
    </div>
    }
  </div>
  );
};

export default Match3;
