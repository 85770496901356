import React, {ReactNode , useState, useEffect} from 'react'

import classes from './PlayerHeader.module.css'
import AppState from '@/js/AppState';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import ProfilePic from '../ProfilePic/ProfilePic';

/**
 *
 * @returns {ReactNode} LE header d'équipe, utilisé dans lers écrans de démarrage, et la popup d'équipe
 */
const PlayerHeader = ({small}) => {
  let pseudo = AppState.vertxClient.UserData.getPseudo()
  return (
    <div className={clsx(classes.PlayerHeader, small && classes.small)}>
      <div className={classes.pp_blason}>
        <ProfilePic pseudo={pseudo} />
        <div className={classes.blason}></div>
      </div>
      <div className={classes.content}>
        {!small && <div className={classes.type}>Joueur</div>}
        {!small && <div className={classes.pseudo}>{pseudo}</div>}
        {AppState.userStars !== null &&
        <div className={classes.stars}>{AppState.userStars}</div>}
      </div>
    </div>
  );
};

export default observer(PlayerHeader)
