import { makeAutoObservable } from "mobx"

export default class Debrief {

  constructor(json, isCorrect) {


    Object.assign(this, json)
    this.isCorrect = isCorrect

  }
}
