import React, { useEffect } from 'react'
import classes from './Dial.module.css'
import { observer } from 'mobx-react-lite';
import AppState from '@/js/AppState';
import CanvasCharacter from './CanvasCharacter/CanvasCharacter';
import Button from '@cmp/shared/Button/Button';

import Loader from 'react-sdk/components/Loader/Loader'
import clsx from 'clsx';
import SoundPlayer from 'react-sdk/SoundPlayer';
import Analytics from 'react-sdk/Vertx/Analytics';




const Dial = () => {

  const loading = AppState.dials.loading
  const dial = AppState.dials.currentDial
  const characterIds = Array.from(AppState.dials.loadedCharIds)

  useEffect(() => {
    if(!dial) return
    if(dial.Type === "choice_title") {
      SoundPlayer.playSound("dial_choice")
    }
    else {
      SoundPlayer.playSound("dial_pop")
    }

    Analytics.contentEventShow(`dial.${dial.id}`)
    return () => {
      Analytics.contentEventHide(`dial.${dial.id}`)

    }

  }, [dial]);

  return (

    <div className={clsx(classes.Dial)}>


      {dial &&
      <>
        {!dial.raw.bg && <div className={classes.dial_overlay} />}

        {dial.raw.bg &&
        <div
        className={classes.dial_bg}
        style={{backgroundImage: `url(${window.CONFIG.root}/images/dial_bg/${dial.raw.bg})`}} />}

        {characterIds.map(id => {
            return <CanvasCharacter key={id} char_id={id} sequence_element={dial} onEnd={()=>AppState.dials.nextDial()} />
        })}


        {dial.Type === "choice_title" &&
        <div className={classes.choices}>
          <div className={classes.choice_title}>
            {dial.raw.Text}
          </div>
          <div className={classes.choice_buttons}>
            {dial.choices.map(choice => {
              return <div
              className={classes.choice_btn} key={choice.id} onClick={() => {
                dial.addAnswer(choice.id)
                AppState.dials.nextDial()
                SoundPlayer.playSound("dial_answer")
              }}>{choice.raw.Text}</div>
            })}
          </div>
        </div>}

      </>}

      {loading &&
      <div className={classes.loading}>
        <Loader size={100} />
      </div>}

    </div>



  );
};

export default observer(Dial)
