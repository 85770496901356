import React from 'react';
import Loader from "react-sdk/components/Loader/Loader";

import './ScreenLoading.css';

const ScreenLoading = () => {
  return (
    <div className="screen ScreenLoading">
      <Loader />
    </div>
  );
};

export default ScreenLoading;
