import Shepherd from "shepherd.js";
import { offset } from "@floating-ui/core";
import SequenceScenario from "./SequenceScenario";
import AppState from "./AppState";

// NOTE ne pas oublier ca, ca peut servir
// advanceOn: {selector: ".ftue-besoin", event: "click"}


// NOTE permet de faire des SequenceScenario.next() a la fermeture de certaines popups, ou de lancer la ftue a l'ouverture de certaines autres
export let FTUE_state = {
  viewed: [],
};

export function loadFTUEState(state) {
  Object.keys(state).forEach(k => {
    FTUE_state[k] = state[k]
  })
}




const tourOptions = {
  useModalOverlay: true,

  // confirmCancel: () => {

  //   let component = document.querySelector(".shepherd-modal-overlay-container")
  //   let stepComponent = document.querySelector(".shepherd-element:not([hidden])")

  //   component.classList.add("shepherd-hidden")
  //   stepComponent.classList.add("shepherd-hidden")

  //   return new Promise((resolve) => {
  //     PopupsManager.open(POPUPS.CANCEL_FTUE, (res) => {
  //       if(!res) {
  //         component.classList.remove("shepherd-hidden")
  //         stepComponent.classList.remove("shepherd-hidden")
  //       }
  //       resolve(res)

  //     })
  // })},

  exitOnEsc: false,
  defaultStepOptions: {
    // classes: classes.FTUE,
    scrollTo: false,
    modalOverlayOpeningPadding: 15,
    modalOverlayOpeningRadius: 10,
    floatingUIOptions: { middleware: [offset(40)] },
    cancelIcon: { enabled: false },
  },
};

let chapter_bar_ftue = new Shepherd.Tour(tourOptions);

chapter_bar_ftue.on("complete", () => {
  FTUE_state.viewed.push("chapter_bar_ftue");
});

chapter_bar_ftue.addStep({
  id: "step1",
  text: "Suivez votre progression ainsi que celle de vos collègues dans cet épisode",
  attachTo: {
    element: ".ftue-chapter-bar",
    on: "top",
  },
  canClickTarget: false,

  buttons: [
    {
      text: () => "OK",
      action: chapter_bar_ftue.next,
    },
  ],
});

chapter_bar_ftue.addStep({
  id: "step1.2",
  text: "Cliquez ici pour consulter vos informations personnelles.",
  attachTo: {
    element: ".ftue-player-popup",
    on: "top",
  },

  canClickTarget: false,

  buttons: [
    {
      text: () => "OK",
      action: chapter_bar_ftue.next,
    },
  ],
});



let team_header_ftue = new Shepherd.Tour(tourOptions);

team_header_ftue.on("complete", () => {
  console.log("team_header_ftue complete");
  FTUE_state.viewed.push("team_header_ftue");
});


team_header_ftue.addStep({
  id: "team_header_ftue_step1",
  text: "Cliquez ici pour consulter les infos de l'équipe.",
  attachTo: {
    element: ".ftue-team-button",
    on: "top",
  },
  canClickTarget: false,
  buttons: [
    {
      text: () => "OK",
      action: team_header_ftue.next,
    },
  ],
});




let popup_user_ftue = new Shepherd.Tour(tourOptions);

popup_user_ftue.on("complete", () => {
  FTUE_state.viewed.push("popup_user_ftue");
});
// ici page player objectives
popup_user_ftue.addStep({
  id: "step1",
  text: "Terminez tous les objectifs d'un épisode pour gagner des étoiles et passer à l'épisode suivant.",
  attachTo: {
    element: ".ftue-user-objectives",
    on: "top",
  },
  beforeShowPromise: function () {
    return new Promise(function (resolve) {
      setTimeout(resolve, 250);
    });
  },
  when: {
    show: () => {
      document.querySelector(".Popup-close").classList.add("ftue-deactivate");
      // document.querySelector(".Tab-1").classList.add("ftue-hide");
    },
    hide: () => {
      document.querySelector(".Tab-1").classList.add("ftue-blink");
      document.querySelector(".Tab-2").classList.add("ftue-deactivate");
      FTUE_state.viewed.push("popup_user_ftue_step1")
    },
    // cancel: () => {
    //   document.querySelector(".Popup-close").classList.remove("ftue-deactivate")

    // }
  },
  buttons: [
    {
      text: () => "OK",
      action: () => {
        popup_user_ftue.hide();
      },
    },
  ],
});
// ici page player reliques
popup_user_ftue.addStep({
  id: "step2",
  text: "Les reliques que vous trouverez apparaîtront sur cette page.",
  when: {
    show: () => {
      document.querySelector(".Tab-1").classList.remove("ftue-blink");
    },
    cancel: () => {},
    complete: () => {
      console.log("step2 complete");
      // document
      //   .querySelector(".Popup-close")
      //   .classList.remove("ftue-deactivate");
    },
    hide: () => {
      document.querySelector(".Tab-2").classList.remove("ftue-deactivate");
      document.querySelector(".Tab-2").classList.add("ftue-blink");
      FTUE_state.viewed.push("popup_user_ftue_step2")
      console.log("step2 hide");
    },
    destroy: () => {
      console.log("step2 destroy");
      // document
      //   .querySelector(".Popup-close")
      //   .classList.remove("ftue-deactivate");
    },
  },
  buttons: [
    {
      text: () => "OK",
      action: () => {
        popup_user_ftue.hide();
      }
    },
  ],
});
// ici page player info
popup_user_ftue.addStep({
  id: "step3",
  text: "Ici, consultez les informations concernant votre profil.",
  when: {
    show: () => {
      document.querySelector(".Tab-2").classList.remove("ftue-blink");
    },
    cancel: () => {},
    complete: () => {
      console.log("step3 complete");
      document
        .querySelector(".Popup-close")
        .classList.remove("ftue-deactivate");
    },
    hide: () => {
      console.log("step3 hide");
    },
    destroy: () => {
      console.log("step3 destroy");
      FTUE_state.viewed.push("popup_user_ftue_step3")

      document
        .querySelector(".Popup-close")
        .classList.remove("ftue-deactivate");
    },
  },
  buttons: [
    {
      text: () => "OK",
      action: popup_user_ftue.next,
    },
  ],
});


let popup_team_ftue = new Shepherd.Tour(tourOptions);
popup_team_ftue.on("complete", () => {
  FTUE_state.viewed.push("popup_team_ftue");
});

// page collectes 01
popup_team_ftue.addStep({
  id: "page_collecte_01",
  text: "En valorisant la collecte des déchets dans votre magasin, vous pouvez gagner des jetons.",
  attachTo: {
    element: ".collectes-block",
    on: "top",
  },
  canClickTarget: false,
  beforeShowPromise: function () {
    return new Promise(function (resolve) {
      setTimeout(resolve, 250);
    });
  },
  when: {
    show: () => {
      document.querySelector(".Popup-close").classList.add("ftue-deactivate");
      document.querySelector(".Tab-1").classList.add("ftue-deactivate")
      document.querySelector(".Tab-2").classList.add("ftue-deactivate")
      document.querySelector(".Tab-3").classList.add("ftue-deactivate")
    },
    hide: () => {
      // document.querySelector(".Tab-2").classList.add("ftue-blink")
    },
    // cancel: () => {
    //   document.querySelector(".Popup-close").classList.remove("ftue-deactivate")

    // }
  },
  buttons: [
    {
      text: () => "OK",
      action: () => {
        popup_team_ftue.next();
      },
    },
  ],
});
// page collectes 02
popup_team_ftue.addStep({
  id: "page_collecte_02",
  text: "Pour obtenir plus d'informations sur la collecte, veuillez contacter votre référent Rekupo.",
  attachTo: {
    element: ".collectes-block",
    on: "top",
  },

  canClickTarget: false,
  when: {
    show: () => {
      // document.querySelector(".Popup-close").classList.add("ftue-deactivate")
      // document.querySelector(".Tab-1").classList.add("ftue-hide")
    },
    hide: () => {

      // document.querySelector(".Tab-2").classList.add("ftue-blink")
    },
    // cancel: () => {
    //   document.querySelector(".Popup-close").classList.remove("ftue-deactivate")

    // }
  },
  buttons: [
    {
      text: () => "OK",
      action: () => {
        popup_team_ftue.next();
      },
    },
  ],
});
// page collectes 03
popup_team_ftue.addStep({
  id: "page_collecte_03",
  text: "Voici les jetons actuellement disponibles pour votre équipe. Vous pouvez échanger ces jetons contre des récompenses réelles !",
  attachTo: {
    element: ".popup-team-header .team-coins",
    on: "bottom",
  },
  when: {
    show: () => {
      // document.querySelector(".Popup-close").classList.add("ftue-deactivate")
    },
    hide: () => {
      // document.querySelector(".Tab-2").classList.add("ftue-blink")
      document.querySelector(".Tab-1").classList.remove("ftue-deactivate");
      document.querySelector(".Tab-1").classList.add("ftue-blink");
      FTUE_state.viewed.push("popup_team_page01")
    },
    // cancel: () => {
    //   document.querySelector(".Popup-close").classList.remove("ftue-deactivate")

    // }
    destroy: () => {
      // document.querySelector(".Popup-close").classList.remove("ftue-deactivate")
    },
  },
  buttons: [
    {
      text: () => "OK",
      action: () => {
        popup_team_ftue.hide();
      },
    },
  ],
});

// ici page team obj
popup_team_ftue.addStep({
  id: "popup_team_page02",
  text: "Complétez ces objectifs d'équipe pour gagner plus de jetons !",
  when: {
    show: () => {
      document.querySelector(".Tab-1").classList.remove("ftue-blink");
    },
    cancel: () => {},
    hide: () => {
      document.querySelector(".Tab-2").classList.remove("ftue-deactivate");
      document.querySelector(".Tab-2").classList.add("ftue-blink");
      FTUE_state.viewed.push("popup_team_page02")

    },
  },
  buttons: [
    {
      text: () => "OK",
      action: () => {
        popup_team_ftue.hide();
      },
    },
  ],
});
// ici page team blasons
popup_team_ftue.addStep({
  id: "popup_team_page03",
  text: "Cette page affiche les blasons gagnés par votre équipe",
  when: {
    show: () => {
      document.querySelector(".Tab-2").classList.remove("ftue-blink");
    },
    cancel: () => {},
    hide: () => {
      document.querySelector(".Tab-3").classList.remove("ftue-deactivate");
      document.querySelector(".Tab-3").classList.add("ftue-blink");
      FTUE_state.viewed.push("popup_team_page03")

    },
  },
  buttons: [
    {
      text: () => "OK",
      action: () => {
        popup_team_ftue.hide();
      },
    },
  ],
});
// ici page team info
popup_team_ftue.addStep({
  id: "popup_team_page04",
  text: "Ici, consultez les informations concernant votre équipe.",
  when: {
    show: () => {
      document.querySelector(".Tab-3").classList.remove("ftue-blink");
    },
    cancel: () => {},
    destroy: () => {
      FTUE_state.viewed.push("popup_team_page04")
      document
        .querySelector(".Popup-close")
        .classList.remove("ftue-deactivate");
    },
  },
  buttons: [
    {
      text: () => "OK",
      action: popup_team_ftue.next,
    },
  ],
});

// ici popup replay MG
let batiment_replaymg_ftue = new Shepherd.Tour(tourOptions);

batiment_replaymg_ftue.on("complete", () => {
  console.log("batiment_replaymg_ftue complete");
  FTUE_state.viewed.push("batiment_replaymg_ftue");

  AppState.mapFtueBatimentBoundingBox = null
  // SequenceScenario.next()

});

batiment_replaymg_ftue.addStep({
  id: "step1",
  text: "Cliquez sur ce bâtiment pour rejouer aux mini-jeux et activités déjà terminés.",
  attachTo: {
    element: ".ftue-poiBB",
    on: "top",
  },
  canClickTarget: true, // un peu contre-intuitif : si true, le div de bounding-box devient cliquable et bloque le clic sur le batiment
  modalOverlayOpeningPadding: -10,
  buttons: [
    {
      text: () => "OK",
      action: batiment_replaymg_ftue.next,
    },
  ],
});

// ici popup boutique
let batiment_boutique_ftue = new Shepherd.Tour(tourOptions);
batiment_boutique_ftue.on("complete", () => {
  console.log("batiment_boutique_ftue complete");
  FTUE_state.viewed.push("batiment_boutique_ftue");
  AppState.mapFtueBatimentBoundingBox = null
  AppState.reaxe_api.toHaxe("map", "CameraFollowAvatar")

  // SequenceScenario.next()
});

batiment_boutique_ftue.addStep({
  id: "step1",
  text: "Cliquez sur la boutique pour échanger les jetons gagnés.",
  attachTo: {
    element: ".ftue-poiBB",
    on: "top",
  },
  canClickTarget: true, // un peu contre-intuitif : si true, le div de bounding-box devient cliquable et bloque le clic sur le batiment
  modalOverlayOpeningPadding: -10,
  buttons: [
    {
      text: () => "OK",
      action: batiment_boutique_ftue.next,
    },
  ],
});

// ici popup classement
let batiment_classement_ftue = new Shepherd.Tour(tourOptions);
batiment_classement_ftue.on("complete", () => {
  console.log("batiment_classement_ftue complete");
  FTUE_state.viewed.push("batiment_classement_ftue");

  AppState.mapFtueBatimentBoundingBox = null
  // SequenceScenario.next()
});

batiment_classement_ftue.addStep({
  id: "step1",
  text: "Cliquez sur le podium pour consulter le classement des joueurs et des équipes.",
  attachTo: {
    element: ".ftue-poiBB",
    on: "top",
  },
  canClickTarget: true, // un peu contre-intuitif : si true, le div de bounding-box devient cliquable et bloque le clic sur le batiment
  modalOverlayOpeningPadding: -10,
  buttons: [
    {
      text: () => "OK",
      action: batiment_classement_ftue.next,
    },
  ],
});

export default {
  chapter_bar_ftue,
  team_header_ftue,
  batiment_replaymg_ftue,
  batiment_boutique_ftue,
  batiment_classement_ftue,

  popup_user_ftue,
  popup_team_ftue,
  // tour2,
  // tour4,
};
