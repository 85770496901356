import { makeAutoObservable } from "mobx"
import Notification from "./Notification"


export const TYPES = {
  NEUTRAL: "NEUTRAL",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS"
}

class Notifications {
  list = new Set()

  constructor() {
    makeAutoObservable(this)
  }

  neutral(message, duration) {
    console.log("Notifications neutral", message)
    this.list.add(new Notification(message, duration, TYPES.NEUTRAL))

  }

  error(message, duration) {

    console.error("Notifications error", message)
    this.list.add(new Notification(message, duration, TYPES.ERROR))

  }
  errors(messages, duration) {
    messages.forEach(msg => this.error(msg, duration))
  }

  success(message, duration) {
    console.log("Notifications success", message)
    this.list.add(new Notification(message, duration, TYPES.SUCCESS))
  }

  remove(notif) {
    this.list.delete(notif)
  }


}


export default new Notifications()