import EditableInput from "@/components/shared/EditableInput/EditableInput";
import TeamHeader from "@/components/shared/TeamHeader/TeamHeader";
import AppState from "@/js/AppState";
import FTUE, { FTUE_state } from "@/js/FTUE";
import clsx from "clsx";
import { useEffect } from "react";
import Notifications from "react-sdk/Notifications/Notifications";
import Texts from "react-sdk/Texts";
import popup_classes from '../Popups.module.css';
import classes from './PopupUser.module.css';

const PageInfos = () => {

  let pseudo = AppState.vertxClient.UserData.getPseudo()
  let mail = AppState.vertxClient.UserData.getUserMail()


  useEffect(() => {

    if(FTUE.popup_user_ftue.isActive() && !FTUE_state.viewed.includes("popup_user_ftue_step3")) {
      FTUE.popup_user_ftue.show("step3")
    }
  }, []);

  function updateUserPseudo(newValue, cb) {
    AppState.vertxClient.UserUpdatePseudo(newValue, (success, msg, data) => {
      if(success) {
        Notifications.success("User infos updated !", 5000)
      } else {
        Notifications.error(msg, 5000)
      }
      cb()
    })
  }

  return (
    <div className={clsx(popup_classes.page, classes.PageInfos)}>
      <h4>{Texts.get("popup-user-tab3-title")}</h4>

      <div className={clsx(classes.player_infos)}>
        {/* <ProfilePic pseudo={pseudo} /> */}

        <EditableInput onSubmit={updateUserPseudo} value={pseudo} maxLength={15}  />

        <div className="block">
          <TeamHeader />
        </div>
        <div className={classes.mail}>{mail}</div>
        {/* <Button className={classes.disconnect_btn}>Se déconnecter</Button> */}
      </div>
    </div>
  )
}

export default PageInfos