import { POPUPS } from '@/js/AppState';
import Popup from '@cmp/shared/Popup/Popup';
import Tabs from '@cmp/shared/Tabs/Tabs';
import { useEffect } from 'react';
import PopupsManager from 'react-sdk/PopupsManager';
import PagePlayerLeaderboard from './PagePlayerLeaderboard';
import PageTeamLeaderboard from './PageTeamLeaderboard';
import classes from './PopupClassement.module.css';

import { FTUE_state } from '@/js/FTUE';
import SequenceScenario from '@/js/SequenceScenario';
import SoundPlayer from 'react-sdk/SoundPlayer';
import Texts from 'react-sdk/Texts';
import tab1 from "./tabs_img/classement_tab_team.png";
import tab2 from "./tabs_img/classement_tab_users.png";

const PopupClassement = () => {


  useEffect(() => {
    SoundPlayer.playSound("music_batUI")

    return () => {
      SoundPlayer.playSound("music_map")
    }
  }, []);

  function handleClose() {
    PopupsManager.close(POPUPS.LEADERBOARD)
  }

  return (

    <Popup className={classes.PopupClassement} onClose={handleClose}>

      <div className="popup-title">
        <h3>{Texts.get("popup-leaderboard-title")}</h3>
      </div>


      <Tabs
      popupId={POPUPS.LEADERBOARD}
      tabs={[ <img src={tab1} />, <img src={tab2} />]}
      panels={[<PageTeamLeaderboard />, <PagePlayerLeaderboard />]}
      />

    </Popup>

  );
};

export default PopupClassement;
