import { useEffect } from 'react';

import Dial from '@/components/Dial/Dial';
import AppState, { SCREENS } from '@/js/AppState';
import classes from './ScreenDial.module.css';
import clsx from 'clsx';
import SoundPlayer from 'react-sdk/SoundPlayer';

const ScreenDial = () => {
  useEffect(() => {

    // SoundPlayer.playSound("music_dials")
    AppState.dials.playSequence("DialsIntro", "postCine", () => {
      console.log("FIN DU DIAL")
      AppState.setScreen(SCREENS.GAME)
    })
  }, []);
  return (
    <div className={clsx("screen", classes.ScreenDial)}>
      <Dial />
    </div>
  );
};

export default ScreenDial;
