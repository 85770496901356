import React, { useState } from "react"
import Button from "@cmp/shared/Button/Button"
import classes from './Media.module.css'
import {TbMaximize} from 'react-icons/tb'
import {FaXmark} from 'react-icons/fa6'
import YouTube from 'react-youtube';
import getVideoId from 'get-video-id';

const youtubeConfig = {

  autoplay: 0,
  controls: 1,
  cc_load_policy: 0,
  fs: 1,
  iv_load_policy: 3,
  modestbranding: 1,
  rel: 0,
  showinfo: 0,
}

const getMediaType = (path) => {
  let videoId = getVideoId(path)

  if(videoId.service === "youtube") {
    return "youtube"
  }

  let types = {
    image: ["jpg", "jpeg", "png", "gif"],
    video: ["mp4", "avi", "mov"],
  }

  let ext = path.split(".").pop()

  for(let type of Object.keys(types)) {
    if(types[type].includes(ext.toLowerCase())) {
      return type
    }
  }

  return null
}

const Media = ({path}) => {
  const [showFullscreen, setShowFullscreen] = useState(false);

  let type = getMediaType(path)

  let filepath = `${window.CONFIG.root}/images/medias_pedago/${path}`

  return (
    <div className={classes.media}  >

      {type=== "youtube" &&
      <YouTube
      className="youtube-player"
      iframeClassName="youtube-player-iframe"
      videoId={getVideoId(path).id} opts={{ height: '300', width: '100%', playerVars: youtubeConfig}} />}


      {type==="image" &&
      <img src={filepath} alt="" draggable={false}/>}

      {type === "video" &&
      <video controls src={filepath} autoPlay></video>}

      {type === "image" &&
      <Button
        data-no-dnd="true"
        onClick={() => setShowFullscreen(true)} icon={<TbMaximize />}
        className={classes.media_fullscreen} />}



      {showFullscreen &&
      <div className={classes.fullscreenmedia}  data-no-dnd="true" onClick={() => setShowFullscreen(false)}>
        {type==="image" &&
        <img onClick={e => e.stopPropagation()} src={filepath} alt=""  draggable={false}/>}

        <Button onClick={() => setShowFullscreen(false)} className={classes.media_fullscreen} icon={<FaXmark />} />
      </div>}

    </div>
  )

}


export default Media
