import React, {useState, useEffect} from 'react'
import YouTube from 'react-youtube';

import classes from './ScreenVideo.module.css'
import AppState, { SCREENS } from '@/js/AppState';
import Button from '@cmp/shared/Button/Button';
import SoundPlayer from 'react-sdk/SoundPlayer';
import Texts from 'react-sdk/Texts';

const youtubeConfig = {
  autoplay: 1,
  controls: 1,
  cc_load_policy: 0,
  fs: 1,
  iv_load_policy: 3,
  modestbranding: 1,
  rel: 0,
  showinfo: 0,
}

const ScreenVideo = () => {
  const [showSkipButton, setshowSkipButton] = useState(false);

  useEffect(() => {
    if(showSkipButton) {
      let timer = setTimeout(() => setshowSkipButton(false), 1500)

      return () => clearTimeout(timer)
    }
  }, [showSkipButton]);

  useEffect(() => {
    SoundPlayer.stop()
  }, []);

  function onEnd() {
    AppState.setScreen(SCREENS.FIRST_DIAL)
  }


  return (
    <div className={classes.ScreenVideo} onClick={() => setshowSkipButton(true)}>
      <div className={classes.video_ctnr}>
        {/* <YouTube
        className="youtube-player"
        iframeClassName="youtube-player-iframe"
        videoId="O5xfw_a-TPo" opts={{ height: '300', width: '100%', playerVars: youtubeConfig}}
        onEnd={onEnd}
        /> */}

        <video playsInline autoPlay src={window.CONFIG.root + "images/intro.mp4"} controls onEnded={onEnd}/>

        {showSkipButton && <Button className={classes.btn} onClick={onEnd}>{Texts.get("video-skip")}</Button>}

      </div>
    </div>
  );
};

export default ScreenVideo;
