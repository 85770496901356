import { makeAutoObservable } from "mobx"
import Config from "react-sdk/Config"
import Notifications from "react-sdk/Notifications/Notifications"
import SequencesManager, { SequencesFile } from "react-sdk/SequencesManager"
import SoundPlayer from "react-sdk/SoundPlayer"
import { makeId } from "react-sdk/utils"

class Dials {
  name = makeId()
  onDialEnd = null

  debugSkip = false

  dialsSequence = null
  currentDialSequenceId = null
  currentDial

  loadedCharIds = new Set()

  loading = false

  constructor(debugSkip = false) {
    console.log("debugSkip", debugSkip)
    this.debugSkip = debugSkip
    makeAutoObservable(this, { onDialEnd : false })
  }


  addDialFile(filename, seqFile) {
    // NOTE ceci ajoute automatiquement au SM
    SequencesFile.createFromDataFile(filename, seqFile)
  }

  deleteAllCharacters() {
    window.SpeechAPI.deleteCharacters(Array.from(this.loadedCharIds))
    this.loadedCharIds = new Set()
  }

  getSequenceChars(filename, sequenceID) {
    let file = SequencesManager.findFile(filename)

    let chars = new Set()
    file.elements
    .filter(el => el.SequenceId === sequenceID)
    .forEach(el => {
      chars.add(el.raw.Character)
      if(el.raw.otherCharacters) {
        el.raw.otherCharacters.split(";").forEach(c => chars.add(c))
      }
    })

    return chars
  }

  playSequence(filename, sequenceID, onEnd=null, ) {

    SoundPlayer.playSound("music_dials") // ici on joue les dials

    if(this.debugSkip) {
      // Notifications.neutral("DIAL SEQUENCE " + sequenceID)
      if(onEnd) onEnd()
      return
    }

    if(!SequencesManager.hasSequence(filename, sequenceID)) {
      console.warn("La sequence de dialogue", filename, sequenceID, "n'existe pas")
      if(onEnd) onEnd()
      return
    }
    this.loading = true

    this.onDialEnd = onEnd
    let chars = this.getSequenceChars(filename, sequenceID)
    window.SpeechAPI.loadCharacterIds(Array.from(chars), () => {

      chars.forEach(this.loadedCharIds.add, this.loadedCharIds);

      this.dialsSequence = SequencesManager.playSequence(filename, sequenceID)

      if(this.dialsSequence) {
        this.setDial(this.dialsSequence.getCurrentElement())
        this.currentDialSequenceId = sequenceID
      }
      setTimeout(() => {this.loading= false }, 200);
    })

  }

  setDial(seqElement) {
    this.currentDial = seqElement
  }

  nextDial() {

    if(this.dialsSequence?.hasNext()) {
      this.dialsSequence.next()
      this.setDial(this.dialsSequence.getCurrentElement())
    }
    else {
      this.currentDial = null
      if(this.onDialEnd) {
        this.onDialEnd()
        this.onDialEnd = null
      }
    }
  }
}


export default Dials