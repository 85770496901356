import React, {useState, useEffect} from 'react'

import classes from './HomeScreens.module.css'
import Footer from './Footer/Footer';
import logo_src from "@/images/logo.png"
import clsx from 'clsx'
const HomeScreens = ({logo = true,title, text, header, children}) => {

  return (
    <div className={clsx(classes.HomeScreens, "screen")}>
      {header}
      {logo && <img src={logo_src} alt="Logo Rekup>R" className={classes.logo} />}

      <div className={classes.content}>
        {title && <h1 className={classes.title}>{title}</h1>}
        {text && <div className={classes.text} dangerouslySetInnerHTML={{__html: text}} />}
      </div>

      <div className={classes.children}>
        {children}
      </div>



      <Footer />
    </div>
  );
};

export default HomeScreens;
