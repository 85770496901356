import React, {useState, useEffect} from 'react'
import Loader from 'react-sdk/components/Loader/Loader'
import classes from './CollectesGrid.module.css'
import Collectes from '@/js/Collectes';

import { observer } from 'mobx-react-lite';
import Texts from 'react-sdk/Texts';
import PopupsManager from 'react-sdk/PopupsManager';
import { POPUPS } from '@/js/AppState';
import clsx from 'clsx';


const CollecteElement = observer(({collecte_id}) => {

  let collecte = Collectes.get(collecte_id)
  let {day, time} =  collecte.date_confirmation_DE_toLocale

  return (
    <div className={classes.Collecte} onClick={() => PopupsManager.open(POPUPS.COLLECTE, collecte)}>
      <div className={classes.collecte_block}>
        {collecte.total_weight &&
        <div className={classes.collecte_poids}>
          <span>{collecte.total_weight * 1000}kg </span>
          <div>Poids réel</div>
        </div>}


        {(!collecte.total_weight && collecte.Estimation) &&
        <div className={clsx(classes.collecte_poids, classes.collecte_poids_estimation)}>
          <span>{collecte.EstimationKG}kg</span>
          <div>Estimation</div>
        </div>}

        {(!collecte.total_weight && !collecte.Estimation) &&
        <div>-</div>}

      </div>


      <div className={classes.date}>le {day} à {time}</div>

    </div>
  )
})





const CollectesGrid = () => {
  const [loading, setLoading] = useState(true);

  const [showAll, setShowAll] = useState(false);

  function fetch() {
    setLoading(false)

    Collectes.fetch()
    .then(() => {
      setLoading(false)
    })

  }

  useEffect(() => {
    fetch()
  }, []);



  return (
    <div className={classes.CollectesGrid}>
      {loading &&
      <div className={classes.loading}>
        <Loader />
      </div>}

      {Collectes.all
      .filter((c, i) => showAll || i < 3) // ne renvoie que les 3 prems si !showAll
      .map(c => <CollecteElement collecte_id={c.id} key={c.id} />)}

      {Collectes.all.length === 0 &&
      <div className={classes.no_collecte}>{Texts.get("popup-collecte-no-collecte")}</div>}

      {(!showAll && Collectes.all.length > 3 )&&

      <div className={classes.show_more_btn} onClick={() => setShowAll(true)}>Voir plus...</div>}

    </div>
  );
};

export default observer(CollectesGrid)
