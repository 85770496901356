import { FaXmark } from 'react-icons/fa6';
import Button from '../Button/Button';
import './Popup.css';

const Popup = ({title, children, className="", style={}, onClose}) => {

  function handleClose() {
    if(onClose) onClose()
  }

  // useEffect(() => {
  //   const handleEscape = e => { if(e.code === 'Escape')  onClose() }
  //   window.addEventListener('keydown', handleEscape)

  //   return () => { window.removeEventListener('keydown', handleEscape) }
  // }, []);

  return (
    <div className="Popup-overlay" onClick={handleClose} >
      <div className="Popup" onClick={e => e.stopPropagation()} >

        <div className={className} style={style}>
          {children}
          {onClose && <Button className="Popup-close"  onClick={onClose} icon={<FaXmark />} />}
        </div>

      </div>
    </div>
  );
};

export default Popup;