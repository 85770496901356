import React, {useState, useEffect} from 'react'

import classes from './ScreenTeam.module.css'
import HomeScreens from '@scr/_layouts/HomeScreens/HomeScreens';
import Button from '@cmp/shared/Button/Button';
import Texts from 'react-sdk/Texts';
import AppState, { SCREENS } from '@/js/AppState';
import TeamGrid from '@cmp/shared/TeamGrid/TeamGrid';
import MagasinInfos from '@cmp/shared/MagasinInfos/MagasinInfos';
import TeamHeader from '@/components/shared/TeamHeader/TeamHeader';
import { observer } from 'mobx-react-lite';


const ScreenTeam = () => {
  let user = AppState.vertxClient.UserData

  let title_id = AppState.userIsReferent ? "team-screen-title-referent" : "team-screen-title"


  return (
    <HomeScreens
    logo={false}
    header={<TeamHeader  /> }
    title={Texts.get(title_id, {mustacheVars: {pseudo: user.getPseudo()}})}
    >
      <div className={classes.content}>
          <TeamGrid showReferentActions={AppState.userIsReferent}  />
          <MagasinInfos />


          <div className={classes.btn_ctnr}>
            <Button onClick={() => AppState.setScreen(SCREENS.VIDEO)}>{Texts.get("team-screen-button")}</Button>
          </div>
      </div>


    </HomeScreens>
  );
};

export default observer(ScreenTeam)
