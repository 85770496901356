import React, {useState, useEffect} from 'react'

import classes from './PopupCollecte.module.css'
import Button from '@cmp/shared/Button/Button';
import clsx from 'clsx';
import PopupsManager from 'react-sdk/PopupsManager';
import AppState, { POPUPS } from '@/js/AppState';
import Popup from '@/components/shared/Popup/Popup';
import Texts from 'react-sdk/Texts';
import Collectes, { Collecte, COLLECTE_STATUS } from '@/js/Collectes';
import { FaPencil } from 'react-icons/fa6';
import { toJS } from 'mobx';
import { Round } from 'react-sdk/utils';

const PopupCollecte = () => {

  /** @type {Collecte} */
  let collecte = PopupsManager.getData(POPUPS.COLLECTE)


  let date = collecte.date_confirmation_DE_toLocale



  const [formSending, setFormSending] = useState(false);

  const [editEstimation, setEditEstimation] = useState(AppState.userIsReferent && !collecte.Estimation && !collecte.total_weight);


  function handleSubmit(e) {
    e.preventDefault()

    const data = new FormData(e.target)
    const estim = data.get("estim")
    setFormSending(true)

    collecte.setEstimation(estim)
    .then(() => {
      setFormSending(false)
      setEditEstimation(false)

    })
  }

  return (
    <Popup className={classes.PopupCollecte} onClose={() => PopupsManager.close(POPUPS.COLLECTE)}>

      <div className="popup-title">
        <h3>{Texts.get("popup-collecte-title")}</h3>
      </div>

      {AppState.userIsReferent && <div className={classes.referent}>{Texts.get("popup-team-referent-info")}</div>}

      <div className={classes.block}>
        <h5>{Texts.get("popup-collecte-prevu-title")}</h5>
        <p className={classes.libelle_bac}>{collecte.libelle_flux_ENL}</p>
        <p><i>Demande réalisée le {date.day} à {date.time}  </i></p>
      </div>

      {editEstimation &&
      <div className={classes.block}>
        <h5>{Texts.get("popup-collecte-estim-title")}</h5>
        <form onSubmit={handleSubmit} className={classes.estim_form} >
          <input
          disabled={formSending}
          type="number"
          name="estim"
          className={classes.input_estim}
          defaultValue={collecte.EstimationKG}
          placeholder={Texts.get("popup-collecte-estim-placeholder")}/>
          <Button disabled={formSending} variant='small'>{Texts.get("popup-collecte-estim-btn")}</Button>
        </form>

        {formSending && <div><small>Merci de patienter</small></div>}
      </div>}

      {(!editEstimation && collecte.Estimation) &&
      <div className={classes.block}>
        <h5>{Texts.get("collecte-estim-done", {mustacheVars: {poids: collecte.EstimationKG}})}</h5>
        {AppState.userIsReferent && !collecte.total_weight && <Button icon={<FaPencil />} onClick={() => setEditEstimation(true)} />}
      </div>}

      {(!AppState.userIsReferent && !collecte.Estimation && !collecte.total_weight) &&
      <div
      className={classes.block}
      dangerouslySetInnerHTML={{__html: Texts.get("popup-collecte-no-estim") }} />}



      {/* il me semble que c'est tjs sur VALIDEE... */}
      {/* {collecte.statut_DE === "VALIDEE" &&
      <div className={clsx(classes.block, collecte.statut_DE === "PREVU" && classes.inactive)}>
        <h5>{Texts.get("popup-collecte-valide-title")}</h5>
      </div>} */}

      {collecte.total_weight &&
      <div
      className={clsx(classes.block)}
      dangerouslySetInnerHTML={{__html: Texts.get("popup-collecte-pesee", {mustacheVars: {poids: Round(collecte.total_weight * 1000)}})}}>
      </div>}

      {collecte.Score &&
      <div className={clsx(classes.block)}>
        <div dangerouslySetInnerHTML={{__html: Texts.get("popup-collecte-score", {mustacheVars: {score: Round(collecte.Score)}})}} />

        {collecte.ScoreCoeff &&
        <>
          <div>{Round(collecte.total_weight * 1000)} &times; {Round(collecte.ScoreCoeff, 4)} = {Round(collecte.Score)}</div>
          <div dangerouslySetInnerHTML={{__html: Texts.get("popup-collecte-score-coef", {mustacheVars: {coef: collecte.ScoreCoeff}})}}></div>
        </>}
      </div>}


      {collecte.statut_DE === COLLECTE_STATUS.REFUSEE &&
      <div className={clsx(classes.block)}>
        <h5>{Texts.get("collecte-title-refusee")}</h5>
      </div>}

      {collecte.statut_DE === COLLECTE_STATUS.SUPPRIMEE &&
      <div className={clsx(classes.block)}>
        <h5>{Texts.get("collecte-title-supprimee")}</h5>
      </div>}


      {collecte.conformance_status === "NONCONFORME" &&
      <div className={clsx(classes.block)}>
      <h5 dangerouslySetInnerHTML={{__html: Texts.get("collecte-non-conforme")}} ></h5>
      </div>}

      {collecte.conformance_status === "CONFORME" &&
      <div className={clsx(classes.block)}>
        <h5 dangerouslySetInnerHTML={{__html: Texts.get("collecte-conforme")}} ></h5>
      </div>}


      <div className={classes.spacer}></div>

    </Popup>
  );
};

export default PopupCollecte;
