import React, { useState } from "react";
import HomeScreens from "../_layouts/HomeScreens/HomeScreens";
import Texts from "react-sdk/Texts";
import AppState, { SCREENS } from "@/js/AppState";
import classes from '../ScreenUser/ScreenUser.module.css'
import ProfilePic from "@/components/shared/ProfilePic/ProfilePic";
import Button from "@/components/shared/Button/Button";
import Config from "react-sdk/Config";

const ScreenTeamPseudo = () => {

  const maxlength = Config.get("pseudoTeamMaxLength", 15)

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [pseudo, setPseudo] = useState(AppState.team.teamPseudo || "");

  const remaining = maxlength - pseudo.length
  function updatePseudo(e) {
    setError(false)

    setPseudo(e.target.value)
}

  function handleClick() {
    setLoading(true)

    if(pseudo !== AppState.team.teamPseudo) {
      AppState.team.updatePseudo(pseudo)
      .then(() => {
        AppState.setScreen(SCREENS.TEAM)
      })
      .catch(err => {
        setLoading(false)
        setError(err)

      })
    }
    else {
      AppState.setScreen(SCREENS.TEAM)
    }
  }


  return (
    <HomeScreens
    title={Texts.get("teampseudo-screen-title")}
    >
      <div className={classes.content}>
        <div className={classes.pp}>
          <ProfilePic pseudo={AppState.team.teamPseudo} />
        </div>
        <div className={classes.pseudo}>
          <input
          required maxLength={15}
          type="text"
          value={pseudo}
          onChange={updatePseudo}
          placeholder={Texts.get("teampseudo-screen-placeholder")} />

          <div className={classes.remaining}>{remaining}</div>
        </div>

        {error &&
        <div className={classes.error}> {Texts.get("teampseudo-screen-pseudo-error")}</div>}

        <div className={classes.button_ctnr}>
          <Button disabled={loading || !pseudo} onClick={handleClick}>{Texts.get("teampseudo-screen-button")}</Button>
        </div>

      </div>

    </HomeScreens>
  )
}

export default ScreenTeamPseudo