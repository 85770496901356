import React, {useState, useEffect, useRef} from 'react'

import classes from './PopupMinigameStars.module.css'
import Popup from '@/components/shared/Popup/Popup';
import Button from '@/components/shared/Button/Button';
import {CountUp} from 'countup.js';
import clsx from 'clsx';
import PopupsManager from 'react-sdk/PopupsManager';
import { POPUPS } from '@/js/AppState';


const SvgStar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={198}
    height={205}
    viewBox="0 0 198 205"
    fill="none"
    {...props}
  >
    <path
      className={classes.hide_if_inactive}
      fill="#178DC0"
      d="m107.808 199.663-36.085-38.52L18 172.682a15.677 15.677 0 0 1-8.45-.501c-2.703-.937-5.05-2.596-6.746-4.768-1.696-2.173-2.667-4.764-2.792-7.448-.124-2.684 1.304-21.927 2.792-24.228l27.72-27.069-28.42-43.56c-1.489-2.3-2.216-4.96-2.092-7.644.125-2.684 1.096-11.147 2.792-13.32 1.697-2.173 4.855.98 6.623-.606 5.18-4.647 6.03-6.484 8.84-5.873l53.401 18.604 36.14-38.529c1.886-2.037 4.376-3.51 7.158-4.235a15.7 15.7 0 0 1 8.479.163c2.747.83 5.17 2.399 6.965 4.508 1.794 2.108 2.88 4.664 3.122 7.345l4.722 50.785 46.038 9.253c2.663 1.035 9.652 3.897 11.252 6.134 1.6 3.411 2.454 14.321 2.454 17.009 0 2.688-.854 5.316-2.454 7.554-1.6 2.237-3.875 3.983-6.538 5.018l-50.752 19.884v31.023c-.242 2.682-6.05 24.981-7.844 27.09-1.795 2.108-4.218 3.677-6.965 4.508a15.705 15.705 0 0 1-8.479.163c-2.782-.725-5.272-2.198-7.158-4.236v-.043Z"
    />
    <path
      className={classes.starfill}
      fill="#52C4F6"
      d="m106.864 174.355-32.218-34.266-47.966 10.264a14.046 14.046 0 0 1-7.546-.445c-2.413-.834-4.508-2.31-6.022-4.242-1.515-1.933-2.382-4.238-2.493-6.626-.112-2.387.538-4.753 1.867-6.8L37.86 93.408 12.486 54.659c-1.33-2.047-1.979-4.412-1.867-6.8.11-2.388.978-4.692 2.493-6.625 1.514-1.933 3.61-3.409 6.022-4.242a14.056 14.056 0 0 1 7.546-.446l47.917 10.25 32.267-34.274c1.685-1.813 3.908-3.124 6.392-3.768a14.066 14.066 0 0 1 7.57.145c2.453.739 4.617 2.134 6.219 4.01 1.602 1.876 2.572 4.149 2.788 6.534l4.215 45.177 45.315 17.634c2.378.921 4.409 2.474 5.838 4.465 1.428 1.99 2.191 4.328 2.191 6.72 0 2.39-.763 4.729-2.191 6.719-1.429 1.99-3.46 3.543-5.838 4.464l-45.315 17.688-4.215 45.161c-.216 2.386-1.186 4.659-2.788 6.535-1.602 1.876-3.766 3.271-6.219 4.01a14.073 14.073 0 0 1-7.57.145c-2.484-.645-4.707-1.955-6.392-3.768v-.038Z"
    />
    <path
      // className={classes.hide_if_inactive}
      fill="#178DC0"
      fillRule="evenodd"
      d="M106.866 174.384c1.685 1.813 3.908 3.124 6.392 3.768 2.484.645 5.117.594 7.57-.145 2.453-.739 4.617-2.134 6.219-4.01 1.602-1.876 2.572-4.149 2.788-6.535l4.215-45.161 45.315-17.688c2.378-.921 4.409-2.474 5.838-4.464 1.428-1.99 2.191-4.328 2.191-6.72 0-2.391-.763-4.729-2.191-6.72-1.429-1.99-3.46-3.543-5.838-4.464L134.05 64.611l-4.215-45.177c-.216-2.385-1.186-4.658-2.788-6.534-1.602-1.876-3.766-3.27-6.219-4.01a14.066 14.066 0 0 0-7.57-.145c-2.484.644-4.707 1.955-6.392 3.768L74.599 46.786 26.681 36.537a14.058 14.058 0 0 0-7.545.446c-2.413.833-4.508 2.309-6.022 4.242-1.515 1.933-2.382 4.237-2.494 6.625-.11 2.388.539 4.753 1.868 6.8L37.863 93.4l-25.375 38.832c-1.33 2.047-1.979 4.413-1.867 6.801.11 2.387.978 4.692 2.493 6.625 1.514 1.933 3.61 3.408 6.022 4.242 2.413.834 5.038.989 7.546.446l47.966-10.265 32.218 34.266v.038Zm-8.25 3.212v.01l2.197 2.364c2.794 3.007 6.414 5.113 10.366 6.138a22.35 22.35 0 0 0 12.037-.231c3.91-1.179 7.445-3.428 10.113-6.551 2.671-3.128 4.346-6.989 4.721-11.113l.001-.012 3.743-40.093 40.56-15.831.008-.004c3.808-1.476 7.153-4 9.55-7.34 2.406-3.351 3.732-7.354 3.732-11.504 0-4.149-1.326-8.152-3.732-11.503-2.397-3.34-5.742-5.864-9.549-7.34l-.009-.004-40.56-15.784-3.742-40.102-.002-.013c-.376-4.124-2.05-7.985-4.721-11.112-2.668-3.123-6.203-5.372-10.113-6.55a22.344 22.344 0 0 0-12.037-.233c-3.942 1.023-7.554 3.121-10.345 6.116L71.772 37.773l-43.338-9.27a22.334 22.334 0 0 0-12 .712c-3.855 1.332-7.291 3.72-9.823 6.95-2.537 3.238-4.039 7.168-4.232 11.303-.192 4.138.938 8.192 3.182 11.648l.01.014 22.444 34.274-22.447 34.351-.007.01c-2.244 3.456-3.374 7.511-3.182 11.648.193 4.136 1.695 8.066 4.232 11.304 2.532 3.23 5.968 5.618 9.823 6.95a22.335 22.335 0 0 0 11.99.714l.01-.002 43.384-9.284 26.798 28.501Z"
      clipRule="evenodd"
    />
    <path
      className={classes.hide_if_inactive}
      fill="#A7E4FF"
      d="m104.035 141.848-20.62-21.741-30.699 6.513a9.077 9.077 0 0 1-4.828-.283c-1.545-.529-2.886-1.465-3.855-2.692a7.326 7.326 0 0 1-1.596-4.203 7.241 7.241 0 0 1 1.196-4.315l16.24-24.638-16.24-24.586a7.24 7.24 0 0 1-1.196-4.314 7.328 7.328 0 0 1 1.596-4.204c.97-1.226 2.31-2.163 3.855-2.691a9.071 9.071 0 0 1 4.829-.283l30.667 6.503 20.651-21.746c1.078-1.15 2.501-1.982 4.091-2.391a9.079 9.079 0 0 1 4.845.092c1.569.47 2.954 1.354 3.979 2.544a7.382 7.382 0 0 1 1.785 4.146l2.698 28.664 29.001 11.189c1.522.584 2.822 1.57 3.736 2.832a7.261 7.261 0 0 1 1.402 4.264c0 1.517-.488 3-1.402 4.264-.914 1.262-2.214 2.248-3.736 2.832l-29.001 11.223-2.698 28.654a7.386 7.386 0 0 1-1.785 4.146c-1.025 1.19-2.41 2.075-3.979 2.544a9.078 9.078 0 0 1-4.845.092c-1.59-.409-3.013-1.241-4.091-2.391v-.024Z"
    />
    <path
      className={classes.hide_if_inactive}
      fill="#1FA7E2"
      d="m103.825 136.065-19.76-21.032-29.42 6.3a8.627 8.627 0 0 1-4.628-.273c-1.48-.512-2.765-1.418-3.694-2.604a7.132 7.132 0 0 1-1.529-4.067 7.055 7.055 0 0 1 1.145-4.174L61.503 86.38 45.939 62.597a7.053 7.053 0 0 1-1.145-4.174 7.13 7.13 0 0 1 1.53-4.067c.928-1.186 2.213-2.092 3.693-2.604a8.615 8.615 0 0 1 4.628-.273l29.39 6.29 19.79-21.036a8.13 8.13 0 0 1 3.92-2.313 8.622 8.622 0 0 1 4.643.09 8.063 8.063 0 0 1 3.814 2.46 7.168 7.168 0 0 1 1.71 4.011l2.586 27.73 27.793 10.823c1.458.566 2.704 1.519 3.58 2.74a7.069 7.069 0 0 1 1.344 4.125 7.07 7.07 0 0 1-1.344 4.124c-.876 1.222-2.122 2.175-3.58 2.74l-27.793 10.857-2.586 27.72a7.17 7.17 0 0 1-1.71 4.011 8.068 8.068 0 0 1-3.814 2.461 8.614 8.614 0 0 1-4.643.089 8.123 8.123 0 0 1-3.92-2.313v-.023Z"
    />

    <text x="50%" y ="50%" className={classes.star_text}>{props.children}</text>
  </svg>
)



const PopupMinigameStars = () => {


  let {score, level} = PopupsManager.getData(POPUPS.MINIGAME_STARS)

  const scoreRef = useRef(null)

  const [currentval, setCurrentval] = useState(0);
  useEffect(() => {
    let demo = new CountUp(scoreRef.current, score, {duration: 3});

    // un peu triste d'utiliser ça pour ça mais tant pis
    demo.formattingFn = (val => {
      setCurrentval(val)
      return val
    })

    if (!demo.error) {
      demo.start();
    } else {
      console.error(demo.error);
    }

  }, []);

  function handleClick() {
    PopupsManager.close(POPUPS.MINIGAME_STARS)
  }

  return (
    <Popup className={classes.PopupMinigameStars}>
      {level &&
      <div className={classes.stars_ctnr}>
        <SvgStar className={clsx(currentval < level.threshold_1 && classes.inactive)} >{level.threshold_1}</SvgStar>
        <SvgStar className={clsx(currentval < level.threshold_2 && classes.inactive)} >{level.threshold_2}</SvgStar>
        <SvgStar className={clsx(currentval < level.threshold_3 && classes.inactive)} >{level.threshold_3}</SvgStar>


      </div>}
      <div className={classes.score_block}>
        <div className={classes.score_block_title}>Score</div>
        <div className={classes.score_block_content} ref={scoreRef}>
        </div>
      </div>
      <div className={classes.btn}>
        <Button onClick={handleClick}>Continuer</Button>
      </div>
    </Popup>
  );
};

export default PopupMinigameStars;
