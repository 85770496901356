// NOTE un fichier avec des constantes utiles


export const STATES = {
  CORRECT: "CORRECT",
  WRONG: "WRONG",
  MISSED: "MISSED",
}


/**
 * @typedef ActivityAnswer
 * @type {object}
 * @property {boolean} is_correct - la réponse est correcte
 * @property {boolean} joker_earned
 * @property {boolean} joker_used
 * @property {number} nb_cases
 * @property {number} normalized_score
 * @property {number} points
 * @property {Object} additionalInfos
 */
