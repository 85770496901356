import AppState, { POPUPS } from '@/js/AppState';
import Popup from '@cmp/shared/Popup/Popup';
import { useEffect, useState } from 'react';
import PopupsManager from 'react-sdk/PopupsManager';

import { FTUE_state } from '@/js/FTUE';
import SequenceScenario from '@/js/SequenceScenario';
import { observer } from 'mobx-react-lite';
import SoundPlayer from 'react-sdk/SoundPlayer';
import Loader from 'react-sdk/components/Loader/Loader';
import classes from './PopupBoutique.module.css';
import popup_classes from '../Popups.module.css';
import Button from '@/components/shared/Button/Button';
import clsx from 'clsx';
import Texts from 'react-sdk/Texts';

const PopupBoutique = () => {
  const [boutiqueOpened, setBoutiqueOpened] = useState(false);

  useEffect(() => {
    SoundPlayer.playSound("music_batUI")

    return () => {
      SoundPlayer.playSound("music_map")
    }
  }, []);


  function handleClose() {
    PopupsManager.close(POPUPS.BOUTIQUE)
  }

  function handleOpen() {
    const userID = AppState.vertxClient.UserData.getUserID()

    window.open(window.CONFIG.boutique_url + userID)

    setTimeout(() => {
      setBoutiqueOpened(true)
    }, 500)
  }

  return (
    <Popup className={clsx(classes.PopupBoutique, popup_classes.page)} onClose={handleClose}>
      <div className="popup-title">
        <h3>{Texts.get("popup-boutique-title")}</h3>
      </div>
      <div className={classes.content}>
        {!boutiqueOpened &&
        <div className={clsx("block", classes.block)}>
          <p dangerouslySetInnerHTML={{__html: Texts.get("popup-boutique-not-opened-text")}} />
          <Button onClick={handleOpen}>{Texts.get("popup-boutique-not-opened-button")}</Button>
        </div>}

        {boutiqueOpened &&
        <div className={clsx("block", classes.block)}>
          <p dangerouslySetInnerHTML={{__html: Texts.get("popup-boutique-opened-text")}} />
          <Button onClick={handleClose}>{Texts.get("popup-boutique-opened-button")}</Button>
        </div>}
      </div>
    </Popup>

  );
};

export default observer(PopupBoutique)
